/**
 * SetUp 设置模块相关数据
 */

var actions = {

}

var mutations = {
    //设置部门信息
    SetSetDptInfo(state,value){
        state.setDptInfo = value
    },
}

var state = {
    setDptInfo:{},  //设置部门信息
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}