<template>
  <div>
    <!-- 移仓单列表 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="移仓单号">
                <el-input v-model="info.search.moveNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="待审核"></el-option>
                    <el-option :value="3" label="已驳回"></el-option>
                    <el-option :value="4" label="已审核"></el-option>
                    <el-option :value="5" label="已出库"></el-option>
                    <el-option :value="6" label="已入库"></el-option>
                    <el-option :value="10" label="已完成"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="出库仓库">
                <el-select v-model="info.search.outWarehouseNumber" class="search-200">
                  <el-option value="" label="全部"></el-option>
                  <el-option v-for="whInfo in allWhList" :key="whInfo.warehouseNumber" :label="whInfo.warehouseName" :value="whInfo.warehouseNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="入库仓库">
                <el-select v-model="info.search.inWarehouseNumber" class="search-200">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="whInfo in allWhList" :key="whInfo.warehouseNumber" :label="whInfo.warehouseName" :value="whInfo.warehouseNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button v-if="CheckUserButtonAuth('BtnMoveAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
            <el-popconfirm v-if="CheckUserButtonAuth('BtnMoveDelete')"
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>       
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" />  
        <el-table-column label="移仓单号" width="170">
            <template #default="scope">
                {{scope.row.moveNumber}}
            </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" min-width="110"/>
        <el-table-column prop="outWarehouseName" label="出库仓库" min-width="110"/>
        <el-table-column prop="inWarehouseName" label="入库仓库" min-width="110"/>
            
        <el-table-column label="状态" min-width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2" type="warning">待审核</el-tag>
                <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                <el-tag v-if="scope.row.state ==4" >已审核</el-tag>
                <el-tag v-if="scope.row.state ==5" type="warning">已出库</el-tag>
                <el-tag v-if="scope.row.state ==6" >已入库</el-tag>
                <el-tag v-if="scope.row.state ==10" type="success">已完成</el-tag>
                
            </template>
        </el-table-column>

        <el-table-column label="备注说明" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.remark"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.moveNumber)">详情</el-button>
            <el-popconfirm v-if="CheckUserButtonAuth('BtnMoveDelete')"
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.moveNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditMove :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditMove>
    </el-drawer>

  </div>
</template>

<script>
import EditMove from '@/views/Wms/Move/EditMove.vue'
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户授权部门
import { ElMessage } from 'element-plus'
export default {

    mixins:[UserDptList],
    components:{
        EditMove
    },
    data(){
        return{
            dataList:[],  //Table数据
            allWhList:[],   //所有仓库列表 
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    moveNumber:'',
                    selectDepartmentList:[],
                    state:0,
                    datePoint:'',
                    outWarehouseNumber:'',
                    inWarehouseNumber:'',
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'新增移仓单'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-290
        }
    },
    methods:{

        //加载全部仓库列表
        LoadWhList(){
            this.$api.wms.SelectCacheWarehouseList().then(res=>{
                if(res.data.status === 200){
                    this.allWhList = res.data.data;
                }
            })
        },

        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                moveNumber:this.info.search.moveNumber,
                departmentList:newDepartmentList,
                state:this.info.search.state,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                outWarehouseNumber:this.info.search.outWarehouseNumber,
                inWarehouseNumber:this.info.search.inWarehouseNumber,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.PageMoveList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.moveNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增编辑部门信息
        btnEditInfo(moveNumber){
            this.info.id = moveNumber
            if(moveNumber==0){
                this.BoxEditInfo.title = "新增移仓单"
            }else{
                this.BoxEditInfo.title = "编辑移仓单"
            }
            this.BoxEditInfo.isVisible = true;
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(moveNumber){
            let param = [moveNumber]
            this.$api.wms.DeleteMoveAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.wms.DeleteMoveAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.moveNumber='';
            this.info.search.selectDepartmentList = [];
            this.info.search.state=0;
            this.info.search.datePoint = '';
            this.info.search.outWarehouseNumber='';
            this.info.search.inWarehouseNumber='';
            this.info.page = 1;
            this.GetDataList()
        }, 

    },
    mounted(){
        this.LoadWhList(); //加载所有仓库列表 
        this.GetDataList();  //加载数据
    }

}
</script>

<style>

</style>