<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="销售单" name="tabSale">
            <TabSale ref="tabSale"></TabSale>

            <el-divider>销售单明细</el-divider>
            <TabSaleDetail></TabSaleDetail>
        </el-tab-pane>

        <el-tab-pane label="出库记录" name="tabOutRecord">
            <TabOutStock></TabOutStock>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabSaleRecord></TabSaleRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabSaleState></TabSaleState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="saleInfo.info">
        <el-button v-if="CheckUserButtonAuth('BtnCancelSale') && saleInfo.info.state == 4" type="danger" @click="CancelSale">撤单</el-button>
        <el-button type="primary" v-if="saleInfo.info.state==1 || saleInfo.info.state==3" @click="btnSaveData">保存</el-button>
        <el-button v-if="saleInfo.info.saleNumber !='' && (saleInfo.info.state==1 || saleInfo.info.state == 3)" type="primary" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnSaleAudit') && saleInfo.info.state == 2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnSaleConfirm') && saleInfo.info.state == 5 " type="primary" @click="btnUpdateState(10)">确认完成</el-button>
        <el-button class="iconfont icon-dayin" v-if="saleInfo.info.state==10" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="销售单审核"
    v-model="BoxCheckState.isVisible"
    width="300px">
        <el-divider></el-divider>
        <div>销售单是否审核通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(4)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="80%">
        <PrintView v-if="BoxPrint.isVisible" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

  </div>
</template>

<script>
import TabSale from '@/views/Sales/Sale/TabSale.vue'
import TabSaleDetail from '@/views/Sales/Sale/TabSaleDetail.vue'
import TabSaleRecord from '@/views/Sales/Sale/TabSaleRecord.vue'
import TabSaleState from '@/views/Sales/Sale/TabSaleState.vue'
import TabOutStock from '@/views/Sales/Sale/TabOutStock.vue'
import PrintView from '@/views/Sales/Sale/PrintView.vue'  //打印页面

import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'

export default {
    props:['editId','dataList'],
    components:{
        TabSale,
        TabOutStock,
        TabSaleDetail,
        TabSaleRecord,
        TabSaleState,
        PrintView,
    },
    data(){
        return{
            info:{
                activeName:'tabSale',
                saleNumber:this.editId,
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['saleInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetSaleInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.saleNumber!=0){
                let param = {
                    saleNumber:this.info.saleNumber
                }
                this.$api.erp.SingleSaleAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetSaleInfo(res.data.data)
                    }else{
                        this.btnClearData();
                    }
                    //加载
                    this.$refs.tabSale.SelectShiftRecordList(); //班次列表
                })
            }
            else
            {
                this.btnClearData();
                //加载
                this.$refs.tabSale.SelectShiftRecordList(); //班次列表
            }            
        },

        //保存数据
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })
            
            this.$api.erp.SaveSaleAllInfo(this.saleInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新页面
                    this.saleInfo.info.saleNumber = res.data.data;
                    this.info.saleNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //提交审核状态
        btnUpdateState(state){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            let param = {
                saleNumber:this.saleInfo.info.saleNumber,
                state:state
            }
            this.$api.erp.UpdateSaleState(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.saleInfo.info.state = state;
                    this.BoxCheckState.isVisible = false
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //撤单
        CancelSale(){
            ElMessageBox.confirm('撤单将销售单状态变更为【新建单】。确认是否撤单？','撤单提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //确认
                let param = {
                    saleNumber:this.info.saleNumber
                }
                this.$api.erp.CancelSale(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.btnClose();
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{
                
            })            
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    saleNumber:'',
                    departmentId:this.userDepartmentId,
                    shiftNumber:'',
                    warehouseNumber:'',
                    tradeType:0,
                    tradeNumber:'',
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    totalPrice:0,
                    isTransport:0,
                    state:1,
                },
                detailList:[],
                recordList:[],
                stateList:[]
            }
            this.SetSaleInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.saleNumber === this.info.saleNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.saleNumber = prevInfo.saleNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.saleNumber === this.info.saleNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.saleNumber = prevInfo.saleNumber
                this.GetDataInfo()
            }
        },

        //打开-打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭-打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;        
        },

    },
    mounted(){
        this.GetDataInfo()
    }

}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>