//路由配置页面
import {createRouter,createWebHistory} from 'vue-router'
import Authorize from '@/utils/authorize'

// 框架
import Back from '@/views/Back'
import Login from '@/views/Login'
import Center from '@/components/home/Center'


//模块化
import System from '@/router/modules/system'
import Common from '@/router/modules/common'
import Goods from '@/router/modules/goods'
import Library from '@/router/modules/library'

import Service from '@/router/modules/service'
import Erp from '@/router/modules/erp'
//办公
import Hr from '@/router/modules/hr'
import Oa from '@/router/modules/oa'

import Market from '@/router/modules/market'
import Trace from '@/router/modules/trace'
//电商
import Shop from '@/router/modules/shop'
import Member from '@/router/modules/member'
//财务
import Finance from '@/router/modules/finance'
//统计
import Stat from '@/router/modules/statistics'



const routes = [{
    path:'/',
    redirect:{name:'back'}
},
{
    name:'back',
    path:'/back',
    component:Back,
    meta:{
        title:'透明云'
    },
    children:[{
            //首页
            name:'center',
            path:'center',
            component:Center
        },
        ...System,
        ...Common,
        ...Goods,
        ...Service,  //服务
        ...Market,  //营销管理
        ...Erp,  // ERP模块相关页面
        ...Hr,    //人事管理相关
        ...Oa,  //OA模块相关页面
        ...Shop,  //商铺
        ...Member,   //会员相关
        ...Finance,   //财务相关
        ...Trace,   //溯源码
        ...Stat,   //统计相关页面
        ...Library,  //资源
    ]
},{
    name:'login',
    path:'/login',
    component:Login,
    meta:{
        title:'登录'
    }
}]

const router = createRouter({
    history:createWebHistory(),
    routes:routes
})

// 路由守卫(前)
router.beforeEach((to,from,next)=>{
    if(to.name == 'login' || to.name == 'ShareRetail'){
        next()
    }
    else
    {
        if(Authorize.checkLogin()){
            next()
        }
        else
        {
            next({name:'login'})
        }
    }
})

//(后置守卫)
router.afterEach(to =>{
    document.title = to.meta.title;
})


export default router