<template>
  <div>
      <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
              <el-form :inline="true"
              label-width="70px">

                  <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" class="search-200"/>
                  </el-form-item>

                  <el-form-item label="仓库名称">
                    <el-input v-model="info.search.warehouseName" class="search-200"></el-input>
                  </el-form-item>

                  <el-form-item label="仓库类型" class="search-200">
                    <el-select v-model="info.search.type">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="普通库" :value="1"></el-option>
                        <el-option label="冷藏库" :value="2"></el-option>
                        <el-option label="耗材库" :value="3"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                  </el-form-item>

              </el-form>
            </el-col>
      </el-row>

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
              
          </el-col>
          </el-row>

          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox" ref="table"> 
              <el-table-column prop="id" type="selection" width="50" />  
              <el-table-column label="仓库编号" width="170">
                <template v-slot="scope">
                    <span>{{scope.row.warehouseNumber}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="departmentName" label="部门名称" />              
              <el-table-column prop="warehouseName" label="仓库名称" />
              <el-table-column label="仓库类型">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.warehouseType ==1" type="info">普通库</el-tag>
                    <el-tag v-if="scope.row.warehouseType ==2">冷藏库</el-tag>  
                    <el-tag v-if="scope.row.warehouseType ==3" type="warning">耗材库</el-tag>
                  </template>
              </el-table-column>
              
              <el-table-column prop="manager" label="管理员" />
              <el-table-column prop="state" label="状态">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.state ==1">正常</el-tag>
                    <el-tag v-if="scope.row.state ==0" type="danger">关闭</el-tag>
                  </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注" />
              <el-table-column label="操作" width="150" fixed="right">
              <template v-slot="scope">              
                  <el-button size="small" type="primary" @click="btnEditInfo(scope.row.warehouseNumber)">详情</el-button>
                  <el-popconfirm 
                  title="确认要删除吗?"
                  @confirm="btnDeleteInfo(scope.row.warehouseNumber)">
                      <template #reference>
                      <el-button size="small" type="danger">删除</el-button>
                      </template>
                  </el-popconfirm>
              </template>
              </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                  v-model:currentPage="info.page"
                  v-model:page-size="info.size"
                  :page-sizes="[50, 200, 500]"
                  :background="true"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="info.total"
                  @current-change="btnPagechange"
                  @size-change="btnSizechange">
              </el-pagination>
          </el-container>

      </div>

      <!-- 新增、编辑 弹出框 -->
      <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="700px"
      :draggable="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="GetDataList">
        <EditWarehouse :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditWarehouse>
      </el-dialog>
  </div>
</template>

<script>
import EditWarehouse from '@/views/Wms/Warehouse/EditWarehouse.vue'
import { ElMessage } from 'element-plus'
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
    name:'ListWarehouse',
    components:{
        EditWarehouse
    },
    mixins:[UserDptList],
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{  //搜索条件
                    selectDepartmentList:[],  //部门
                    warehouseName:'',   //仓库名称
                    type:0  //仓库类型
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增仓库'
            },

        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                return item
            })
            }
            
            let param = {
                departmentList:newDepartmentList,
                warehouseName:this.info.search.warehouseName,
                type:this.info.search.type,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.ListWarehourse(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data
                    //分页
                    this.info.page = res.data.pageNumber
                    this.info.total = res.data.total
                }
            }).catch(e=>{
                console.log(e)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.warehouseNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增、编辑信息
        btnEditInfo(warehouseNumber){
            this.info.id = warehouseNumber
            if(warehouseNumber == 0){
                this.BoxEditInfo.title = "新增仓库"
            }
            else{
                this.BoxEditInfo.title = "编辑仓库"
            }
            this.BoxEditInfo.isVisible = true;
        },
        
        //删除按钮
        btnDeleteInfo(warehouseNumber){
            let param = [warehouseNumber]
            this.$api.wms.DeleteWarehouseInfo(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage({type:'success',message:res.data.msg})
                    this.GetDataList()
                }
                else{
                    ElMessage({type:'error',message:res.data.msg})
                }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage({type:'error',message:'请选择要删除的选项'})
                return false
            }

            this.$api.wms.DeleteWarehouseInfo(this.info.ids).then(res=>{
            if(res.data.status === 200){
                ElMessage({type:'success',message:res.data.msg})
                this.GetDataList()
            }
            else{
                ElMessage({type:'error',message:res.data.msg})
            }
            })
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //重置按钮
        btnResetSearch(){
            this.info.search = {
                departmentList:[],
                warehouseName:'',
                type:0
            }
            this.GetDataList();
        },

    },
    mounted(){
        this.GetDataList()  //加载默认数据
    }
}
</script>

<style>

</style>