<template>
  <div>

    <el-form :inline="true"
    v-model="costInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="costInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="费用单号">
                    <el-input v-model="costInfo.info.costNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                    <el-tree-select v-model="costInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="单据说明">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>单据说明</span>
                    </template>
                    <el-input v-model="costInfo.info.costName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="单据类型">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>单据类型</span>
                    </template>
                    <el-select v-model.number="costInfo.info.billType" class="search-200">
                        <el-option :value="0" label="选择类型"></el-option>
                        <el-option v-for="typeInfo in info.financeTypeList" :key="typeInfo.typeId" :label="typeInfo.typeName" :value="typeInfo.typeId"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="交易者">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>交易者</span>
                    </template>
                    <el-select v-model="costInfo.info.tradeNumber" filterable class="search-200">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="记账时间">
                    <el-date-picker v-model="costInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="审核人">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>审核人</span>
                    </template>
                    <el-select v-model="costInfo.info.receiver" class="search-200">
                        <el-option v-for="checker in info.checkerList" :key="checker.tradeNumber" :label="checker.tradeName" :value="checker.tradeNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="总价">
                  <el-input v-model="costInfo.info.totalPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="财务单号">
                    <el-input v-model="costInfo.info.financeNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="costInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="costInfo.info.state ==2" type="success">待审核</el-tag>
                  <el-tag v-if="costInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="costInfo.info.state ==4" type="warning">已审核并上报</el-tag>
                  <el-tag v-if="costInfo.info.state ==5" type="info">已完成</el-tag>
                  <el-tag v-if="costInfo.info.state ==10">已关单</el-tag>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="财务单标识">
                    <el-tag v-if="costInfo.info.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="costInfo.info.financeFlag == 1" type="info">已生成</el-tag>
                </el-form-item>
            </el-col>            

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllUserList from '@/utils/mixins/AllUserList.js'
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,AllUserList,PartTradeList],
    data(){
        return{
            info:{
                checkerList:[],  //审核人列表
                financeTypeList:[],  //财务类型-下拉列表
            }
        }
    },
    computed:{
        ...mapState('Erp',['costInfo'])
    },
    methods:{
       
        //获取审核人列表
        SelectCheckerList(){
            let param = {
                tradeType:3  //固定：获取所有员工列表
            }
            this.$api.erp.SelectTradeList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.checkerList = res.data.data;
                }else{
                    this.info.checkerList = [];
                }
            }) 
        },

        //财务类型
        SelectFinanceTypeList(){
            this.$api.finance.SelectFinanceTypeList().then(res=>{
                if(res.data.status === 200){
                    this.info.financeTypeList = res.data.data;
                }
            })
        },


    },
    mounted(){
        this.SelectCheckerList();   //获取审核人列表
        this.SelectFinanceTypeList();  //获取单据类型列表
    }
}
</script>

<style>

</style>