<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="移仓单" name="tabMove">
            <TabMove></TabMove>

            <el-divider>移仓单明细</el-divider>
            <TabMoveDetail></TabMoveDetail>
        </el-tab-pane>

        <el-tab-pane label="出库记录" name="tabOutRecord">
            <TabOutStock></TabOutStock>
        </el-tab-pane>

        <el-tab-pane label="入库记录" name="tabInRecord">
            <TabInStock></TabInStock>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabMoveRecord></TabMoveRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabMoveState></TabMoveState>
        </el-tab-pane>

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="moveInfo.info">
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnMoveSave') && (moveInfo.info.state==1 || moveInfo.info.state ==3)" @click="btnSaveData">保存</el-button>
        <el-button v-if="moveInfo.info.moveNumber !='' && (moveInfo.info.state==1 || moveInfo.info.state == 3)" type="primary" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnMoveCheck') && moveInfo.info.state ==2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnMoveComplete') && moveInfo.info.state==6" type="success" @click="btnUpdateState(10)">确认完成</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="审核"
    v-model="BoxCheckState.isVisible"
    width="20%">
        <el-divider></el-divider>
        <div>审核是否通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(4)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TabMove from '@/views/Wms/Move/TabMove.vue'
import TabMoveDetail from '@/views/Wms/Move/TabMoveDetail.vue'
import TabMoveRecord from '@/views/Wms/Move/TabMoveRecord.vue'
import TabMoveState from '@/views/Wms/Move/TabMoveState.vue'

import TabOutStock from '@/views/Wms/Move/TabOutStock.vue'
import TabInStock from '@/views/Wms/Move/TabInStock.vue'

import { ElLoading, ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    props:['editId','dataList'],
    components:{
        TabMove,
        TabMoveDetail,
        TabMoveRecord,
        TabMoveState,
        TabOutStock,
        TabInStock,
    },
    data(){
        return{
            info:{
                activeName:'tabMove',
                moveNumber:this.editId,
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['moveInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetMoveInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.moveNumber!=0){
            let param = {
                moveNumber:this.info.moveNumber
            }
            this.$api.wms.GetMoveAllInfo(param).then(res=>{
                if(res.data.status === 200){
                    this.SetMoveInfo(res.data.data)
                }else{
                    this.btnClearData();
                }
            })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.wms.SaveMoveAllInfo(this.moveInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新页面
                    this.moveInfo.info.moveNumber = res.data.data;
                    this.info.moveNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //更新状态
        btnUpdateState(state){
            let param = {
                moveNumber:this.moveInfo.info.moveNumber,
                state:state
            }
            this.$api.wms.UpdateMoveState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.moveInfo.info.state = state;
                    this.GetDataInfo();
                    this.BoxCheckState.isVisible = false;  //关闭审核框
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    systemNumber:'',
                    moveNumber:'',
                    departmentId:this.userDepartmentId,
                    outWarehouseNumber:'',
                    inWarehouseNumber:'',
                    receiver:'',
                    state:1,
                    remark:'',
                    isDel:0,
                },
                detailList:[],
                recordList:[],
                stateList:[]
            }
            this.SetMoveInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.moveNumber === this.info.moveNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.moveNumber = prevInfo.moveNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.moveNumber === this.info.moveNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.moveNumber = prevInfo.moveNumber
                this.GetDataInfo()
            }
        },


    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>