/**
 * 仓库模块接口列表
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const wms = {

    //#region 仓库

    //获取仓库列表
    ListWarehourse(param){
        return axios.post(`${base.base}/Warehouse/ListWarehouse`,param)
    },

    //获取单个仓库信息
    SingleWarehouse(param){
        return axios.get(`${base.base}/Warehouse/SingleWarehouse`,{
            params:param
        })
    },

    //保存仓库信息
    SaveWarehouseInfo(param){
        return axios.post(`${base.base}/Warehouse/SaveWarehouseInfo`,param)
    },

    //删除仓库信息
    DeleteWarehouseInfo(param){
        return axios.post(`${base.base}/Warehouse/DeleteWarehouseInfo`,param)
    },

    //获取所有仓库列表
    SelectCacheWarehouseList(){
        return axios.get(`${base.base}/Warehouse/SelectCacheWarehouseList`)
    },

    //#endregion

    //#region 库存

    //获取库存列表
    ListStockInfo(param){
        return axios.post(`${base.base}/Stock/ListStockInfo`,param)
    },

    //导出库存列表
    ExportStockList(param){
        return axios.post(`${base.base}/Stock/ExportStockList`,param)
    },

    //获取指定产品的成本单价和库存数量 注意：库存数量=是实际库存数量-锁定库存数量之后的库存数量
    GetSkuUnitStockInfo(param){
        return axios.get(`${base.base}/Stock/GetSkuUnitStockInfo`,{
            params:param
        })
    },

    //获取指定产品成本单价和库存量 注意：库存数量 = 实际库存数量， 不扣除锁定库存数量
    GetSkuUnitStockInfoByBefore(param){
        return axios.get(`${base.base}/Stock/GetSkuUnitStockInfoByBefore`,{
            params:param
        })
    },

    //删除库存
    DeleteStock(param){
        return axios.post(`${base.base}/Stock/DeleteStock`,param)
    },

    //调整锁定库存
    AdjustLockStock(param){
        return axios.post(`${base.base}/Stock/AdjustLockStock`,param)
    },

    //#endregion

    //#region 盘点单

    //获取盘点列表
    ListCheck(param){
        return axios.post(`${base.base}/Check/ListCheck`,param)
    },

    //获取单个盘点信息(包含子信息)
    SingleCheckAllInfo(param){
        return axios.get(`${base.base}/Check/SingleCheckAllInfo`,{
            params:param
        })
    },

    //保存盘点单
    SaveCheckAllInfo(param){
        return axios.post(`${base.base}/Check/SaveCheckAllInfo`,param)
    },

    //删除盘点单(包含明细)
    DeleteCheckAllInfo(param){
        return axios.post(`${base.base}/Check/DeleteCheckAllInfo`,param)
    },

    //删除盘点单明细
    DeleteCheckDetail(param){
        return axios({
            url:`${base.base}/Check/DeleteCheckDetail`,
            method:'post',
            params:param
        })
    },

    //更新盘点单状态
    UpdateCheckState(param){
        return axios.post(`${base.base}/Check/UpdateCheckState`,param)
    },

    //更新盘点单明细状态
    UpdateStateOfDetail(param){
        return axios({
            url:`${base.base}/Check/UpdateStateOfDetail`,
            method:'post',
            params:param
        })
    },

    //批量更新盘点明细表：数量、状态
    BatchUpdateCheckDetail(param){
        return axios.post(`${base.base}/Check/BatchUpdateCheckDetail`,param)
    },


    //#endregion

    //#region 报损单

    //获取报损单列表
    ListLoss(param){
        return axios.post(`${base.base}/Loss/ListLoss`,param)
    },

    //获取单个报损单(包含子信息)
    SingleLossAllInfo(param){
        return axios.get(`${base.base}/Loss/SingleLossAllInfo`,{
            params:param
        })
    },

    //保存报损单(包含子信息)
    SaveLossAllInfo(param){
        return axios.post(`${base.base}/Loss/SaveLossAllInfo`,param)
    },

    //更新报损单状态
    UpdateLossState(param){
        return axios({
            url:`${base.base}/Loss/UpdateLossState`,
            method:'post',
            params:param
        })
    },

    //删除报损单(包含子信息)
    DeleteLossAllInfo(param){
        return axios.post(`${base.base}/Loss/DeleteLossAllInfo`,param)
    },

    //删除报损单明细
    DeleteLossDetail(param){
        return axios({
            url:`${base.base}/Loss/DeleteLossDetail`,
            method:'post',
            params:param
        })
    },

    //加载报损单选中的产品列表
    GetLossDetailListByParam(param){
        return axios.post(`${base.base}/Loss/GetLossDetailListByParam`,param)
    },

    //报损单创建财务单
    LossCreateFinanceNoCash(param){
        return axios.post(`${base.base}/Loss/CreateFinanceNoCash`,param)
    },

    //撤销报损单
    CancelLoss(param){
        return axios({
            url:`${base.base}/Loss/CancelLoss`,
            method:'post',
            params:param
        })
    },

    //#endregion


    //#region 入库单

    //列表
    ListBillIn(param){
        return axios.post(`${base.base}/BillIn/ListBillIn`,param)
    },

    //获取单个入库单
    SingleBillInAllInfo(param){
        return axios.get(`${base.base}/BillIn/SingleBillInAllInfo`,{
            params:param
        })
    },

    //保存入库单
    SaveBillInAllInfo(param){
        return axios.post(`${base.base}/BillIn/SaveBillInAllInfo`,param)
    },

    //更新入库单状态
    UpdateBillInByState(param){
        return axios.post(`${base.base}/BillIn/UpdateBillInByState`,param)
    },

    //删除入库单
    DeleteBillInAllInfo(param){
        return axios.post(`${base.base}/BillIn/DeleteBillInAllInfo`,param)
    },

    //删除入库单明细
    DeleteBillInDetail(param){
        return axios({
            url:`${base.base}/BillIn/DeleteBillInDetail`,
            method:'post',
            params:param
        })
    },

    //删除入库单附加成本明细
    DeleteBillInExtra(param){
        return axios({
            url:`${base.base}/BillIn/DeleteBillInExtra`,
            method:'post',
            params:param
        })
    },

    //批量保存入库单
    BatchSaveBillIn(param){
        return axios.post(`${base.base}/BillIn/BatchSaveBillIn`, param)
    },

    //批量生成PDF
    BatchBillInCreatePdf(param){
        return axios.post(`${base.base}/BillIn/BatchBillInCreatePdf`,param)
    },


    //#endregion

    //#region 出库单

    //出库单列表
    ListBillOut(param){
        return axios.post(`${base.base}/BillOut/ListBillOut`,param)
    },

    //获取单个出库单
    SingleBillOutAllInfo(param){
        return axios.get(`${base.base}/BillOut/SingleBillOutAllInfo`,{
            params:param
        })
    },

    //保存出库单
    SaveBillOutAllInfo(param){
        return axios.post(`${base.base}/BillOut/SaveBillOutAllInfo`,param)
    },

    //更新出库单状态
    UpdateBillOutByState(param){
        return axios.post(`${base.base}/BillOut/UpdateBillOutByState`,param)
    },

    //删除出库单
    DeleteBillOutAllInfo(param){
        return axios.post(`${base.base}/BillOut/DeleteBillOutAllInfo`,param)
    },

    //删除出库单明细
    DeleteBillOutDetail(param){
        return axios({
            url:`${base.base}/BillOut/DeleteBillOutDetail`,
            method:'post',
            params:param
        })
    },

    //批量保存出库单
    BatchSaveBillOut(param){
        return axios.post(`${base.base}/BillOut/BatchSaveBillOut`, param)
    },

    //批量生成PDF
    BatchBillOutCreatePdf(param){
        return axios.post(`${base.base}/BillOut/BatchBillOutCreatePdf`,param)
    },

    //#endregion

    //#region 原始单据相关接口

    //获取原始单据列表
    GetOriginList(param){
        return axios.post(`${base.base}/BillIn/GetOriginList`,param)
    },

    //获取单个原始单据明细列表
    GetOriginDetailList(param){
        return axios.post(`${base.base}/BillIn/GetOriginDetailList`,param)
    },

    //#endregion
    
    //#region 移仓单

    //分页-列表
    PageMoveList(param){
        return axios.post(`${base.base}/Move/PageMoveList`,param)
    },

    //获取单个信息(所有信息)
    GetMoveAllInfo(param){
        return axios.get(`${base.base}/Move/GetMoveAllInfo`,{
            params:param
        })
    },

    //保存
    SaveMoveAllInfo(param){
        return axios.post(`${base.base}/Move/SaveMoveAllInfo`,param)
    },

    //删除单个(所有信息)
    DeleteMoveAllInfo(param){
        return axios.post(`${base.base}/Move/DeleteMoveAllInfo`,param)
    },

    //删除单个明细
    DeleteMoveDetailById(param){
        return axios({
            url:`${base.base}/Move/DeleteMoveDetailById`,
            method:'post',
            params:param
        })
    },

    //更新状态
    UpdateMoveState(param){
        return axios({
            url:`${base.base}/Move/UpdateMoveState`,
            method:'post',
            params:param
        })
    },
    

    //#endregion

 }

 export default wms