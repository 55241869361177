<template>
  <div>
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="账户名称">
                <el-input v-model="info.search.accountName"></el-input>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>           
                
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="info.dataList" border stripe show-summary :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="60"></el-table-column>
            <el-table-column label="账户编号">
                <template v-slot="scope">
                    <span>{{scope.row.accountNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="accountName" label="账户名称"></el-table-column>
            <el-table-column prop="totalPrice" label="账户余额(元)"></el-table-column>
            <el-table-column prop="startPrice" label="初始余额(元)"></el-table-column>
            <el-table-column label="初始日期">
                <template v-slot="scope">
                    <div class="cellOverFlow">{{DateFormat(scope.row.startDate)}}</div>
                </template>
            </el-table-column>

            <el-table-column label="统计标识">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.statFlag == 0" type="primary">关闭</el-tag>
                    <el-tag v-if="scope.row.statFlag == 1" type="danger">开启</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="零售标识">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.retailFlag == 0" type="primary">关闭</el-tag>
                    <el-tag v-if="scope.row.retailFlag == 1" type="danger">开启</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="排序">
                <template v-slot="scope">
                    <span>{{scope.row.sort}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.accountNumber)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.accountNumber)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
                
                <!-- <el-button size="small">开始</el-button> -->
              </template>
            </el-table-column>
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="45%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditAccount :editId="info.id" @closeBox="btnCloseBox"></EditAccount>
    </el-dialog>

  </div>
</template>

<script>
import EditAccount from '@/views/Fms/Account/EditAccount.vue'
import { ElMessage } from 'element-plus'
export default {
    components:{
        EditAccount
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    accountName:'',
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'账户详情'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                accountName:this.info.search.accountName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.ListAccount(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.accountNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增账户"
            }else{
                this.BoxEditInfo.title = "编辑账户"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(accountNumber){
            let param = [accountNumber]
            this.$api.finance.DeleteAccount(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.finance.DeleteAccount(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.accountName ="";
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },  

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList()  //获取数据列表
    }
}
</script>

<style>

</style>