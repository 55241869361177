/**
 * 财务模块相关
 * 
 */

//差额单
import ListDifference from '@/views/Bill/Difference/ListDifference.vue'
//业务单
import ListBusiness from '@/views/Bill/Business/ListBusiness.vue'
//库存账
import ListStockDetail from '@/views/Ma/StockDetail/ListStockDetail.vue'
import ListStockDetailDay from '@/views/Ma/StockDetail/ListStockDetailDay.vue'
import ListStockDetailMonth from '@/views/Ma/StockDetail/ListStockDetailMonth.vue'
//资金交易账
import ListTradeDetail from '@/views/Ma/TradeDetail/ListTradeDetail.vue'
import ListTradeDetailDay from '@/views/Ma/TradeDetail/ListTradeDetailDay.vue'
import ListTradeDetailMonth from '@/views/Ma/TradeDetail/ListTradeDetailMonth.vue'


//会计科目
import ListSubject from '@/views/Fms/Subject/ListSubject.vue'
//账户管理
import ListAccount from '@/views/Fms/Account/ListAccount.vue'
//发票信息
import ListInvoice from '@/views/Fms/Invoice/ListInvoice.vue'
//费用单
import ListCost from '@/views/Fms/Cost/ListCost.vue'
//财务单
import ListFinance from '@/views/Fms/Finance/ListFinance.vue'
//非现金财务单
import ListNoCash from '@/views/Fms/NoCash/ListNoCash.vue'
//账户结余
import ListSurplus from '@/views/Fms/Surplus/ListSurplus.vue'
//财务类型
import ListFinanceType from '@/views/Fms/Type/ListType.vue'
//对账单
import ListCycle from '@/views/Fms/Cycle/ListCycle.vue'
//摊销单
import ListAmortize from '@/views/Fms/Amortize/ListAmortize.vue'
//业务对账单
import ListCycleBusiness from '@/views/Fms/CycleBusiness/ListCycleBusiness.vue'
//财务差额项
import ListFinanceBalance from '@/views/Fms/Balance/ListFinanceBalance.vue'


//资产负债表
import BalanceSheet from '@/views/Fms/Report/BalanceSheet.vue'


export default [
    {
        //差额单
        name:'ListDifference',
        path:'listdifference',
        component:ListDifference
    },
    {
        //业务单
        name:'ListBusiness',
        path:'listbusiness',
        component:ListBusiness
    },
    {
        //库存明细账
        name:'ListStockDetail',
        path:'liststockdetail',
        component:ListStockDetail
    },
    {
        //库存日记账
        name:'ListStockDetailDay',
        path:'liststockdetailday',
        component:ListStockDetailDay
    },
    {
        //库存月记账
        name:'ListStockDetailMonth',
        path:'liststockdetailmonth',
        component:ListStockDetailMonth
    },

    {
        //会计科目
        name:'ListSubject',
        path:'listsubject',
        component:ListSubject
    },
    {
        //账户管理
        name:'ListAccount',
        path:'listaccount',
        component:ListAccount
    },
    {
        //发票信息
        name:'ListInvoice',
        path:'listinvoice',
        component:ListInvoice
    },
    {
        //费用单
        name:'ListCost',
        path:'listcost',
        component:ListCost
    },
    {
        //财务单
        name:'ListFinance',
        path:'listfinance',
        component:ListFinance
    },
    {
        //结余记录
        name:'ListSurplus',
        path:'listsurplus',
        component:ListSurplus
    },
    {
        //财务类型
        name:'ListFinanceType',
        path:'ListFinanceType',
        component:ListFinanceType
    },
    {
        //账期管理
        name:'ListCycle',
        path:'listcycle',
        component:ListCycle
    },
    {
        //摊销单
        name:'ListAmortize',
        path:'listamortize',
        component:ListAmortize
    },
    {
        //非现金账
        name:'ListNoCash',
        path:'listnocash',
        component:ListNoCash
    },
    {
        //交易明细账
        name:'ListTradeDetail',
        path:'listtradedetail',
        component:ListTradeDetail
    },    
    {
        //交易日账
        name:'ListTradeDetailDay',
        path:'listtradedetailday',
        component:ListTradeDetailDay
    },    
    {
        //交易月账
        name:'ListTradeDetailMonth',
        path:'listtradedetailmonth',
        component:ListTradeDetailMonth
    },    

    {
        //资产负债表
        name:'BalanceSheet',
        path:'balancesheet',
        component:BalanceSheet
    },

    {
        //业务对账单
        name:'ListCycleBusiness',
        path:'listcyclebusiness',
        component:ListCycleBusiness
    },
    {
        //财务差额项
        name:'ListFinanceBalance',
        path:'listfinancebalance',
        component:ListFinanceBalance
    },
    

]