<template>
  <!-- 选择库存产品列表 -->
  <div>
    <el-divider></el-divider>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true"
            label-width="80px">

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.departmentId" :data="departmentList" :default-expand-all="true" class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="仓库">
                    <el-select v-model="info.search.warehouseNumber" clearable @change="GetDataList" class="search-200">
                        <el-option v-for="warehouse in userWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber" />
                    </el-select>
                </el-form-item>

                <el-form-item label="产品类目">
                    <el-tree-select v-model="info.search.cid" :data="selectCategoryList" @change="GetDataList" :default-expand-all="true" class="search-200"/>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" placeholder="分类、品牌、产品名称" v-on:keyup.enter="GetDataList" class="search-200"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 产品列表 -->
    <el-table :data="info.dataList" border stripe style="width: 100%" @select="btnCheckbox" @select-all="btnAllCheckbox" @row-click="TableRowClick" ref="table">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="departmentName" label="部门" min-width="100"/>
        <el-table-column prop="warehouseName" label="仓库" min-width="100"/>
        <el-table-column prop="cidName" label="类目" min-width="90"/>        
        <el-table-column prop="brandName" label="品牌" min-width="80"/>
        <el-table-column prop="spuName" label="产品名称" min-width="100"/>        
        <el-table-column prop="skuName" label="规格" min-width="100"/>
        <el-table-column prop="realStockNum" label="实物库存(最小单位)" min-width="100">
            <template v-slot="scope">
                <span style="color:blue">{{scope.row.realStockNum}} {{scope.row.minUnitName}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="realStockNum" label="实物库存(默认单位)" min-width="100">
            <template v-slot="scope">
                <span style="color:blue">{{scope.row.defUnitStockNum}} {{scope.row.defUnitName}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="lockStockNum" label="锁定库存(最小单位)" min-width="100">
            <template v-slot="scope">
                <span style="color:red">{{scope.row.lockStockNum}} {{scope.row.minUnitName}}</span>
            </template>
        </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-container class="page_one">
        <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[10, 50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange">
        </el-pagination>
    </el-container>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //部门-下拉列表-目录树
import UserWhList from '@/utils/mixins/UserWhList.js'  //用户关联仓库
import CategoryList from '@/utils/mixins/CategoryList.js'   //类目
export default {
    props:['dpId'],  //部门编号
    mixins:[UserDptList,CategoryList,UserWhList],
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:10,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    departmentId:this.dpId,  //部门编号
                    warehouseNumber:'', //仓库编号
                    cid:0,    //产品类目
                    searchName:''
                },                
            },
            selectedItem:[],   //选择后产品列表
            warehouseList:[],  //仓库列表
            categoryList:[],   //分类列表
        }
    },
    methods:{
                
        //加载数据列表
        GetDataList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.departmentId!=0){
                newDepartmentList = [this.info.search.departmentId]
            }
            //仓库
            let newWarehouseList = []
            if(this.info.search.warehouseNumber!=''){
                newWarehouseList = [this.info.search.warehouseNumber]
            }

            let param = {
                isSkuUnit:1,   //是否加载单位列表
                departmentList:newDepartmentList,    
                warehouseList:newWarehouseList,
                cid:this.info.search.cid,
                searchName:this.info.search.searchName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.ListStockInfo(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }
            })
        },
        //选中多选框
        btnCheckbox(row){
            this.selectedItem = row
        },
        //全选
        btnAllCheckbox(){
            this.selectedItem = this.info.dataList
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.departmentId=this.dpId;
            this.info.search.warehouseNumber = '';
            this.info.search.cid=0;
            this.info.search.searchName='';
            this.GetDataList();
        },

        //选中行时(标准模板)
        TableRowClick(row){
            let isExist = false; //判断是否存在
            this.selectedItem = this.selectedItem.filter(item=>{                
                if(row.skuNumber != item.skuNumber){
                    return item;
                }else{                    
                    //如果存在
                    isExist = true;
                }                
            })
            
            if(isExist){
                this.$refs.table.toggleRowSelection(row,false);
            }else{
                this.selectedItem.push(row)
                this.$refs.table.toggleRowSelection(row,true);
            }
        },

    },
    mounted(){
        this.GetDataList();   //加载产品数据
    }
}
</script>

<style>

</style>