<template>
  <div>
    <!-- 选择产品 -->
    <div class="skuBtnList" v-if="returnPurchaseInfo.info && returnPurchaseInfo.info.state ==1 && returnPurchaseInfo.info.originNumber=='' ">
      <el-button class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
    </div>

    <!-- 销售列表 -->
    <el-table :data="returnPurchaseInfo.detailList" border stripe show-summary style="width: 100%">

        <el-table-column label="出库仓库" min-width="100">
              <template v-slot="scope">
                <span>{{scope.row.warehouseName}}</span>
              </template>
          </el-table-column>

        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="100"/>
        <el-table-column prop="skuName" label="规格名称" min-width="100"/>
        <el-table-column label="单位" min-width="70">
            <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1 && returnPurchaseInfo.info.originNumber==''" v-model="scope.row.unitId"  size="small" @change="ChangeSkuUnit(scope.$index)">
                    <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                </el-select>
                <span v-else>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="unitPrice" label="单价(元)" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1 && returnPurchaseInfo.info.originNumber==''" v-model="scope.row.unitPrice" :precision="2" size="small" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="planNum" label="计划数量" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="0" :max="scope.row.maxNum" :precision="2" size="small" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.planNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="realNum" label="实际数量" min-width="120">
            <template v-slot="scope">
                <span>{{scope.row.realNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" width="90">
            <template v-slot="scope">
                <span>{{scope.row.totalPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="参考价 / 库存" min-width="110">
            <template v-slot="scope">
                <span>{{scope.row.ckPrice}} / {{scope.row.maxNum}}</span>
            </template>
        </el-table-column>

        <el-table-column label="备注">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.remark"></el-input>
                <span v-else >{{scope.row.remark}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope" v-if="returnPurchaseInfo.info && returnPurchaseInfo.info.state <=2">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
            <SelectStockSkuList :dpId="returnPurchaseInfo.info.departmentId" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    components:{
        SelectStockSkuList,
    },
    data(){
        return{
            //选择产品-弹出框
            BoxSkuInfo:{
                isVisible:false,
                title:'选择库存产品'
            },
        }
    },
    computed:{
        ...mapState('Erp',['returnPurchaseInfo'])
    },
    methods:{
        //选择产品
        BtnSelectSku(){
            this.BoxSkuInfo.isVisible = true
        },

        //保存选中产品
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let newRow = {
                        id:0,
                        systemNumber:this.returnPurchaseInfo.info.systemNumber,
                        billNumber:this.returnPurchaseInfo.info.billNumber,
                        warehouseNumber:item.warehouseNumber,
                        warehouseName:item.warehouseName,
                        cid:item.cid,
                        cidName:item.cidName,
                        brandId:item.brandId,
                        brandName:item.brandName,
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        unitId:item.defUnitId,   //默认单位编号
                        unitName:item.defUnitName,  //默认单位名称
                        unitPrice:0,
                        planNum:1,
                        realNum:0,
                        maxNum:item.realStockNum-item.lockStockNum,  //最大出库数量=库存数-锁定库存数
                        totalPrice:0,
                        remark:'',
                        isDel:0,
                        isEdit:1,
                        skuUnitList:[],   //单位列表
                        ckPrice:0,   //参考单价
                    }
                    
                    //#region 加载单位列表
                    let param = {
                        skuNumber:item.skuNumber
                    }
                    this.$api.goods.GetSkuUnitList(param).then(res=>{
                        if(res.data.status === 200){
                            newRow.skuUnitList = res.data.data;
                        }
                    })
                    //#endregion
                    //#region 单位发生变化时-重新计算价格、库存
                    let param2 = {
                        warehouseNumber:item.warehouseNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.defUnitId
                    }
                    this.$api.wms.GetSkuUnitStockInfo(param2).then(res=>{
                        if(res.data.status === 200){
                            newRow.ckPrice = res.data.data.unitPrice;  //参考成本单价
                            newRow.maxNum = res.data.data.unitStock;  //库存
                        }
                    })
                    //#endregion
                    setTimeout(()=>{
                        this.returnPurchaseInfo.detailList.push(newRow);
                    },500);
                    
                });
            }
            this.BoxSkuInfo.isVisible = false  //关闭选择产品弹出框
        },

        //编辑行
        EditRow(id){
            this.returnPurchaseInfo.detailList = this.returnPurchaseInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                    //#region 加载单位列表
                    let param = {
                        skuNumber:item.skuNumber
                    }
                    this.$api.goods.GetSkuUnitList(param).then(res=>{
                        if(res.data.status === 200){
                            item.skuUnitList = res.data.data;
                        }
                    }) 
                    //#endregion
                    //#region 单位发生变化时-重新计算价格、库存
                    let param2 = {
                        warehouseNumber:item.warehouseNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.unitId
                    }
                    this.$api.wms.GetSkuUnitStockInfo(param2).then(res=>{
                        if(res.data.status === 200){
                            item.ckPrice = res.data.data.unitPrice;  //参考成本单价
                            item.maxNum = res.data.data.unitStock;  //库存
                        }
                    })
                    //#endregion
                }
                return item
            })
        },

        //删除销售明细
        DeleteRow(billNumber, id, index){
            this.returnPurchaseInfo.detailList.splice(index,1)
            if(id !=0){
                let param = {
                    billNumber:billNumber,
                    id:id
                }
                this.$api.erp.DeleteReturnPurchaseDetail(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
            //重新计算总价
            this.OnChangeNum();  
        },

        //计划数量改变时
        OnChangeNum(){
            let newtotalPrice = 0;   //主表-总价
            this.returnPurchaseInfo.detailList = this.returnPurchaseInfo.detailList.map(item=>{
                item.totalPrice = this.myRound(item.unitPrice * item.planNum,2);
                newtotalPrice += item.totalPrice
                return item
            })
            this.returnPurchaseInfo.info.totalPrice = this.myRound(newtotalPrice,2)  //更新主表-总价
        },

        //当单位发生变化时
        ChangeSkuUnit(index){
            this.returnPurchaseInfo.detailList = this.returnPurchaseInfo.detailList.map((item,index2)=>{
                if(index === index2){
                    //#region 单位发生变化时-重新计算价格、库存
                    let param3 = {
                        warehouseNumber:item.warehouseNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.unitId
                    }
                    this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                        if(res.data.status === 200){
                            item.ckPrice = res.data.data.unitPrice;  //成本单价
                            item.maxNum = res.data.data.unitStock;  //库存
                        }
                    })
                    //#endregion
                }                
                return item
            })
            //重新计算总价
            setTimeout(()=>{
                this.OnChangeNum();
            },500)
        },
        
    }
}
</script>

<style>

</style>