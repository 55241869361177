<template>
    <!-- 编辑 - 供应商信息 -->
  <div>

      <el-form :inline="true"
        v-model="supplierInfo.info"
        label-width="90px"
        class="tabSpu"
        label-position="right">
            <el-row v-if="supplierInfo.info">

                <el-col :span="12">
                    <el-form-item>
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>供应商简称</span>
                        </template>
                        <el-input v-model="supplierInfo.info.supplierName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="供应商全称">
                        <el-input v-model="supplierInfo.info.supplierAlias" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="联系人">
                        <el-input v-model="supplierInfo.info.linkMan" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="联系电话">
                        <el-input v-model="supplierInfo.info.linkPhone" class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :span="12">
                    <el-form-item label="省市区">
                        <el-cascader :options="areaList" clearable v-model="supplierInfo.info.selectArea" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="详细地址">
                        <el-input v-model="supplierInfo.info.address" minlength="6" maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="主营业务">
                        <el-input v-model="supplierInfo.info.mainBusiness" maxlength="150" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="显示标识">
                        <el-select v-model="supplierInfo.info.isShow" class="search-200">
                            <el-option :value="1" label="显示"></el-option>
                            <el-option :value="0" label="隐藏"></el-option>
                        </el-select>
                        <!-- 提示 -->
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="在制单过程中交易者列表是否显示该供应商"
                            placement="top"
                        >
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                    </el-form-item>
                </el-col>

            </el-row>


      </el-form>

  </div>
</template>

<script>
import areaList from '@/utils/mixins/AreaList.js'
import { mapState } from 'vuex'
export default {
    name:'TabSupplier',
    mixins:[areaList],
    computed:{
        ...mapState('Purchase',['supplierInfo'])
    },

}
</script>

<style>

</style>