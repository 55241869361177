<template>
  <div>
    <!-- 按钮 -->
    <div class="skuBtnList">
      <el-button class="iconfont icon-add" type="primary" plain @click="btnSelectSku">选择产品</el-button>
    </div>

    <!-- 盘点列表 -->
    <el-table :data="checkInfo.detailList" border show-summary style="width: 100%">
      <el-table-column prop="warehouseName" label="仓库" min-width="100"/>
      <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
      <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
      <el-table-column prop="skuName" label="产品名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
      <el-table-column prop="skuName" label="规格" min-width="100"/>
      <el-table-column label="单位" width="110">
        <template v-slot="scope">
            <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId" @change="ChangeSkuUnit">
                <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
            </el-select>
            <span v-else>{{scope.row.unitName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="stockNum" label="库存数量" min-width="90" />
      <el-table-column label="盘点数量" min-width="100">
        <template v-slot="scope">
            <el-input-number v-if="checkInfo.info.state === 2" v-model="scope.row.checkNum" :min="0" :controls="false" @change="OnChangeNum" style="width:90px;"></el-input-number>
            <span v-else >{{scope.row.checkNum}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="diffNum" label="差额数量" min-width="90">
        <template v-slot="scope">
            <span>{{this.myRound(scope.row.diffNum,2)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="100">
        <template v-slot="scope">
            <el-tag v-if="scope.row.checkState == 0" type="warning">未盘点</el-tag>
            <el-tag v-if="scope.row.checkState == 1" type="danger">待复盘</el-tag>
            <el-tag v-if="scope.row.checkState == 2" type="info">已盘点</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope">
            <!-- 编辑、删除 -->
            <el-button v-if="checkInfo.info && checkInfo.info.state <=2 && scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm v-if="checkInfo.info && checkInfo.info.state <=2"
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.checkNumber,scope.row.id,scope.$index)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            <!-- 复盘 -->
            <el-button v-if="checkInfo.info && checkInfo.info.state ==3" size="small" type="danger" @click="btnUpdateCheckDetail(scope.row.id,1)">复盘</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 选择产品 -->
    <el-dialog title="选择产品" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
          <SelectStockSkuList :dpId="checkInfo.info.departmentId" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

    <div>
        盘点单流程：保存盘点单 - 开始盘点 - 提交盘点结果 - 完成盘点 - 审核
    </div>

  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    components:{
        SelectStockSkuList
    },
    data(){
        return{
            //选择产品-弹出框
            BoxSkuInfo:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['checkInfo'])
    },
    methods:{
        //选择产品
        btnSelectSku(){
            this.BoxSkuInfo.isVisible = true
        },
        //保存选中产品
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            console.log(itemList)
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let newRow = {
                        id:0,
                        systemNumber:'',
                        checkNumber:'',
                        warehouseNumber:item.warehouseNumber,
                        warehouseName:item.warehouseName,
                        cid:item.cid,
                        cidName:item.cidName,
                        brandId:item.brandId,
                        brandName:item.brandName,
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        unitId:item.defUnitId,   //默认单位编号
                        unitName:item.defUnitName,  //默认单位名称
                        costUnitPrice:item.defUnitPrice,  //成本单价 = 默认单位成本价
                        stockNum:item.defUnitStockNum,  //默认单位-实际库存数
                        checkNum:0,  //盘点数
                        diffNum:0 - item.defUnitStockNum,  //差额数量=盘点数-默认单位库存数
                        costTotalPrice:0,     //总成本价
                        checkState:0,  //盘点状态
                        isDel:0,
                        isEdit:1,  //编辑状态
                        skuUnitList:item.skuUnitList,   //单位列表
                    }

                    setTimeout(()=>{
                        this.checkInfo.detailList.push(newRow);
                    },500);                    
                });
                this.BoxSkuInfo.isVisible = false; //关闭选择产品弹出框
            }
        },

        //编辑行
        EditRow(id){
            this.checkInfo.detailList = this.checkInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                }
                return item;
            });
        },

        //删除行
        DeleteRow(checkNumber,id,index){
            if(id !=0){
                let param = {
                    checkNumber:checkNumber,
                    id:id
                }
                this.$api.wms.DeleteCheckDetail(param).then(res=>{
                    if(res.data.status === 200){
                        this.checkInfo.detailList.splice(index,1);
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }else{
                this.checkInfo.detailList.splice(index,1);
            }
        },

        //当前明细数量、改变时触发
        OnChangeNum(){
            this.checkInfo.detailList = this.checkInfo.detailList.map(item=>{
                item.diffNum = item.checkNum - item.stockNum
                return item
            })
        },

        //当单位发生变化时
        ChangeSkuUnit(){
            this.checkInfo.detailList = this.checkInfo.detailList.map(item=>{
                //#region 单位发生变化时-重新计算价格、库存
                if(item.isEdit ==1){
                    let param3 = {
                        warehouseNumber:item.warehouseNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.unitId
                    }
                    this.$api.wms.GetSkuUnitStockInfoByBefore(param3).then(res=>{
                        if(res.data.status === 200){
                            item.stockNum = res.data.data.unitStock;  //库存= 实际库存数 
                            item.costUnitPrice = res.data.data.unitPrice;  //成本单价
                            item.diffNum = item.checkNum - item.stockNum;  //差额数量=盘点数-库存数
                        }
                    })
                }                
                //#endregion
                return item;
            });
            //重新计算差额
            this.OnChangeNum();
        },

        //更新单个明细状态：2:复盘
        btnUpdateCheckDetail(id,state){
            let param = {
                detailId:id,
                state:state
            }
            this.$api.wms.UpdateStateOfDetail(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success('已更新')
                    //更新页面
                    this.checkInfo.detailList = this.checkInfo.detailList.map(element=>{
                        if(element.id == id){
                            element.checkState = state
                        }
                        return element;
                    })
                }
            })
        }

    }
}
</script>

<style>

</style>