<template>
  <div>
      <el-divider></el-divider>

      <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>
                
                <el-col :span="24">
                    <el-form-item label="类目编号">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>类目编号</span>
                        </template>
                        <el-input v-model="info.categoryNumber" class="search-200"></el-input>
                        <!-- 提示 -->
                        <!-- <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="自定义编号,可以为空"
                            placement="top">
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>  -->
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="类目名称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>类目名称</span>
                        </template>
                        <el-input v-model="info.categoryName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="归属类目">
                        <el-tree-select v-model="info.pid" :data="selectCategoryList" check-strictly :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="24" >
                    <el-form-item label="排列顺序">
                        <el-input-number v-model.number="info.sort" style="width:200px" :controls="false" :min="0"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

      <!-- 功能按钮 -->
      <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
      </div>

  </div>
</template>

<script>
import CategoryList from '@/utils/mixins/CategoryList.js'
import { ElMessage } from 'element-plus'
export default {
    name:'EditCategory',
    props:['editId'],
    mixins:[CategoryList],
    data(){
        return{
            info:{
                cid:0,
                categoryNumber:'',
                categoryName:'',
                pid:0,
                sort:0
            },
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            if(this.editId!=0){
                let param = {
                    cid:this.editId
                }
                this.$api.goods.SingleCategory(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        console.log(res.data.msg)
                    }
                })
            }
            else
            {
                this.btnClearData();
            }
        },

        //保存信息 -type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.goods.SaveCategory(this.info).then(res=>{
            if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                        //
                        this.SelectCategoryList();
                    }
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            }).catch(e=>{
                console.log(e)
            }) 
        },

        //清空实体
        btnClearData(){
            let newInfo = {
                cid:0,
                categoryNumber:'',
                categoryName:'',
                pid:this.info.pid,
                sort:0
            };
            this.info = newInfo;
            this.GetCategoryNumber();
        },

        //获取类目编号
        GetCategoryNumber(){
            let param = {
                field:'CategoryNumber',
            }
            this.$api.common.GetNumber(param).then(res=>{
                if(res.data.status === 200){
                    this.info.categoryNumber = res.data.data;
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.GetDataInfo()
    }

}
</script>

<style scoped>
    .el-divider{margin-top: 0px;}
    .el-dialog__body{padding-top: 0px;}
</style>