<template>
  <div class="SingleInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName">

        <el-tab-pane label="客户信息" name="tabCustomer">
            <TabCustomer></TabCustomer>
        </el-tab-pane>

        <el-tab-pane label="发票信息" name="tabInvoice">
            <TabCustomerInvoice></TabCustomerInvoice>
        </el-tab-pane>

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup" v-if="customerInfo.info">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import TabCustomer from '@/views/Sales/Customer/TabCustomer.vue'
import TabCustomerInvoice from '@/views/Sales/Customer/TabCustomerInvoice.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    name:'EditCustomer',
    props:['editId','dataList'],
    components:{
        TabCustomer,
        TabCustomerInvoice
    },
    data(){
        return{
            info:{
                activeName:'tabCustomer',
                customerNumber:this.editId,
            },
        }
    },
    computed:{
        ...mapState('Erp',['customerInfo'])  //客户信息实体
    },
    methods:{
        ...mapMutations('Erp',['SetCustomerInfo']), 

        //加载数据
        GetDataInfo(){
            if(this.info.customerNumber !=0){
                let param = {
                    customerNumber:this.info.customerNumber
                }
                this.$api.erp.SingleCustomerAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetCustomerInfo(res.data.data)
                    }
                    else{
                        this.btnClearData()
                    }
                })
            }
            else{
                this.btnClearData()
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.erp.SaveCustomerAllInfo(this.customerInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                    
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },
        
        //清空实体
        btnClearData(){
            var newInfo = {
                info:{
                    systemNumber:'',
                    customerNumber:'',
                    customerName:'',
                    customerAlias:'',
                    chainRole:0,
                    linkMan:'',
                    linkPhone:'',
                    departmentId:0,
                    mainBusiness:'',
                    certNumber:'',
                    grade:'A',
                    isShow:1,
                    isDel:0
                },
                invoiceList:[],  //发票
            }
            this.SetCustomerInfo(newInfo)
        },
        
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.customerNumber === this.info.customerNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.customerNumber = prevInfo.customerNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.customerNumber === this.info.customerNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.customerNumber = prevInfo.customerNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>