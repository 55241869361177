/**
 * 产品模块接口列表
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const goods = {

    //#region 产品品牌

    //获取品牌列表
    PageBrandList(param){
        return axios.post(`${base.base}/Brand/PageBrandList`,param)
    },

    //获取单个品牌
    SingleBrand(param){
        return axios.get(`${base.base}/Brand/SingleBrand`,{
            params:param
        })
    },

    //保存品牌
    SaveBrand(param){
        return axios.post(`${base.base}/Brand/SaveBrand`,param)
    },

    //删除品牌
    DeleteBrand(param){
        return axios.post(`${base.base}/Brand/DeleteBrand`,param)
    },

    //导出品牌到Excel
    ExportBrand(param){
        return axios.get(`${base.base}/Brand/ExportBrand`,{
            params:param
        })
    },

    //下拉列表-品牌列表
    SelectTreeBrandList(){
        return axios.get(`${base.base}/Brand/SelectTreeBrandList`)
    },
    
    //#endregion

    //#region 产品类目

    //获取产品类目-目录树
    SelectTreeCategory(){
        return axios.get(`${base.base}/Category/SelectTreeCategory`)
    },

    //获取产品类目列表
    PageCategoryList(param){
        return axios.post(`${base.base}/Category/PageCategoryList`,param)
    },

    //获取单个产品类目信息
    SingleCategory(param){
        return axios.get(`${base.base}/Category/SingleCategory`,{
            params:param
        })
    },

    //保存产品类目
    SaveCategory(param){
        return axios.post(`${base.base}/Category/SaveCategory`,param)
    },

    //删除产品类目
    DeleteCategory(param){
        return axios.post(`${base.base}/Category/DeleteCategory`,param)
    },

    //#endregion

    //#region 类目关联品牌

    //获取类目关联品牌  ####################
    ListCategorySelectedBrand(param){
        return axios.get(`${base.base}/Category/ListCategorySelectedBrand`,{
            params:param
        })
    },

    //保存类目关联品牌
    SaveCategoryBrand(param){
        return axios.post(`${base.base}/Category/SaveCategoryBrand`,param)
    },

    //获取类目所属品牌列表(目录树)
    SelectCategoryBrandList(param){
        return axios.get(`${base.base}/Category/SelectCategoryBrandList`,{
            params:param
        })
    },

    //#endregion

    //#region 产品单位

    //获取单位列表
    ListUnit(param){
        return axios.get(`${base.base}/Unit/ListUnit`,{
            params:param
        })
    },

    //获取单个单位信息
    SingleUnit(param){
        return axios.get(`${base.base}/Unit/SingleUnit`,{
            params:param
        })
    },

    //保存单位
    SaveUnit(param){
        return axios.post(`${base.base}/Unit/SaveUnit`,param)
    },

    //删除单位
    DeleteUnit(param){
        return axios.post(`${base.base}/Unit/DeleteUnit`,param)
    },

    //获取计量单位列表
    GetUnitList(){
        return axios.get(`${base.base}/Unit/GetUnitList`)
    },

    //#endregion

    //#region 产品规格

    //获取产品列表 - 分页 - 按照sku编号排序
    PageSkuList2(param){
        return axios.post(`${base.base}/Goods/PageSkuList2`,param)
    },

    //获取产品列表 - 分页- 按照权重排序
    ListSku(param){
        return axios.post(`${base.base}/Goods/PageSkuList`,param)
    },

    //获取单个产品所有信息（包含子信息）
    SingleGoodsAllInfo(param){
        return axios.get(`${base.base}/Goods/SingleGoodsAllInfo`,{
            params:param
        })
    },

    //获取单个产品的规格列表
    GetSkuList(param){
        return axios.get(`${base.base}/Goods/GetSkuList`,{
            params:param
        })
    },
    
    //保存产品信息
    SaveGoodsInfo(param){
        return axios.post(`${base.base}/Goods/SaveGoodsInfo`,param)
    },
    
    //删除产品规格
    DeleteSku(param){
        return axios.post(`${base.base}/Goods/DeleteSku`,param)
    },

    //导出产品规格列表
    ExportSku(param){
        return axios.post(`${base.base}/Goods/ExportSku`,param)
    },

    //获取规格列表-下拉列表
    SelectSkuList(param){
        return axios.get(`${base.base}/Goods/SelectSkuList`,{
            params:param
        })
    },

    //#endregion

    //#region 计量单位换算

    //获取规格关联的单位换算列表
    GetSkuUnitInfo(param){
        return axios.get(`${base.base}/SkuUnit/GetSkuUnitInfo`,{
            params:param
        })
    },

    //保存规格关联单位换算列表
    SaveSkuUnit(param){
        return axios.post(`${base.base}/SkuUnit/SaveSkuUnit`,param)
    },

    //删除规格对应单位换算
    DeleteSkuUnit(param){
        return axios({
            url:`${base.base}/SkuUnit/DeleteSkuUnit`,
            method:'post',
            params:param
        })
    },

    //获取单个规格的单位换算列表
    GetSkuUnitList(param){
        return axios.get(`${base.base}/SkuUnit/GetSkuUnitList`,{
            params:param
        })
    },

    //★获取最小单位数量★
    GetMinUnitTotalNum(param){
        return axios.get(`${base.base}/SkuUnit/GetMinUnitTotalNum`,{
            params:param
        })
    },

    //★获取指定单位的数量★
    GetUnitNumByUnitId(param){
        return axios.get(`${base.base}/SkuUnit/GetUnitNumByUnitId`,{
            params:param
        })
    },


    //#endregion

    //#region 规格图片

    //列表(无效)
    ListGoodsSkuImage(param){
        return axios.get(`${base.base}/Goods/ListGoodsSkuImage`,{
            params:param
        })
    },

    //保存规格图片(无效)
    SaveGoodsSkuImageList(param){
        return axios.post(`${base.base}/Goods/SaveGoodsSkuImageList`,param)
    },

    //删除规格图片
    DeleteGoodsSkuImage(param){
        return axios({
            url:`${base.base}/Goods/DeleteGoodsSkuImage`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 产品信息

    //产品列表
    PageSpuList(param){
        return axios.post(`${base.base}/Spu/PageSpuList`,param)
    },

    //产品信息 - 多个信息
    GetSpuAllInfo(param){
        return axios.get(`${base.base}/Spu/GetSpuAllInfo`,{
            params:param
        })
    },

    //保存 - 多信息
    SaveSpuAllInfo(param){
        return axios.post(`${base.base}/Spu/SaveSpuAllInfo`,param)
    },

    //产品信息 - 单个信息
    GetSpuInfo(param){
        return axios.get(`${base.base}/Spu/GetSpuInfo`,{
            params:param
        })
    },

    //保存 - 单信息
    SaveSpuInfo(param){
        return axios.post(`${base.base}/Spu/SaveSpuInfo`,param)
    },

    //产品 - 下拉列表
    SelectSpuList(param){
        return axios.get(`${base.base}/Spu/SelectSpuList`,{
            params:param
        })
    },

    //删除产品
    DeleteSpu(param){
        return axios.post(`${base.base}/Spu/DeleteSpu`,param)
    },

    //删除产品图片
    DeleteSpuImage(param){
        return axios({
            url:`${base.base}/Spu/DeleteSpuImage`,
            method:'post',
            params:param
        })
    },

    //#endregion 
 }

 export default goods