<template>
  <div>
    
    <el-row>
        <!-- 目录树 -->
        <el-col :span="4" class="tree_info">
            <el-card class="box-card">
              <template #header>
                  <div class="card-header">
                      <span>产品类目</span>
                  </div>
              </template>
              <!-- 目录树 -->
              <el-scrollbar :height="GetTreeDataHeight">
                  <el-tree :data="info.treeList"
                  node-key="id"
                  :default-expanded-keys="[0]"
                    @node-click="handleNodeClick" empty-text="暂无产品类目" />
              </el-scrollbar>                
            </el-card>
        </el-col>

        <!-- 主列表 -->
        <el-col :span="20">
          <!-- 搜索功能 -->
          <el-row :style="showSearch.search_hidden">
              <!-- <el-col :span="24">
                  <el-form :inline="true">
                  <el-form-item label="地区名称">
                      <el-input v-model="info.search.fullName"></el-input>
                  </el-form-item>

                  <el-form-item>
                      <el-button class="iconfont icon-search" type="primary" plain>搜索</el-button>
                      <el-button class="iconfont icon-exchangerate" plain>重置</el-button>
                  </el-form-item>

                  </el-form>
              </el-col> -->
          </el-row>

        <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                  <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="流程图"
                  placement="top">
                  <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                  </el-tooltip>

                  <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="打印"
                  placement="top">
                  <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                  </el-tooltip>

                  <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="导出数据"
                  placement="top">
                  <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                  </el-tooltip>
              </el-button-group>       
              
            </el-col>
          </el-row>
          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox">
            <el-table-column prop="cid" type="selection" width="50" />
            <el-table-column prop="cid" label="编号" width="150" />
            <el-table-column prop="categoryName" label="类目名称" />
            <el-table-column prop="pidName" label="归属" />
            <el-table-column prop="sort" label="排序" />
            <el-table-column label="操作" width="130">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.cid)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.cid)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
                <!-- <el-button size="small" type="warning" @click="showCategoryBrand(scope.row.cid)">所属品牌</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                v-model:currentPage="info.page"
                v-model:page-size="info.size"
                :page-sizes="[50, 200, 500]"
                :background="true"
                layout="total, sizes, prev, pager, next, jumper"
                :total="info.total"
                @current-change="btnPagechange"
                @size-change="btnSizechange"
              >
              </el-pagination>
          </el-container>
        </div>

        </el-col>
    </el-row>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="400"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="btnReloadData">
        <EditCategory :editId="info.id" @closeBox="btnCloseBox"></EditCategory>
    </el-dialog>

    <!-- 所属品牌 弹出框 -->
    <!-- <el-dialog :title="BoxBrand.title" 
      v-model="BoxBrand.isVisible"
      width="42%"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @opened="OpenBrand">
      <BoxTreeBrand :cid="info.id" ref="treeBrandInfo"></BoxTreeBrand>
      <template #footer>
        <el-button type="primary" @click="btnSaveTreeBrand">保存</el-button>
        <el-button @click="BoxBrand.isVisible = false">取消</el-button>
      </template>
    </el-dialog> -->

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import EditCategory from '@/views/Goods/Category/EditCategory.vue'
// import BoxTreeBrand from '@/views/Goods/BoxTreeBrand.vue'
export default {
    name:'ListCategory',
    components:{
      EditCategory,
      // BoxTreeBrand
    },
    data(){
        return{
            info:{
                treeList:[],  //类目目录树
                pid:0,  //目录树父节点
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //单个新增、编辑
                ids:[],   //新增、编辑、删除 的参数
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'新增类目'
            },
            //所属品牌-弹出框
            // BoxBrand:{
            //   isVisible:false,
            //   title:'所属品牌'
            // }
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-190
        },
        //左侧目录树自适应窗体高度
        GetTreeDataHeight(){
          return window.innerHeight - 250
        },
    },
    methods:{
      //Tree 数据
      GetTreeList(){
          this.$api.goods.SelectTreeCategory().then(res=>{
              if(res.data.status === 200){
                  this.info.treeList = res.data.data;  //加载目录树数据
              }else{
                  ElMessage({type:'error',message:res.data.msg})
              }
          }).catch(e=>{
              console.log(e.message)
          })
      },
      //加载数据列表
      GetDataList(){
          let param = {
              pid:this.info.pid,
              page:this.info.page,
              size:this.info.size
          }
          this.$api.goods.PageCategoryList(param).then(res=>{
              if(res.data.status === 200){
                  this.info.dataList = res.data.data;  //获取数据
                  //分页
                  this.info.total = res.data.total
                  this.info.page = res.data.pageNumber
              }else{
                  ElMessage({type:'error',message:res.data.msg})
              }
          }).catch(e=>{
              console.log(e.message)
          })
      },
      //获取目录树节点
      handleNodeClick(data){
        this.info.pid = data.id;
        this.GetDataList()
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.cid)
      },
      //分页-页数变化时
      btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
      },
      //新增编辑信息
      btnEditInfo(id){
          this.info.id = id
          if(id==0){
              this.BoxEditInfo.title = "新增类目"
          }else{
              this.BoxEditInfo.title = "编辑类目"
          }  
          this.BoxEditInfo.isVisible = true;
      },
      //删除单个
      btnDeleteInfo(id){
      let param = [id]
      this.$api.goods.DeleteCategory(param).then(res=>{
              if(res.data.status === 200){
                  ElMessage.success(res.data.msg)
                  this.btnReloadData();
              }
              else{
                  ElMessage.error(res.data.msg)
              }
          })
      },  
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
          }

          this.$api.goods.DeleteCategory(this.info.ids).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.btnReloadData();
            }
            else{
              ElMessage.error(res.data.msg)
            }
          })
      },

      //重新加载方法
      btnReloadData(){
        this.GetTreeList()
        this.GetDataList()
      },
      //关闭弹出框
      btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
      },

      //所属品牌按钮
      showCategoryBrand(cid){
        this.info.id = cid
        this.BoxBrand.isVisible = true
      },
      //打开品牌弹出框
      OpenBrand(){
        this.$refs.treeBrandInfo.GetDataInfo()
      },
      //保存品牌弹出框
      btnSaveTreeBrand(){
        const brandList = this.$refs.treeBrandInfo.GetSelectedKeys()
        var param = {
          brandList:brandList,
          cid:this.info.id
        }
        this.$api.goods.SaveCategoryBrand(param).then(res=>{
          if(res.data.status === 200){
            ElMessage({type:'success',message:res.data.msg})
            this.BoxBrand.isVisible = false
          }else{
            ElMessage({type:'error',message:res.data.msg})
          }
        }).catch(e=>{
          console.log(e.message)
        })
      },


    },
    mounted(){
      this.GetTreeList()  //加载目录树
      this.GetDataList()  //加载Table数据
    }
}
</script>

<style>

</style>