<template>
  <div>
    <!-- 编辑产品信息 -->
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName">

      <el-tab-pane label="产品信息" name="tabInfo">
          <TabInfo></TabInfo>
      </el-tab-pane>
      <el-tab-pane label="产品介绍" name="tabDesc">
          <TabDesc></TabDesc>
      </el-tab-pane>
      
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
      <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
      <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>

      <el-button-group>
          <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
          <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
      </el-button-group>
    </div>

  </div>
</template>

<script>
import TabInfo from '@/views/Goods/Spu/TabInfo.vue'
import TabDesc from '@/views/Goods/Spu/TabDesc.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    components:{
      TabInfo,
      TabDesc
    },
    data(){
      return{
        info:{
          activeName:'tabInfo',
          strSpuNumber:this.editId,
        }
      }
    },
    computed:{
      ...mapState('Erp',['spuInfo'])
    },
    methods:{
      ...mapMutations('Erp',['SetSpuInfo']),

      //加载数据
      GetDataInfo(){
        if(this.info.strSpuNumber !=0){
          let param = {
            spuNumber:this.info.strSpuNumber
          }
          this.$api.goods.GetSpuAllInfo(param).then(res=>{
            if(res.data.status === 200){
              this.SetSpuInfo(res.data.data)
            }else{
              this.btnClearData();
            }
          })
        }else{
          this.btnClearData();
        }
        
      },

      //保存信息 -type:1:保存并关闭 2:保存并新增
      btnSaveData(type){
        this.$api.goods.SaveSpuAllInfo(this.spuInfo).then(res=>{
          if(res.data.status === 200){
              ElMessage.success(res.data.msg);
              //类型
              if(type==1){
                this.btnClose();
              }else{
                this.btnClearData();
              }
          }else{
            ElMessage.error(res.data.msg);
          }
        })
      },

      //清空实体
      btnClearData(){
        var allInfo = {
          info:{
            id:0,
            systemNumber:'',
            spuNumber:'',
            cid:0,
            spuName:'',
            spuDesc:'',
          },
          imgList:[]
        }
        this.SetSpuInfo(allInfo);
      },

      //关闭按钮
      btnClose(){
          this.$emit('closeBox')  //关闭弹出框
      },

      //上一条
      btnPrevious(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.spuNumber === this.info.strSpuNumber
          })
          let prevIndex = currentIndex -1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有上一条了");
              return false;
          }
          else{
              this.info.strSpuNumber = prevInfo.spuNumber
              this.GetDataInfo()
          }
          
      },
      //下一条
      btnNext(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.spuNumber === this.info.strSpuNumber
          })
          let prevIndex = currentIndex + 1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有下一条了");
              return false;
          }
          else{
              this.info.strSpuNumber = prevInfo.spuNumber
              this.GetDataInfo()
          }
      },
      
    },
    mounted(){
      this.GetDataInfo();
    },
}
</script>

<style>

</style>