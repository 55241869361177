<template>
  <div>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="品牌名称">
                  <el-input v-model="info.search.brandName"></el-input>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
      <!-- 功能栏 -->
      <el-row :gutter="10" class="operation">
        <el-col :span="15">
          <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
          <el-popconfirm 
              title="确认要删除吗?"
              @confirm="btnBatchDeleteInfo">
                <template #reference>
                  <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
              </el-popconfirm>
        </el-col>

        <el-col :span="9" class="operation_hidden">
          <el-button-group>
                                            
              <el-tooltip
              class="box-item"
              effect="dark"
              content="流程图"
              placement="top">
              <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
              </el-tooltip>

              <el-tooltip
              class="box-item"
              effect="dark"
              content="打印"
              placement="top">
              <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
              </el-tooltip>

              <el-tooltip
              class="box-item"
              effect="dark"
              content="导出数据"
              placement="top">
              <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
              </el-tooltip>
          </el-button-group>        
          
        </el-col>
      </el-row>

      <!-- 列表 -->
      <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="brandId" type="selection" width="50" />  
        <el-table-column prop="brandId" label="编号" />
        <el-table-column prop="brandName" label="品牌名称" />
        <el-table-column prop="logo" label="Logo">
          <template v-slot="scope">
            <el-image v-if="scope.row.logo"
              style="width: 70px; height: 70px"
              :src="scope.row.logo"
              :preview-src-list="[scope.row.logo]"
              :hide-on-click-modal="true"
              fit="cover">
            </el-image>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" />
        <el-table-column label="操作" width="130" fixed="right">
          <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.brandId)">详情</el-button>
            <el-popconfirm 
              title="确认要删除吗?"
              @confirm="btnDeleteInfo(scope.row.brandId)">
                <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-container class="page_one">
          <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
          >
          </el-pagination>
      </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="400"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditBrand :editId="info.id" @closeBox="btnCloseBox"></EditBrand>
    </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import EditBrand from '@/views/Goods/Brand/EditBrand.vue'
export default {
    name:'ListBrand',
    components:{
        EditBrand
    },
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{  //搜索条件
            brandName:''
          }
        },
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增品牌'
        }

      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
      //加载数据列表
      GetDataList(){
        let param = {
          brandName:this.info.search.brandName,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.goods.PageBrandList(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }
        }).catch(e=>{
          console.log(e.message)
        })
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.brandId)
      },
      //分页-页数变化时
      btnPagechange(page){
          this.info.page = page
          this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
          this.info.size = size
          this.info.page = 1
          this.GetDataList() //加载Table数据
      },
      
      //新增编辑部门信息
      btnEditInfo(id){
        this.info.id = id
        if(id==0){
            this.BoxEditInfo.title = "新增品牌"
        }else{
            this.BoxEditInfo.title = "编辑品牌"
        }  
        this.BoxEditInfo.isVisible = true;
      },
      //删除按钮
      btnDeleteInfo(id){
        let param = [id]
        this.$api.goods.DeleteBrand(param).then(res=>{
          if(res.data.status === 200){
            ElMessage({type:'success',message:res.data.msg})
            this.GetDataList()
          }
          else{
            ElMessage({type:'error',message:res.data.msg})
          }
        })
      },
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
          ElMessage({type:'error',message:'请选择要删除的选项'})
          return false
        }

        this.$api.goods.DeleteBrand(this.info.ids).then(res=>{
          if(res.data.status === 200){
            ElMessage({type:'success',message:res.data.msg})
            this.GetDataList()
          }
          else{
            ElMessage({type:'error',message:res.data.msg})
          }
        })
      },
      
      //重置按钮
      btnResetSearch(){
        this.info.search.brandName='',
        this.GetDataList()
      },
      //导出系统列表
      btnExportInfo(){
        let param = {
          brandName:this.info.search.brandName,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.goods.ExportBrand(param).then(res=>{
          if(res.data.status === 200){
            const alink = document.createElement('a');
            alink.href = res.data.data;
            document.body.appendChild(alink);
            alink.click();
            document.body.removeChild(alink)
          }
          else
          {
            ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e.message)
        })
      },
      //关闭弹出框
      btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
      },

    },
    mounted(){
      this.GetDataList()  //加载数据Table数据
    }
}
</script>

<style scoped>
.brandLogo{width: 80px;height: 80px;border: solid 1px #ccc;}  
</style>