<template>
  <div>
    <!-- 退供单 -->
    
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="退供单号">
                <el-input v-model="info.search.billNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="物流标识">
                <el-select v-model="info.search.isTransport" class="search-150">
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="未使用"></el-option>
                    <el-option :value="1" label="已使用"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="业务标识">
                <el-select v-model="info.search.billFlag" class="search-150">
                    <el-option label="全部" :value="-1" />
                    <el-option label="未生成" :value="0" />
                    <el-option label="已生成" :value="1" />
                </el-select>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="待审核"></el-option>
                    <el-option :value="3" label="已驳回"></el-option>
                    <el-option :value="4" label="已审核"></el-option>
                    <el-option :value="5" label="已出库"></el-option>
                    <el-option :value="10" label="已完成"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="记账日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnReturnPurchaseAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnReturnPurchaseDelete')"
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
                <el-button v-if="CheckUserButtonAuth('BtnReturnPurchaseAddTransport')" class="iconfont icon-navigation" type="primary" plain @click="btnLookTransport">添加到物流单</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnReturnPurchaseAddBusiness')"
                    title="确认要创建业务单?"
                    @confirm="btnCreateBusiness">
                    <template #reference>
                        <el-button class="iconfont icon-dingdan" type="warning" plain>生成业务单</el-button>
                    </template>
                </el-popconfirm>            
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" />  
        <el-table-column label="退供单号" width="170">
            <template v-slot="scope">
                {{scope.row.billNumber}}
            </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        
        <el-table-column label="班次" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.shiftName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.shiftName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="交易者类型" min-width="95">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="交易者" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" />
        <el-table-column label="物流标识">
            <template #default="scope">
                <el-tag v-if="scope.row.isTransport ==0" type="info">未使用</el-tag>
                <el-tag v-if="scope.row.isTransport ==1">已使用</el-tag>
            </template>
        </el-table-column>        
        <el-table-column label="业务单标识">
            <template #default="scope">
                <el-tag v-if="scope.row.billFlag == 0" type="danger">未生成</el-tag>
                <el-tag v-if="scope.row.billFlag == 1" type="info">已生成</el-tag>
            </template>
        </el-table-column>  
        <el-table-column label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                <el-tag v-if="scope.row.state ==4" type="warning">已审核</el-tag>
                <el-tag v-if="scope.row.state ==5" type="success">已出库</el-tag>
                <el-tag v-if="scope.row.state ==10" type="info">已完成</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="originNumber" label="原始单号" min-width="160"></el-table-column>
        <el-table-column prop="billTime" label="记账时间" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.billTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.billTime)}}</span>
                </el-tooltip>                
            </template>
        </el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnReturnPurchaseDelete')"
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.billNumber)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditReturnPurchase :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditReturnPurchase>
    </el-drawer>

    <!-- 选择物流单 - 弹出框 -->
    <el-dialog :title="BoxTransport.title" 
    v-model="BoxTransport.isVisible"
    width="50%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <SelectTansport :numbers="info.ids" :billType="14" @closeBox="btnTransportCloseBox"></SelectTansport>
    </el-dialog>

  </div>
</template>

<script>
import EditReturnPurchase from '@/views/Pms/ReturnPurchase/EditReturnPurchase.vue'
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import { ElLoading, ElMessage } from 'element-plus'
import SelectTansport from '@/views/Tms/Transport/SelectTransport.vue'   //选择物流单
export default {
  name:'ListReturnSale',
  components:{
    EditReturnPurchase,
    SelectTansport
  },
  mixins:[UserDptList,AllTradeList],
  data(){
    return{
      info:{
        dataList:[],  //Table数据
        page:1,
        size:50,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
            billNumber:'',   //退工单号
            selectDepartmentList:[],
            tradeNumber:'',  //交易者编号
            isTransport:-1,
            billFlag:-1,  //业务单标识
            state:0,
            datePoint:'',
        },
      },
      //编辑-弹出框
      BoxEditInfo:{
        isVisible:false,
        title:'新增退供单'
      },
      //物流单-弹出框
      BoxTransport:{
        isVisible:false,
        title:'选择物流单'
      },

    }
  },
  computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-285
        }
    },
  methods:{

    //加载数据列表
    GetDataList(){
        if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
        }

        //获取、转换部门
        let newDepartmentList = []
        if(this.info.search.selectDepartmentList!=null){
            newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                return item
            })
        }

        let param = {
            billNumber:this.info.search.billNumber,
            departmentList:newDepartmentList,
            tradeNumber:this.info.search.tradeNumber,
            isTransport:this.info.search.isTransport,
            billFlag:this.info.search.billFlag,
            state:this.info.search.state,
            startDate:this.DateFormat(this.info.search.datePoint[0]),
            endDate:this.DateFormat(this.info.search.datePoint[1]),
            page:this.info.page,
            size:this.info.size
        }
        this.$api.erp.ListReturnPurchase(param).then(res=>{
            if(res.data.status === 200){
                this.info.dataList = res.data.data;  //获取数据
                //分页
                this.info.total = res.data.total
                this.info.page = res.data.pageNumber
            }else{
                ElMessage.error(res.data.msg);
            }
        }).catch(e=>{
            console.log(e)
        })
    },
    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.billNumber)
    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //新增编辑部门信息
    btnEditInfo(billNumber){
      this.info.id = billNumber
      if(billNumber==0){
          this.BoxEditInfo.title = "新增退供单"
      }else{
          this.BoxEditInfo.title = "编辑退供单"
      }
      this.BoxEditInfo.isVisible = true;
    },
    
    //关闭弹出框
    btnCloseBox(){
        this.BoxEditInfo.isVisible = false;
    },

    //选择物流单
    btnLookTransport(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要添加到物流单的单据')
            return false
        }
        this.BoxTransport.isVisible = true
    },
    //关闭物流弹出框
    btnTransportCloseBox(){
        this.BoxTransport.isVisible = false
    },


    //删除按钮
    btnDeleteInfo(billNumber){
        let param = [billNumber]
        this.$api.erp.DeleteReturnPurchaseAllInfo(param).then(res=>{
          if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
          }
          else{
              ElMessage.error(res.data.msg)
          }
        })
    },
    //批量删除
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        this.$api.erp.DeleteReturnPurchaseAllInfo(this.info.ids).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList();
                this.info.ids = [];
            }
            else{
                ElMessage.error(res.data.msg)
            }
        })
    },
    //重置按钮
    btnResetSearch(){
        this.info.search.billNumber='',
        this.info.search.selectDepartmentList = [];
        this.info.search.tradeNumber='';
        this.info.search.isTransport=-1;
        this.info.search.billFlag=-1;
        this.info.search.state=0;
        this.info.search.datePoint = '';
        this.info.ids = [];  //编号数组
        this.info.page = 1;
        this.GetDataList()
    }, 

    //生成业务单
    btnCreateBusiness(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要生成业务单的单据')
            return false
        }

        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在提交,请稍候!',
            background:'rgba(0, 0, 0, 0.7)',
        })

        this.$api.erp.ReturnPurchaseCreateBusiness(this.info.ids).then(res=>{
            loading.close();
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //
                this.GetDataList();
                this.info.ids = [];
            }else{
                ElMessage.error(res.data.msg);
            }
        })
    },

  },
  mounted(){
    this.GetDataList()  //加载列表
  }

}
</script>

<style>

</style>