<template>
  <div>

    <el-form :inline="true"
    v-model="lossInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="lossInfo.info">
            
            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="报损单号">
                    <el-input v-model="lossInfo.info.lossNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="lossInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <!-- <el-col :span="8">
                <el-form-item label="记账时间">
                    <el-date-picker v-model="lossInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD HH:mm:ss" />
                </el-form-item>
            </el-col> -->

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="总价">
                  <el-input v-model="lossInfo.info.totalPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="lossInfo.info.state ==1" type="info">新建单</el-tag>
                  <el-tag v-if="lossInfo.info.state ==2" type="warning">待审核</el-tag>
                  <el-tag v-if="lossInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="lossInfo.info.state ==4" type="success">已审核</el-tag>
                  <el-tag v-if="lossInfo.info.state ==5" type="primary">已报损</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList],
    computed:{
        ...mapState('Erp',['lossInfo'])
    },
}
</script>

<style>

</style>