<template>
  <div>
    <el-form :inline="true"
        v-model="retailInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="retailInfo.info">

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="零售单号">
                        <el-input v-model="retailInfo.info.retailNumber" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="所属班次">
                        <el-select v-model="retailInfo.info.shiftNumber" class="search-200">
                            <el-option v-for="shiftInfo in shiftRecordList" :key="shiftInfo.shiftNumber" :label="shiftInfo.shiftName" :value="shiftInfo.shiftNumber"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="所属部门">
                        <el-tree-select v-if="retailInfo.info.state === 1" v-model="retailInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                        <el-tree-select v-else v-model="retailInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" disabled/>
                    </el-form-item>
                </el-col>


                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="交易者">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>交易者</span>
                        </template>
                        <el-select v-if="retailInfo.info.state==1" v-model="retailInfo.info.tradeNumber" filterable clearable class="search-200">
                            <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                        <el-input v-else v-model="retailInfo.info.tradeName" class="search-200" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="收款账户">
                        <el-select v-if="retailInfo.info.state==1" v-model="retailInfo.info.accountNumber" class="search-200">
                            <el-option v-for="accountInfo in retailAccountList" :key="accountInfo.accountNumber" :label="accountInfo.accountName" :value="accountInfo.accountNumber"></el-option>
                        </el-select>
                        <el-input v-else v-model="retailInfo.info.accountName" class="search-200" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="应付金额">
                        <el-input-number v-model="retailInfo.info.totalPrice" disabled :min="0" :precision="2" :controls="false" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="实付金额">
                        <el-input-number v-if="retailInfo.info.state === 1" v-model="retailInfo.info.realPrice" :min="0" :precision="2" :controls="false" class="search-200"></el-input-number>
                        <el-input-number v-else v-model="retailInfo.info.realPrice" :min="0" :precision="2" :controls="false" class="search-200" disabled></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="创建时间">
                        <el-input v-model="retailInfo.info.createTime" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="付款时间">
                        <el-input v-model="retailInfo.info.payTime" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="支付操作">
                        <el-select v-if="retailInfo.info.state === 1" v-model="retailInfo.info.payMode" class="search-200">
                            <el-option label="系统内支付" :value="1"/>
                            <el-option label="系统外支付" :value="2"/>
                        </el-select>
                        <el-select v-else v-model="retailInfo.info.payMode" class="search-200" disabled>
                            <el-option label="系统内支付" :value="1"/>
                            <el-option label="系统外支付" :value="2"/>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="状态">
                    <el-tag v-if="retailInfo.info.state ==1" type="danger">未支付</el-tag>
                    <el-tag v-if="retailInfo.info.state ==5" type="info">已支付</el-tag>
                    <el-tag v-if="retailInfo.info.state ==9" type="info">已取消</el-tag>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户关联部门列表
import UserWhList from '@/utils/mixins/UserWhList.js'  //用户关联仓库
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    name:'TabRetail',
    mixins:[UserDptList,UserWhList,PartTradeList],
    data(){
        return{
            shiftRecordList:[],  //班次列表
            retailAccountList:[],  //零售专用账户列表
        }
    },
    computed:{
        ...mapState('Erp',['retailInfo'])
    },
    methods:{
        //获取班次列表
        SelectShiftRecordList(){
            this.$api.erp.GetShiftRecordList().then(res=>{
                if(res.data.status === 200){
                    this.shiftRecordList = res.data.data;
                    //新增时，默认加载第一个 
                    if(this.shiftRecordList !=null && this.retailInfo.info.retailNumber==''){
                        this.retailInfo.info.shiftNumber = this.shiftRecordList[0].shiftNumber;
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //零售专用账户列表
        SelectRetailAccountList(){
            this.$api.finance.SelectRetailAccountList().then(res=>{
                if(res.data.status === 200){
                    this.retailAccountList = res.data.data;
                    //新增时：默认加载第一个
                    if(this.retailAccountList !=null && this.retailInfo.info.accountNumber==''){
                        this.retailInfo.info.accountNumber = this.retailAccountList[0].accountNumber;
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        

    },
}
</script>

<style>

</style>