<template>
  <!-- 库存列表 -->
  <div>
      <!-- 搜索功能 -->
      <el-row>
          <el-col :span="24">
              <el-form :inline="true"
              label-width="70px">

                  <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200"/>
                  </el-form-item>

                  <el-form-item label="仓库">
                    <el-select v-model="info.search.selectWarehouseList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="item in userWhList" :key="item.id" :label="item.warehouseName" :value="item.warehouseNumber"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="产品类目">
                    <el-tree-select v-model="info.search.cid" :data="selectCategoryList" @change="GetDataList" :default-expand-all="true" class="search-200"/>
                  </el-form-item>

                  <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" placeholder="分类、品牌、产品名称" v-on:keyup.enter="GetDataList" class="search-200"></el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                  </el-form-item>

              </el-form>
            </el-col>
      </el-row>

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <!-- <el-button class="iconfont icon-dingdan" type="warning" plain @click="btnGoodsOrder">生成建议采购单</el-button> -->
                <el-button v-if="CheckUserButtonAuth('BtnStockRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportList"></el-button>
                    </el-tooltip>
                </el-button-group>     
              
          </el-col>
          </el-row>

          <!-- 列表 -->
          <el-table :data="dataList" border stripe show-summary style="width: 100%" :height="GetDataHeight" @select="btnCheckbox" ref="table"> 
              <el-table-column prop="id" type="selection" width="55" />  
              <el-table-column prop="departmentName" label="部门名称" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top"
                  >
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="warehouseName" label="仓库名称" min-width="100" />
              <el-table-column label="产品类目" min-width="90">
                <template v-slot="scope">
                    <span>{{scope.row.cidName}}</span>
                </template>
              </el-table-column>
              <el-table-column label="品牌" min-width="80">
                <template v-slot="scope">
                    <span>{{scope.row.brandName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="spuName" label="产品名称" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top"
                  >
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="skuName" label="规格" min-width="100"/>

              <el-table-column prop="warnStock" label="安全库存" min-width="100">
                <template #header>
                    <div>安全库存</div>
                    <div class="minTitle">(最小单位)</div>
                </template>
                <template #default="scope">
                    {{scope.row.warnStock}}{{scope.row.minUnitName}}
                </template>
              </el-table-column>
              
              <el-table-column label="预警标识" min-width="100">
                <template #default="scope">
                    <!-- 实际库存-锁定库存 < 安全库存 -->
                    <el-tag v-if="scope.row.realStockNum - scope.row.lockStockNum < scope.row.warnStock" type="danger">库存不足</el-tag>
                </template>
              </el-table-column>

              <el-table-column prop="realStockNum" label="库存数(最小单位)" min-width="100">
                <template #header>
                    <div>实物库存数</div>
                    <div class="minTitle">(最小单位)</div>
                </template>
                <template #default="scope">
                    {{scope.row.realStockNum}}{{scope.row.minUnitName}}
                </template>
              </el-table-column>

              <el-table-column  label="库存数(默认单位)" min-width="100">
                <template #header>
                    <div>实物库存数</div>
                    <div class="minTitle">(默认单位)</div>
                </template>
                <template #default="scope">
                    {{scope.row.defUnitStockNum}}{{scope.row.defUnitName}}
                </template>
              </el-table-column>

              <el-table-column prop="lockStockNum" label="锁定库存数(最小单位)" min-width="100">
                <template #header>
                    <div>锁定库存数</div>
                    <div class="minTitle">(最小单位)</div>
                </template>
                <template #default="scope">
                    {{scope.row.lockStockNum}}{{scope.row.minUnitName}}
                </template>
              </el-table-column>

              <el-table-column prop="stockPrice" label="库存成本总价(元)" width="140" />
              <el-table-column label="操作" width="100" fixed="right">
                <template v-slot="scope">     
                    <el-dropdown size="small">
                        <el-button type="primary" size="small">操作<el-icon class="el-icon--right"><arrow-down /></el-icon></el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-if="CheckUserButtonAuth('BtnStockAdjustLockStock')" @click="OpenAdjustLockStock(scope.row.id,scope.row.lockStockNum,scope.row.minUnitName)">调整锁定库存</el-dropdown-item>
                                <el-dropdown-item v-if="CheckUserButtonAuth('BtnStockDelete')" @click="btnDeleteInfo(scope.row.id)">删除</el-dropdown-item>
                                
                            </el-dropdown-menu>                        
                        </template>
                    </el-dropdown>       
                   
                </template>
              </el-table-column>
          </el-table>

      </div>

      <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 调整锁定库存数 -->
    <el-dialog title="调整锁定库存"
    v-model="BoxLockStock.isVisible"
    width="350px"
    @closed="GetDataList">
        请确认要调整的锁定库存数:(单位:{{BoxLockStock.minUnitName}})
        <div>
            <el-input-number v-model="BoxLockStock.lockStockNum" :min="0" :precision="2" :controls="false" style="width:320px"></el-input-number>
        </div>
        <template #footer>
            <el-button type="primary" @click="btnAdjustLockStock">确认</el-button>
            <el-button @click="BoxLockStock.isVisible = false">取消</el-button>
        </template>
    </el-dialog>


  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户关联部门
import UserWhList from '@/utils/mixins/UserWhList.js'  //用户关联仓库
import CategoryList from '@/utils/mixins/CategoryList.js'   //类目
import { ElMessage } from 'element-plus'
import { ArrowDown } from '@element-plus/icons-vue'
export default {
    name:'ListStock',
    mixins:[UserDptList,CategoryList,UserWhList],
    components:{
        ArrowDown,
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                departmentId:0,  //选中的部门编号
                search:{  //搜索条件
                    selectDepartmentList:[],  //选中的部门列表
                    selectWarehouseList:[],  //选中的仓库列表
                    cid:0,  //产品类目
                    searchName:'',   //品牌、分类、产品名称
                },                
            },
            /* 调整锁定库存-弹出框 */
            BoxLockStock:{
                stockId:0,  //编号
                lockStockNum:0,  //锁定库存数量
                minUnitName:'',  //最小单位名称
                isVisible:false,
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-135
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                isSkuUnit:0,   //是否加载单位列表
                departmentList:newDepartmentList,
                warehouseList:this.info.search.selectWarehouseList,
                searchName:this.info.search.searchName,
                cid:this.info.search.cid,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.ListStockInfo(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.id)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search = {
                selectDepartmentList:[],  //部门
                selectWarehouseList:[],  //仓库
                cid:0,  //产品类目
                searchName:'',   //产品名称
            }
            this.GetDataList();
        },

        //删除按钮
        btnDeleteInfo(id){
            let param = [id]
            this.$api.wms.DeleteStock(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },


        //导出数据
        btnExportList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                isSkuUnit:0,   //是否加载单位列表
                departmentList:newDepartmentList,
                warehouseList:this.info.search.selectWarehouseList,
                searchName:this.info.search.searchName,
                page:this.info.page,
                size:this.info.size
            }

            this.$api.wms.ExportStockList(param).then(res=>{
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //生成建议订货单
        btnGoodsOrder(){
            ElMessage.error('开发中');
        },

        //打开-调整锁定库存
        OpenAdjustLockStock(id,lockStockNum,unitName){
            this.BoxLockStock.stockId = id;
            this.BoxLockStock.lockStockNum = lockStockNum;  //锁定库存数量
            this.BoxLockStock.minUnitName = unitName;  //最小单位名称
            this.BoxLockStock.isVisible = true;
        },
        //保存-调整锁定库存
        btnAdjustLockStock(){
            let param = {
                id:this.BoxLockStock.stockId,
                lockStockNum:this.BoxLockStock.lockStockNum,
            }
            this.$api.wms.AdjustLockStock(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.BoxLockStock.isVisible=false;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataList()
    }
}
</script>

<style scoped>
.minTitle{font-size: 12px;}
</style>