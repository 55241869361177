<template>
  <div>
    <!-- 财务交易页面 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="原始单号">
                <el-input v-model="info.search.originNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="原始单类型">
                <el-select v-model="info.search.originType" class="search-150">
                    <el-option label="全部" :value="0" />
                    <el-option label="财务单" :value="30" />
                    <el-option label="零售单" :value="3" />
                    <el-option label="退零单" :value="18" />
                    <el-option label="调账" :value="19" />
                    <el-option label="换钱" :value="22" />
                </el-select>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true"  class="search-200" collapse-tags collapse-tags-tooltip>
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="交易账户">
                <el-select v-model="info.search.accountNumber" clearable class="search-200">
                    <el-option label="无" :value="0" />
                    <el-option v-for="accountInfo in selectAccountList" :key="accountInfo.accountNumber" :label="accountInfo.accountName" :value="accountInfo.accountNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="付款日期">
                <el-date-picker
                    v-model="info.search.payDatePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>    
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" />  
        <el-table-column label="原始单号" width="170">
            <template #default="scope">
                {{scope.row.originNumber}}
            </template>
        </el-table-column>
        <el-table-column label="单据类型" min-width="90">
            <template v-slot="scope">
                <el-tag v-if="scope.row.originType ==30" type="danger">财务单</el-tag>
                <el-tag v-if="scope.row.originType ==3" type="info">零售单</el-tag>
                <el-tag v-if="scope.row.originType ==18" type="primary">退零单</el-tag>
                <el-tag v-if="scope.row.originType ==19" type="info">调账</el-tag>
                <el-tag v-if="scope.row.originType ==22" type="info">换钱</el-tag>
            </template>
        </el-table-column>

        <el-table-column prop="departmentName" label="部门" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        
        <el-table-column label="交易者类型" min-width="95">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="交易者" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        
        <el-table-column label="交易账户" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.accountName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.accountName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="debitPrice" label="借方金额" min-width="110">
            <template v-slot="scope">
                <span class="redPrice">{{this.myRound(scope.row.debitPrice,2)}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="creditPrice" label="贷方金额" min-width="110">
            <template v-slot="scope">
                <span class="redPrice">{{this.myRound(scope.row.creditPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="surplusPrice" label="账户余额" min-width="110">
            <template v-slot="scope">
                <span class="redPrice">{{this.myRound(scope.row.surplusPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="备注" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.remark"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        
        <el-table-column label="付款时间" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.payTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.payTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>                
            </template>
        </el-table-column>

        </el-table>        
    </div>

    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import UserDptList from '@/utils/mixins/UserDptList.js' //部门
import accountList from '@/utils/mixins/AccountList.js'  //账户-下拉列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
export default {
  mixins:[UserDptList,accountList,AllTradeList],
  data(){
    return{
      dataList:[],  //Table数据
      info:{
        page:1,
        size:50,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
          originType:0,
          originNumber:'',
          selectDepartmentList:[],  //选中的部门列表
          tradeNumber:'',
          accountNumber:'',  //账户编号
          payDatePoint:'',  //付款时间
          datePoint:'',    //创建时间          
        }
      }
    }
  },
  computed:{
    //自适应窗体高度
    GetDataHeight(){
        return window.innerHeight-285
    }
  },
  methods:{
    //加载数据列表
    GetDataList(){
      //付款时间
      if(this.info.search.payDatePoint == null){
        this.info.search.payDatePoint = ''
      }
      //创建时间
      if(this.info.search.datePoint == null){
        this.info.search.datePoint = ''
      }      

      let param = {
        originType:this.info.search.originType,
        originNumber:this.info.search.originNumber,
        departmentList:this.info.search.selectDepartmentList,
        tradeNumber:this.info.search.tradeNumber,
        accountNumber:this.info.search.accountNumber,
        startDate:this.DateFormat(this.info.search.datePoint[0]),
        endDate:this.DateFormat(this.info.search.datePoint[1]),
        startPayDate:this.DateFormat(this.info.search.payDatePoint[0]),
        endPayDate:this.DateFormat(this.info.search.payDatePoint[1]),
        page:this.info.page,
        size:this.info.size
      }
      this.$api.finance.PageTradeDetailList(param).then(res=>{
        if(res.data.status === 200){
          this.dataList = res.data.data;  //获取数据
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.id)
    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //删除按钮
    btnDeleteInfo(id){
        let param = [id]
        this.$api.finance.DeleteTradeDetail(param).then(res=>{
          if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
          }
          else{
              ElMessage.error(res.data.msg)
          }
        })
    },
    //批量删除
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        this.$api.finance.DeleteTradeDetail(this.info.ids).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList();
                this.info.ids = [];
            }
            else{
                ElMessage.error(res.data.msg)
            }
        })
    },

    //重置按钮
    btnResetSearch(){
        this.info.search.originType=0;
        this.info.search.originNumber='';
        this.info.search.selectDepartmentList=[];
        this.info.search.tradeNumber='';
        this.info.search.accountNumber='';
        this.info.search.payDatePoint='';
        this.info.search.datePoint = '';
        this.info.ids = [];  //编号数组
        this.info.page = 1;
        this.GetDataList();
    }, 

    //导出业务单列表到Excel
    btnExportInfo(){
        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在导出数据,请稍候!',
            background:'rgba(0, 0, 0, 0.7)',
        })

        //付款时间
        if(this.info.search.payDatePoint == null){
            this.info.search.payDatePoint = ''
        }
        //创建时间
        if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
        }      

        let param = {
            originType:this.info.search.originType,
            originNumber:this.info.search.originNumber,
            departmentList:this.info.search.selectDepartmentList,
            tradeNumber:this.info.search.tradeNumber,
            accountNumber:this.info.search.accountNumber,
            startDate:this.DateFormat(this.info.search.datePoint[0]),
            endDate:this.DateFormat(this.info.search.datePoint[1]),
            startPayDate:this.DateFormat(this.info.search.payDatePoint[0]),
            endPayDate:this.DateFormat(this.info.search.payDatePoint[1]),
            page:this.info.page,
            size:this.info.size
        }
        this.$api.finance.ExportTradeDetail(param).then(res=>{
            loading.close();
            if(res.data.status === 200){
                const alink = document.createElement('a');
                alink.href = res.data.data;
                document.body.appendChild(alink);
                alink.click();
                document.body.removeChild(alink)
            }
            else
            {
                ElMessage.error(res.data.msg)
            }
        })
    },

  },
  mounted(){
    this.GetDataList();  //加载数据
  },
}
</script>

<style scoped>
.redPrice{color: red;}
</style>