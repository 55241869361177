<template>
  <div>
    <!-- 原始单据备注记录 -->
    <el-divider></el-divider>

    <!-- 业务单号 -->
    <el-descriptions>
        <el-descriptions-item label="业务单号">{{billNumber}}</el-descriptions-item>
    </el-descriptions>

    <!-- 列表 -->
    <el-table :data="recordList" border style="width: 100%">

        <el-table-column prop="skuName" label="备注类型">
            <template v-slot="scope">
                <span v-if="scope.row.type == 1">文字</span>
                <span v-if="scope.row.type == 2">图片</span>
                <span v-if="scope.row.type == 3">文件</span>
                <span v-if="scope.row.type == 4">视频</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="备注内容">
            <template v-slot="scope">
                <!-- 文字 -->
                <span v-if="scope.row.type==1" >{{scope.row.content}}</span>
                <!-- 图片 -->
                <el-image v-if="scope.row.type==2"
                    style="width: 70px; height: 70px;border:1px solid #ccc;"
                    hide-on-click-modal
                    :src="scope.row.content"
                    :preview-src-list="[scope.row.content]"
                    fit="cover"
                    />
                <!-- 文件 -->
                <el-link v-if="scope.row.type==3" :href="scope.row.content" target="_blank">{{scope.row.title}}</el-link>
            </template>
        </el-table-column>

        <el-table-column prop="createName" label="创建者">
            <template v-slot="scope">
                <span>{{scope.row.createName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="createName" label="创建时间">
            <template v-slot="scope">
              <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>

    </el-table>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button @click="btnClose">关闭</el-button>
        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>


  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['ywNumber','number','type','dataList'],
    data(){
        return{
            recordList:[],   //原始单据备注记录表
            billNumber:this.ywNumber,  //业务单编号
            originNumber:this.number, //原始单据编号
            originType:this.type,  //原始单类型
        }
    },
    methods:{
        
        //获取数据列表
        GetDataList(){
            let param = {
                billNumber:this.originNumber,
                billType:this.originType
            }
            this.$api.erp.GetRecordList(param).then(res=>{
                if(res.data.status === 200){
                    this.recordList = res.data.data;
                }
            })
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                if(this.originType ===9){
                    return item.billNumber === this.wageNumber
                }else if(this.originType ===10){
                    return item.billNumber === this.costNumber
                }else{
                    return item.billNumber === this.billNumber
                }
                
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                if(this.originType === 9){
                    this.billNumber = prevInfo.wageNumber;
                    this.originNumber = prevInfo.wageNumber;
                    this.originType = 9;
                }else if(this.originType === 10){
                    this.billNumber = prevInfo.costNumber;
                    this.originNumber = prevInfo.costNumber;
                    this.originType = 10;
                }else{
                    this.billNumber = prevInfo.billNumber;
                    this.originNumber = prevInfo.originNumber;
                    this.originType = prevInfo.billType;
                }                
                this.GetDataList()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                if(this.originType ===9){
                    return item.billNumber === this.wageNumber
                }else if(this.originType ===10){
                    return item.billNumber === this.costNumber
                }else{
                    return item.billNumber === this.billNumber
                }
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                if(this.originType === 9){
                    this.billNumber = prevInfo.wageNumber;
                    this.originNumber = prevInfo.wageNumber;
                    this.originType = 9;
                }else if(this.originType === 10){
                    this.billNumber = prevInfo.costNumber;
                    this.originNumber = prevInfo.costNumber;
                    this.originType = 10;
                }else{
                    this.billNumber = prevInfo.billNumber;
                    this.originNumber = prevInfo.originNumber;
                    this.originType = prevInfo.billType;
                }     
                this.GetDataList()
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.GetDataList();
        console.log(this.dataList);
    }
}
</script>

<style>

</style>