<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="出库单" name="tabBill">
            <TabBillOut ref="tabBillOut"></TabBillOut>

            <el-divider>出库单明细</el-divider>
            <TabBillOutDetail></TabBillOutDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabBillOutRecord></TabBillOutRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabBillOutState></TabBillOutState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="billOutInfo.info">
        <el-button type="warning" v-if="CheckUserButtonAuth('BtnBillOutSelectBill') && billOutInfo.info.state !=3 && billOutInfo.info.outNumber =='' " @click="btnOpenBoxBill()">选择出库单据</el-button>
        <el-button type="primary" v-if="billOutInfo.info.state<=2" @click="btnSaveData">保存</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnBillOutConfirm') && billOutInfo.info.outNumber !='' && billOutInfo.info.state <= 2" type="warning" @click="btnUpdateBillOutByState(3)">确认出库</el-button>
        <el-button class="iconfont icon-dayin" v-if="billOutInfo.info.state==3" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 选择单据 - 弹出框 -->
    <el-dialog title="选择出库单据"
    v-model="BoxBill.isVisible"
    width="85%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxOriginOut ref="OriginInfo"></BoxOriginOut>
        <template #footer>
            <el-button type="primary" @click="btnSelectOrigin">选择</el-button>
            <el-button @click="btnCloseBoxBill">关闭</el-button>
        </template>
    </el-dialog>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    width="70%">
        <PrintView v-if="BoxPrint.isVisible" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

  </div>
</template>

<script>
import TabBillOut from '@/views/Wms/BillOut/TabBillOut.vue'
import TabBillOutDetail from '@/views/Wms/BillOut/TabBillOutDetail.vue'
import TabBillOutRecord from '@/views/Wms/BillOut/TabBillOutRecord.vue'
import TabBillOutState from '@/views/Wms/BillOut/TabBillOutState.vue'
import BoxOriginOut from '@/views/Wms/BillOut/BoxOriginOut.vue'
import PrintView from '@/views/Wms/BillOut/PrintView.vue'

import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
export default {
    props:['editId','dataList'],
    components:{
        TabBillOut,
        TabBillOutDetail,
        TabBillOutRecord,
        TabBillOutState,
        BoxOriginOut,
        PrintView,
    },
    data(){
        return{
            info:{
                activeName:'tabBill',
                outNumber:this.editId
            },
            //选择单据-弹出框
            BoxBill:{
                isVisible:false,
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['billOutInfo']),
        ...mapState('Master',['currentUserNumber']),
    },
    methods:{
        ...mapMutations('Erp',['SetBillOutInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.outNumber !=0){
                let param = {
                    outNumber:this.info.outNumber
                }
                this.$api.wms.SingleBillOutAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetBillOutInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(){
            this.$api.wms.SaveBillOutAllInfo(this.billOutInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新编号
                    this.billOutInfo.info.outNumber = res.data.data;
                    this.info.outNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //更新出库状态
        btnUpdateBillOutByState(state){
            let param = {
                outNumber:this.billOutInfo.info.outNumber,
                state:state
            };
            this.$api.wms.UpdateBillOutByState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新状态
                    this.info.outNumber = this.billOutInfo.info.outNumber
                    this.GetDataInfo()
                    //关闭
                    //this.btnClose()
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    systemNumber:'',
                    outNumber:'',
                    departmentId:0,
                    originNumber:'',
                    originType:21,  //新增默认：21
                    state:1,
                    tradeType:0,
                    tradeNumber:'',
                    tradeName:'',
                    receiver:this.currentUserNumber,  //当前用户编号
                    receiveName:'',
                    outTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                },
                detailList:[],
                recordList:[],
                stateList:[]
            }
            this.SetBillOutInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.outNumber === this.info.outNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.outNumber = prevInfo.outNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.outNumber === this.info.outNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.outNumber = prevInfo.outNumber
                this.GetDataInfo()
            }
        },


        //打开选择单据-弹框
        btnOpenBoxBill(){
            this.BoxBill.isVisible = true;
        },
        //关闭选择单据-弹框
        btnCloseBoxBill(){
            this.BoxBill.isVisible = false;
        },
        //选择原始单据
        btnSelectOrigin(){
            let originInfo = this.$refs.OriginInfo.selectedInfo;  //获取选中的单据主信息
            let originDetailList = this.$refs.OriginInfo.selectedDetail;  //获取选中的单据明细列表
            //若有数据的话
            if(originInfo.billNumber !='' && originInfo.billNumber!=undefined){
                originDetailList.forEach(element => {
                    element.originItemId = element.id;
                    element.id = 0;
                    element.isEdit = 1;
                    element.planNum = element.planNum - element.realNum;  //计划数量 = 原计划数量 - 已完成数量
                    element.realNum = element.planNum;  //实际数量 = 计划数量
                    element.totalPrice = 0;  //不要带过来总价，总价是之前已出库存的总价，所以没有意义， 
                    //#region 计划最小单位数量
                    let param2 = {
                        skuNumber:element.skuNumber,
                        unitId:element.unitId,
                        unitNum:element.planNum
                    }
                    this.$api.goods.GetMinUnitTotalNum(param2).then(res=>{
                        if(res.data.status === 200){
                            element.planMinUnitNum = res.data.data;
                        }
                    })
                    //#endregion
                    //#region 加载单位列表
                    let param = {
                        skuNumber:element.skuNumber
                    }
                    this.$api.goods.GetSkuUnitList(param).then(res=>{
                        if(res.data.status === 200){
                            element.skuUnitList = res.data.data;
                        }
                    })
                    //#endregion
                });
                
                var newInfo = {
                    info:{
                        systemNumber:'',
                        outNumber:'',
                        departmentId:originInfo.departmentId,
                        originNumber:originInfo.billNumber,  //原始单据编号
                        originType:originInfo.billType, //原始单据类型
                        state:1,
                        tradeType:originInfo.tradeType,
                        tradeNumber:originInfo.tradeNumber,
                        tradeName:originInfo.tradeName,
                        receiver:this.currentUserNumber,  //当前用户编号
                        receiveName:'',
                        outTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        isFreeze:originInfo.isFreeze, //是否走锁定库存
                    },
                    detailList:originDetailList,
                    recordList:[],
                    stateList:[]
                }   
                this.SetBillOutInfo(newInfo); 
                
            }            
            this.btnCloseBoxBill();  //关闭弹框
        },

        //打开打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>