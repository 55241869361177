<template>
  <div>
    <!-- 新增财务单专用页 -->

    <el-form :inline="true"
        v-model="financeInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="financeInfo.info">

                <el-col :span="8">
                    <el-form-item label="部门">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>部门</span>
                        </template>
                        <el-tree-select v-model="financeInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200">
                        </el-tree-select>
                    </el-form-item>
                </el-col>      

                <el-col :span="8">
                    <el-form-item label="财务类型">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>财务类型</span>
                        </template>
                        <el-select v-model="financeInfo.info.billType" class="search-200">
                            <el-option label="选择类型" :value="0"/>
                            <el-option v-for="typeInfo in financeTypeList" :key="typeInfo.typeId" :label="typeInfo.typeName" :value="typeInfo.typeId"></el-option>
                            
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="会计科目">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>会计科目</span>
                        </template>
                        <el-tree-select v-model="financeInfo.info.subId" :data="selectSubjectList" :default-expanded-keys="[0]" class="search-200">
                        </el-tree-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="交易者">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>交易者</span>
                        </template>
                        <el-select v-model="financeInfo.info.tradeNumber" filterable clearable class="search-200">
                            <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="记账时间">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>记账时间</span>
                        </template>
                        <el-date-picker v-model="financeInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD hh:mm:ss" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="总金额">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>总金额</span>
                        </template>
                        <el-input-number v-model="financeInfo.info.totalPrice" :precision="2" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="备注">
                        <el-input v-model="financeInfo.info.remark" :maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import UserDptList from '@/utils/mixins/UserDptList.js'  //用户关联部门列表
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import SubjectList from '@/utils/mixins/SubjectList.js'  //会计科目

export default {
    mixins:[UserDptList,SubjectList,PartTradeList],
    data(){
        return{
            financeTypeList:[],    //财务类型列表
        }
    },
    computed:{
        ...mapState('Erp',['financeInfo'])
    },
    methods:{
        //财务类型
        SelectFinanceTypeList(){
            this.$api.finance.SelectFinanceTypeList().then(res=>{
                if(res.data.status === 200){
                    this.financeTypeList = res.data.data;
                }
            })
        },

    },
    mounted(){
        this.SelectFinanceTypeList();  //财务类型 
    },
}
</script>

<style>

</style>