<template>
  <div v-if="businessInfo.info">


    <!-- 原始单据的状态列表 -->
    <el-table :data="businessInfo.stateList" border style="width: 100%">

        <el-table-column v-if="businessInfo.info.billType==1" label="状态">
            <template v-slot="scope">
                <span v-if="scope.row.state ==1">新建单</span>
                <span v-if="scope.row.state ==2">已驳回</span>
                <span v-if="scope.row.state ==3">审核通过</span>
                <span v-if="scope.row.state ==4">已发货</span>
                <span v-if="scope.row.state ==5">已收货</span>
                <span v-if="scope.row.state ==6">已入库</span>
                <span v-if="scope.row.state ==7">已取消</span>
                <span v-if="scope.row.state ==10">已关单</span>
            </template>
        </el-table-column>

        <el-table-column v-if="businessInfo.info.billType==2" label="状态">
            <template v-slot="scope">
                <span v-if="scope.row.state ==1">新建单</span>
                <span v-if="scope.row.state ==2">已驳回</span>
                <span v-if="scope.row.state ==3">已审核</span>
                <span v-if="scope.row.state ==4">已出库</span>
                <span v-if="scope.row.state ==5">已送达</span>
                <span v-if="scope.row.state ==10">已关单</span>
            </template>
        </el-table-column>

        <el-table-column v-if="businessInfo.info.billType==4" label="状态">
            <template v-slot="scope">
                <span v-if="scope.row.state ==1">新单据</span>
                <span v-if="scope.row.state ==2">已驳回</span>
                <span v-if="scope.row.state ==3">已审核</span>
                <span v-if="scope.row.state ==10">已关单</span>
            </template>
        </el-table-column>

        <el-table-column v-if="businessInfo.info.billType==14" label="状态">
            <template v-slot="scope">
                <span v-if="scope.row.state ==1">新建单</span>
                <span v-if="scope.row.state ==2">已驳回</span>
                <span v-if="scope.row.state ==3">已审核</span>
                <span v-if="scope.row.state ==4">已入库</span>
                <span v-if="scope.row.state ==10">已关单</span>
            </template>
        </el-table-column>

        <el-table-column v-if="businessInfo.info.billType==15" label="状态">
            <template v-slot="scope">
                <span v-if="scope.row.state ==1">新建单</span>
                <span v-if="scope.row.state ==2">已驳回</span>
                <span v-if="scope.row.state ==3">已审核</span>
                <span v-if="scope.row.state ==4">已入库</span>
                <span v-if="scope.row.state ==10">已关单</span>
            </template>
        </el-table-column>

        <el-table-column label="创建时间">
            <template v-slot="scope">
                <span>{{DateTimeFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="创建者">
            <template v-slot="scope">
                <span>{{scope.row.createName}}</span>
            </template>
        </el-table-column>

    </el-table>


    
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['businessInfo'])
    },

}
</script>

<style>

</style>