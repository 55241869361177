<template>
  <div>
    
    <el-form :inline="true"
    v-model="moveInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="moveInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="移仓单号">
                    <el-input v-model="moveInfo.info.moveNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="moveInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="出库仓库">
                <el-select v-model="moveInfo.info.outWarehouseNumber" class="search-200">
                  <el-option v-for="whInfo in whList" :key="whInfo.warehouseNumber" :label="whInfo.warehouseName" :value="whInfo.warehouseNumber"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="入库仓库">
                <el-select v-model="moveInfo.info.inWarehouseNumber" class="search-200">
                  <el-option v-for="whInfo in whList" :key="whInfo.warehouseNumber" :label="whInfo.warehouseName" :value="whInfo.warehouseNumber"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="备注说明">
                <el-input v-model="moveInfo.info.remark" :max="50" class="search-200"></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="moveInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="moveInfo.info.state ==2" type="warning">待审核</el-tag>
                  <el-tag v-if="moveInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="moveInfo.info.state ==4" >已审核</el-tag>
                  <el-tag v-if="moveInfo.info.state ==5" type="warning">已出库</el-tag>
                  <el-tag v-if="moveInfo.info.state ==6" >已入库</el-tag>
                  <el-tag v-if="moveInfo.info.state ==10" type="success">已完成</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户授权的部门列表
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList],
    data(){
      return{
        whList:[],   //全部仓库列表 
      }
    },
    computed:{
        ...mapState('Erp',['moveInfo'])
    },
    methods:{

      //加载仓库列表
      LoadWhList(){
          this.$api.wms.SelectCacheWarehouseList().then(res=>{
              if(res.data.status === 200){
                  this.whList = res.data.data;
              }
          })
      },
    },
    mounted(){
      this.LoadWhList();
    },
}
</script>

<style>

</style>