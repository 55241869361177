<template>
  <div>
    <div class="skuBtnList" v-if="billOutInfo.info && billOutInfo.info.state == 1 && billOutInfo.info.originType == 21">
          <el-button v-if="CheckUserButtonAuth('BtnBillOutSelectGoods')" class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
      </div>

      <!-- 列表 -->
      <el-table :data="billOutInfo.detailList" v-if="billOutInfo.info" border stripe show-summary style="width: 100%">
        
          <el-table-column prop="skuName" label="出库仓库" min-width="100">
              <template v-slot="scope">
                <span>{{scope.row.warehouseName}}</span>
              </template>
          </el-table-column>
          <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
          <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>

          <el-table-column prop="skuName" label="产品名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="规格" min-width="100">
              <template v-slot="scope">
                  <span>{{scope.row.skuName}}</span>
              </template>
          </el-table-column>

          <el-table-column label="单位" min-width="90">
                <template v-slot="scope">
                    <el-select v-if="scope.row.isEdit==1 && billOutInfo.info.state == 1 && (billOutInfo.info.originType == 21 || billOutInfo.info.originType == 6)" v-model="scope.row.unitId"  @change="ChangeSkuUnit">
                        <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                    </el-select>
                    <span v-else>{{scope.row.unitName}}</span>
                </template>
          </el-table-column>

            <!-- 只有单据类型=出库单 才能看到单价和总价 列 -->
          <el-table-column prop="unitPrice" label="单价(元)" v-if="billOutInfo.info.originType == 21" min-width="110">
              <template v-slot="scope">
                <!-- originType=21 表示只有单据类型=出库单 时才可以编辑，其他类型不能编辑 -->
                  <el-input-number v-if="scope.row.isEdit==1 && billOutInfo.info.originType == 21 && billOutInfo.info.state == 1" v-model="scope.row.unitPrice" :precision="2" @change="OnChangeRealNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{scope.row.unitPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="planNum" label="计划数量" min-width="110">
              <template v-slot="scope">
                <!-- originType=21 表示只有单据类型=出库单 时才可以编辑，其他类型不能编辑 -->
                  <el-input-number v-if="scope.row.isEdit==1 && billOutInfo.info.originType == 21 && billOutInfo.info.state == 1" v-model="scope.row.planNum" :min="0" :precision="2" @change="OnChangPlanNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{this.myRound(scope.row.planNum,2)}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="realNum" label="实际数量" min-width="110">
              <template v-slot="scope">
                  <el-input-number v-if="scope.row.isEdit==1 && billOutInfo.info.state == 1" v-model="scope.row.realNum" :min="0" :precision="2" @change="OnChangeRealNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{scope.row.realNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="totalPrice" label="总价(元)" min-width="80" v-if="billOutInfo.info.originType == 21">
              <template v-slot="scope">
                  <span>{{scope.row.totalPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column label="参考价 / 库存" v-if="billOutInfo.info.originType == 21" min-width="110">
            <template v-slot="scope">
                <span>{{scope.row.ckPrice}} / {{scope.row.maxNum}}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope" v-if="billOutInfo.info && billOutInfo.info.state <=2">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <!-- originType=21 表示只有单据类型=出库单 时才可以删除，其他单据类型不能删除 -->
                <el-popconfirm
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.outNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
          </el-table-column>
      </el-table>

      <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
            <SelectStockSkuList :dpId="billOutInfo.info.departmentId" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    components:{
        SelectStockSkuList
    },
    data(){
        return{
            //选择产品-弹出框
            BoxSkuInfo:{
              isVisible:false,
              title:'选择库存产品'
            },
        }
    },
    computed:{
        ...mapState('Erp',['billOutInfo'])
    },
    methods:{
        //选择产品
        BtnSelectSku(){
            this.BoxSkuInfo.title = "选择库存产品"
            this.BoxSkuInfo.isVisible = true
        },
        //保存选中产品(出库单专用)
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let newRow = {
                        id:0,
                        systemNumber:item.systemNumber,
                        inNumber:this.billOutInfo.info.outNumber,
                        warehouseNumber:item.warehouseNumber,
                        warehouseName:item.warehouseName,
                        cid:item.cid,         //分类编号
                        cidName:item.cidName,    //分类名称
                        brandId:item.brandId,    //品牌编号
                        brandName:item.brandName,   //品牌名称
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        unitId:item.defUnitId,   //单位编号
                        unitName:item.defUnitName,
                        unitPrice:0,
                        planNum:1,
                        realNum:1,
                        maxNum:item.realStockNum-item.lockStockNum,  //最大出库数量=库存数-锁定库存数 (暂时没有用到，因为编辑的时候就没有最大库存数了，容易出现问题)
                        totalPrice:0,
                        isDel:0,
                        isEdit:1,
                        skuUnitList:[],   //单位列表
                        ckPrice:0,   //参考单价
                    }
                    //#region 加载单位列表
                    let param = {
                        skuNumber:item.skuNumber
                    }
                    this.$api.goods.GetSkuUnitList(param).then(res=>{
                        if(res.data.status === 200){
                            newRow.skuUnitList = res.data.data;
                        }
                    })
                    //#endregion
                    
                    //#region 单位发生变化时-重新计算价格、库存
                    let param2 = {
                        warehouseNumber:item.warehouseNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.defUnitId
                    }
                    this.$api.wms.GetSkuUnitStockInfo(param2).then(res=>{
                        if(res.data.status === 200){
                            newRow.ckPrice = res.data.data.unitPrice;  //参考成本单价
                            newRow.maxNum = res.data.data.unitStock;  //库存
                        }
                    })
                    //#endregion
                    setTimeout(()=>{
                        this.billOutInfo.detailList.push(newRow);
                    },500);
                    
                });
            }            
            this.BoxSkuInfo.isVisible = false;
        },
        //编辑行
        EditRow(id){
            this.billOutInfo.detailList = this.billOutInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                    //下边两个影藏值用于单位变化后重新计算数量用
                    item.hidUnitId = item.unitId;  //隐藏值：初始单位编号
                    item.hidPlanNum = item.planNum;  //影藏值：初始计划数量
                    //#region 加载单位列表
                    let param = {
                        skuNumber:item.skuNumber
                    }
                    this.$api.goods.GetSkuUnitList(param).then(res=>{
                        if(res.data.status === 200){
                            item.skuUnitList = res.data.data;
                        }
                    }) 
                    //#endregion
                    //#region 单位发生变化时-重新计算价格、库存
                    let param2 = {
                        warehouseNumber:item.warehouseNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.unitId
                    }
                    this.$api.wms.GetSkuUnitStockInfo(param2).then(res=>{
                        if(res.data.status === 200){
                            item.ckPrice = res.data.data.unitPrice;  //参考成本单价
                            item.maxNum = res.data.data.unitStock;  //库存
                        }
                    })
                    //#endregion
                }
                return item
            })
        },
        //删除明细
        DeleteRow(outNumber, id, index){
            this.billOutInfo.detailList.splice(index,1)
            if(id !=0){
                let param = {
                    outNumber:outNumber,
                    id:id
                }
                this.$api.wms.DeleteBillOutDetail(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        },

        //计划数量改变时
        OnChangPlanNum(){
            this.billOutInfo.detailList = this.billOutInfo.detailList.map(item=>{
                item.realNum = item.planNum;  //实际数量 = 计划数量
                item.totalPrice = this.myRound(item.unitPrice * item.realNum,2);
                return item
            })
        },
        //实际数量改变时
        OnChangeRealNum(){
            this.billOutInfo.detailList = this.billOutInfo.detailList.map(item=>{
                item.totalPrice = this.myRound(item.unitPrice * item.realNum,2);
                return item
            })
        },

        //当单位发生变化时 21:出库单->计算库存  6:加工单->换算计划数量
        ChangeSkuUnit(){
            this.billOutInfo.detailList = this.billOutInfo.detailList.map(item=>{
                if(this.billOutInfo.info.originType == 21){
                    //出库单
                    //#region 单位发生变化时-重新计算价格、库存
                    if(item.isEdit ==1){
                        let param3 = {
                            warehouseNumber:item.warehouseNumber,
                            skuNumber:item.skuNumber,
                            unitId:item.unitId
                        }
                        this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                            if(res.data.status === 200){
                                item.ckPrice = res.data.data.unitPrice;  //成本单价
                                item.unitPrice = res.data.data.unitPrice;  //单价
                                item.maxNum = res.data.data.unitStock;  //库存
                                if(item.planNum > item.maxNum){
                                    item.planNum = item.maxNum;  //如果超出库存，就修改数量
                                }
                                if(item.realNum > item.maxNum){
                                    item.realNum = item.maxNum;  //如果超出库存，就修改数量
                                }
                            }
                        })
                    }                    
                    //#endregion
                }else{
                    //加工单
                    //#region 重新计算指定单位的数量
                    if(item.isEdit ==1){
                        let param4 = {
                            skuNumber:item.skuNumber,
                            unitId:item.unitId,   //单位编号
                            minUnitNum:item.planMinUnitNum,  //计划最小单位数量
                        }
                        this.$api.goods.GetUnitNumByUnitId(param4).then(res=>{
                            console.log(res)
                            if(res.data.status === 200){
                                item.planNum = res.data.data;
                                //item.realNum = res.data.data;
                            }else{
                                item.planNum = 0;
                                //item.realNum = 0;
                            }
                        })
                    }                    
                    //#endregion
                }
                
                return item
            })
        },

    }
}
</script>

<style>

</style>