/**
 * ERP模块相关
 * 
 */

//供应商
import ListSupplier from '@/views/Pms/Supplier/ListSupplier'
//计划单
import ListPlan from '@/views/Pms/Plan/ListPlan.vue'
//询价单
import ListInquiry from '@/views/Pms/Inquiry/ListInquiry.vue'
//采购单
import ListPurchase from '@/views/Pms/Purchase/ListPurchase.vue'
//退供单
import ListReturnPurchase from '@/views/Pms/ReturnPurchase/ListReturnPurchase.vue'
//单价历史记录
import ListHistoryPrice from '@/views/Pms/HistoryPrice/ListHistoryPrice.vue'


//车辆管理
import ListCar from '@/views/Tms/Car/ListCar.vue'
//车辆轨迹
import ListLine from '@/views/Tms/Line/ListLine.vue'
//物流单
import ListTransport from '@/views/Tms/Transport/ListTransport.vue'


//客户资料
import ListCustomer from '@/views/Sales/Customer/ListCustomer.vue'
//销售单
import ListSale from '@/views/Sales/Sale/ListSale.vue'
//退货单
import ListReturnSale from '@/views/Sales/ReturnSale/ListReturnSale.vue'
//销售历史价格记录
import ListSalePrice from '@/views/Sales/HistoryPrice/ListSalePrice.vue'


//零售组管理
import ListRetailGroup from '@/views/Rms/RetailGroup/ListRetailGroup.vue'
//零售单
import ListRetail from '@/views/Rms/Retail/ListRetail.vue'
//退零单
import ListReturnRetail from '@/views/Rms/ReturnRetail/ListReturnRetail.vue'
//班次记录
import ListShiftRecord from '@/views/Rms/ShiftRecord/ListShiftRecord.vue'
//调账记录
import ListAdjustRecord from '@/views/Rms/AdjustRecord/ListAdjustRecord.vue'
//换钱记录
import ListSwapRecord from '@/views/Rms/SwapRecord/ListSwapRecord.vue'
//分享零售单页
import ShareRetail from '@/views/Rms/Retail/ShareRetail.vue'


//仓库信息
import ListWarehouse from '@/views/Wms/Warehouse/ListWarehouse'
//库存信息
import ListStock from '@/views/Wms/Stock/ListStock'
//盘点单
import ListCheck from '@/views/Wms/Check/ListCheck.vue'
//报损单
import ListLoss from '@/views/Wms/Loss/ListLoss.vue'
//入库单
import ListBillIn from '@/views/Wms/BillIn/ListBillIn.vue'
//出库单
import ListBillOut from '@/views/Wms/BillOut/ListBillOut.vue'
//移仓单
import ListMove from '@/views/Wms/Move/ListMove.vue'


//加工组管理
import ListWorkGroup from '@/views/Work/WorkGroup/ListWorkGroup.vue'
//加工单
import ListWork from '@/views/Work/WorkBill/ListWork.vue'



export default [
    {
        //供应商资料
        name:'ListSupplier',
        path:'listsupplier',
        component:ListSupplier
    },
    {
        //计划单
        name:'ListPlan',
        path:'listplan',
        component:ListPlan
    },
    {
        //询价单
        name:'ListInquiry',
        path:'listinquiry',
        component:ListInquiry
    },
    {
        //采购单
        name:'ListPurchase',
        path:'listpurchase',
        component:ListPurchase
    },
    {
        //退供单
        name:'ListReturnPurchase',
        path:'listreturnpurchase',
        component:ListReturnPurchase
    },
    {
        //采购单价历史记录
        name:'ListHistoryPrice',
        path:'listhistoryprice',
        component:ListHistoryPrice
    },

    {
        //车辆管理
        name:'ListCar',
        path:'listcar',
        component:ListCar
    },
    {
        //车辆轨迹
        name:'ListLine',
        path:'listline',
        component:ListLine
    },
    {
        //物流单
        name:'ListTransport',
        path:'listtransport',
        component:ListTransport
    },
    
    {
        //客户管理
        name:'ListCustomer',
        path:'listcustomer',
        component:ListCustomer
    },
    {
        //销售单
        name:'ListSale',
        path:'listsale',
        component:ListSale
    },
    {
        //退货单
        name:'ListReturnSale',
        path:'listreturnsale',
        component:ListReturnSale
    },
    {
        //销售单历史价格
        name:'ListSalePrice',
        path:'listsaleprice',
        component:ListSalePrice
    },

    {
        //零售组管理 
        name:'ListRetailGroup',
        path:'listretailgroup',
        component:ListRetailGroup
    },
    {
        //零售单
        name:'ListRetail',
        path:'listretail',
        component:ListRetail
    },
    {
        //退零单
        name:'ListReturnRetail',
        path:'listreturnretail',
        component:ListReturnRetail
    },
    {
        //班次记录
        name:'ListShiftRecord',
        path:'listshiftrecord',
        component:ListShiftRecord
    },
    {
        //调账记录 
        name:'ListAdjustRecord',
        path:'listadjustrecord',
        component:ListAdjustRecord
    },
    {
        //换钱记录 
        name:'ListSwapRecord',
        path:'listswaprecord',
        component:ListSwapRecord
    },
    {
        //分享零售单
        name:'ShareRetail',
        path:'shareretail',
        component:ShareRetail
    },
    
    {
        //仓库管理
        name:'ListWarehouse',
        path:'listwarehouse',
        component:ListWarehouse
    },
    {
        //库存管理
        name:'ListStock',
        path:'liststock',
        component:ListStock
    },
    {
        //盘点单
        name:'ListCheck',
        path:'listcheck',
        component:ListCheck
    },
    {
        //报损单
        name:'ListLoss',
        path:'listloss',
        component:ListLoss
    },
    {
        //入库单
        name:'ListBillIn',
        path:'listbillin',
        component:ListBillIn
    },
    {
        //出库单
        name:'ListBillOut',
        path:'listbillout',
        component:ListBillOut
    },
    {
        //移仓单
        name:'ListMove',
        path:'listmove',
        component:ListMove
    },

    {
        //加工组
        name:'ListWorkGroup',
        path:'listworkgroup',
        component:ListWorkGroup
    },
    {
        //加工单
        name:'ListWork',
        path:'listwork',
        component:ListWork
    },

    

]
