<template>
  <div>
    <el-row>

      <el-col :span="4" class="tree_department">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>组织架构</span>
            </div>
          </template>
          <!-- 目录树 -->
          <el-scrollbar :height="GetTreeDataHeight">
            <el-tree :data="info.treeList" 
            node-key="id"
            :default-expanded-keys="[0]"
            @node-click="handleNodeClick" empty-text="暂无组织架构" />
          </el-scrollbar>
          
        </el-card>
      </el-col>

      
      <el-col :span="20">
        <!-- 搜索功能 -->
        <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
            <el-form :inline="true">
              <!-- <el-form-item label="部门名称">
                <el-input v-model="info.search.departmentName"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain>搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain>重置</el-button>
              </el-form-item> -->

            </el-form>
          </el-col>
        </el-row>

        <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
              <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
              <!-- <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm> -->
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
              
            </el-col>
          </el-row>
          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="departmentId" type="selection" width="50" />
            <el-table-column prop="departmentId" label="编号" width="80" />
            <el-table-column prop="departmentName" label="部门名称" />
            <el-table-column prop="pidName" label="归属管理" />
            <el-table-column prop="sort" label="排序" />
            <el-table-column prop="departmentId" label="操作" width="130">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.departmentId)">详情</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.departmentId)">
                  <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                v-model:currentPage="info.page"
                v-model:page-size="info.size"
                :page-sizes="[50, 200, 500]"
                :background="true"
                layout="total, sizes, prev, pager, next, jumper"
                :total="info.total"
                @current-change="btnPagechange"
                @size-change="btnSizechange"
              >
              </el-pagination>
          </el-container>

        </div>
      </el-col>

    </el-row>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="400"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="btnRefresh">
        <EditDepartment :editId="info.id" @closeBox="btnCloseBox"></EditDepartment>
    </el-dialog>
  </div>  
</template>

<script>
import { ElMessage } from 'element-plus'
import EditDepartment from '@/views/system/Department/EditDepartment.vue'
export default {
  data(){
    return {
      info:{
          treeList:[],  //部门目录树   
          pid:0,  //目录树父节点
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          id:0,  //单个新增、编辑
          ids:[],   //新增、编辑、删除 的参数
          search:{
            departmentName:''
          }
      },
      BoxEditInfo:{
        isVisible:false,
        title:'新增部门'
      },
    }
  },
  components:{
    EditDepartment
  },
  computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-190
        },
        //Tree自适应窗体高度
        GetTreeDataHeight(){
          return window.innerHeight - 250
        },
    },
  methods:{    
    //Tree 数据
    GetTreeList(){
      this.$api.system.GetTreeDepartmentList().then(res=>{
        if(res.data.status === 200){
          this.info.treeList = res.data.data;  //加载目录树数据
        }
      })
    },
    //Table 数据
    GetDataList(){
      let param = {
        pid:this.info.pid,
        page:this.info.page,
        size:this.info.size
      }
      this.$api.system.GetDepartmentList(param).then(res=>{
        if(res.data.status ===200){
          this.info.dataList = res.data.data;  //获取数据
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }
      })
    },
    //获取目录树节点
    handleNodeClick(data){
      this.info.pid = data.id;
      this.GetDataList()
    },
    //选中多选框
    btnCheckbox(selection){
      this.info.ids = selection.map(item=>item.departmentId)
    },
    //分页-页数变化时
    btnPagechange(page){
      this.info.page = page
      this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
      this.info.size = size
      this.info.page = 1
      this.GetDataList() //加载Table数据
    },
    //新增编辑部门信息
    btnEditInfo(id){
      this.info.id = id
      if(id==0){
          this.BoxEditInfo.title = "新增部门"
      }else{
          this.BoxEditInfo.title = "编辑部门"
      }  
      this.BoxEditInfo.isVisible = true;
    },
    //删除部门
    btnDeleteInfo(id){
      this.$api.system.DeleteDepartmentInfo(id).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg)
          this.btnRefresh()
        }
        else{
          ElMessage.error(res.data.msg)
        }
      })
    },
    //批量删除
    btnBatchDeleteInfo(){
      
    },    

    //关闭弹出框
    btnCloseBox(){
        this.BoxEditInfo.isVisible = false;
    },
    //重新加载
    btnRefresh(){
      this.GetTreeList();
      this.GetDataList();
    },

    
  },
  mounted(){
    this.GetTreeList()  //加载目录树
    this.GetDataList() //加载Table数据
  }
}

</script>

<style>
  .tree_department {padding-right: 10px;}
  .operation {margin-bottom: 5px;}
  .operation .operation_hidden {text-align: right;}

  .page_one {float: right;margin-top: 5px;}
</style>