<template>
  <div>

    <el-form :inline="true"
        v-model="differenceInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="differenceInfo.info">
                
                <el-col :span="8">
                    <el-form-item label="所属部门">
                        <el-tree-select v-model="differenceInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="交易者类型">
                        <el-select v-model="differenceInfo.info.tradeType" @change="ChangeTradeType" class="search-200">
                            <el-option :value="0" label="选择类型" />
                            <el-option :value="1" label="供应商" />
                            <el-option :value="2" label="客户" />
                            <el-option :value="3" label="员工" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="交易者">
                        <el-select v-model="differenceInfo.info.tradeNumber" filterable class="search-200">
                            <el-option v-for="trade in info.tradeList" :key="trade.tradeNumber" :label="trade.tradeName" :value="trade.tradeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="原始单类型">
                        <el-select v-model="differenceInfo.info.originType" class="search-200">
                            <el-option label="采购单" :value="1"></el-option>
                            <el-option label="销售单" :value="2"></el-option>
                            <el-option label="退供单" :value="14"></el-option>
                            <el-option label="退货单" :value="15"></el-option>                            
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="差额说明">
                      <el-input v-model="differenceInfo.info.content" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="记账时间">
                        <el-date-picker v-model="differenceInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" />
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="单价">
                      <el-input-number v-model.number="differenceInfo.info.unitPrice" :precision="2" @change="OnChangePrice" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="数量">
                      <el-input-number v-model.number="differenceInfo.info.realNum" :min="0" @change="OnChangePrice" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="备注">
                      <el-input v-model="differenceInfo.info.remark" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="总价">
                      <el-input v-model="differenceInfo.info.totalPrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="状态">
                      <el-tag v-if="differenceInfo.info.state ==1">新单据</el-tag>
                      <el-tag v-if="differenceInfo.info.state ==2" type="danger">已驳回</el-tag>
                      <el-tag v-if="differenceInfo.info.state ==3" type="success">已审核</el-tag>
                      <el-tag v-if="differenceInfo.info.state ==10">已关单</el-tag>
                    </el-form-item>
                </el-col>


            </el-row>
      </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
    mixins:[UserDptList],
    data(){
        return{
            info:{
                tradeList:[]   //交易者列表
            }
        }
    },
    computed:{
        ...mapState('Erp',['differenceInfo'])
    },
    methods:{
        

        //交易者类型
        ChangeTradeType(){
            this.SelectTradeList();  //获取交易者
        },

        //获取交易者列表
        SelectTradeList(){
            let param = {
                tradeType:this.differenceInfo.info.tradeType,
            }
            this.$api.erp.SelectTradeList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.tradeList = res.data.data;
                }else{
                    this.info.tradeList = [];
                }
            })        
        },

        //单价发生改变后
        OnChangePrice(){
            this.differenceInfo.info.totalPrice = this.myRound(this.differenceInfo.info.unitPrice * this.differenceInfo.info.realNum,2);
        }
    },
}
</script>

<style>

</style>