/**
 * 系统模块接口列表
 */


 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const system = {

    //#region 模块

    //列表
    ListSystemModule(param){
        return axios.get(`${base.base}/Module/ListSystemModule`,{
            params:param
        })
    },

    //获取单个信息
    SingleSystemModule(param){
        return axios.get(`${base.base}/Module/SingleSystemModule`,{
            params:param
        })
    },

    //保存
    SaveSystemModule(param){
        return axios.post(`${base.base}/Module/SaveSystemModule`,param)
    },

    //删除信息
    DeleteSystemModule(param){
        return axios.post(`${base.base}/Module/DeleteSystemModule`,param)
    },

    //获取模块列表-下拉列表
    SelectModuleList(param){
        return axios.get(`${base.base}/Module/SelectModuleList`,{
            params:param
        })
    },

    //#endregion

    //#region 系统关联模块

    //通过系统编号获取所属的moduleId列表 - SAAS管理员专用
    GetSystemModuleIdList(param){
        return axios.get(`${base.base}/Module/GetSystemModuleIdList`,{
            params:param
        })
    },

    //批量保存系统关联模块列表 - SAAS管理员专用
    BatchSaveSystemModuleList(param){
        return axios.post(`${base.base}/Module/BatchSaveSystemModuleList`,param)
    },

    //获取当前系统所属的模块列表 - 当前系统用
    GetSystemModuleList(){
        return axios.get(`${base.base}/Module/GetSystemModuleList`)
    },


    //#endregion

    //#region 系统接口

    //获取系统列表
    GetSystemInfoList(param){
        return axios.get(`${base.base}/System/ListSystemInfo`,{
            params:param
        })
    },
    //获取单个系统信息(不包含配置信息)
    GetSystemInfo(param){
        return axios.get(`${base.base}/System/SingleSystemInfo`,{
            params:param
        })
    },
    //获取系统信息(包含系统信息和系统配置)
    GetSystemAllInfo(param){
        return axios.get(`${base.base}/System/SingleSystemAllInfo`,{
            params:param
        })
    },
    //获取当前系统信息
    SingleCurrentSystemInfo(){
        return axios.get(`${base.base}/System/SingleCurrentSystemInfo`)
    },
    //保存当前系统信息 (只修改、不新增)
    SaveSystemInfo(param){
        return axios.post(`${base.base}/System/SaveSystemInfo`,param)
    },
    
    //保存系统信息(包含系统和配置信息)(SAAS超管用)
    SaveSystemAllInfo(param){
        return axios.post(`${base.base}/System/SaveSystemAllInfo`,param)
    },

    //获取用户关联的系统列表-下拉列表
    SelectSystemList(param){
        return axios.get(`${base.base}/System/SelectSystemList`,{
            params:param
        })
    },

    
    //删除★
    DeleteDeepSystemInfo(param){
        return axios({
            url:`${base.base}/System/DeleteDeepSystemInfo`,
            method:'post',
            params:param
        })
    },
    
    //导出系统列表
    ExportSystem(param){
        return axios.get(`${base.base}/System/ExportSystem`,{
            params:param
        })
    },

    //获取当前系统所属的所有菜单列表
    SelectSystemMenuList(){
        return axios.get(`${base.base}/System/SelectSystemMenuList`)
    },

    //获取系统选中的菜单
    GetSystemSelectedMenu(param){
        return axios.get(`${base.base}/System/ListSystemSelectedMenu`,{
            params:param
        })
    },
    //保存系统所属菜单
    SaveTreeSystemMenu(param){
        return axios.post(`${base.base}/System/SaveSystemMenu`,param)
    },

    //#endregion

    //#region 菜单接口
    
    //获取菜单列表
    PageSystemMenuList(param){
        return axios.post(`${base.base}/Menu/PageSystemMenuList`,param)
    },
    //获取单个菜单信息
    GetSystemMenuInfo(param){
        return axios.get(`${base.base}/Menu/SingleSystemMenu`,{
            params:param
        })
    },
    //保存菜单信息
    SaveSystemMenu(params){
        return axios.post(`${base.base}/Menu/SaveSystemMenu`, params)
    },
    //删除菜单信息
    DeleteSystemMenu(menuId){
        return axios.post(`${base.base}/Menu/DeleteSystemMenu?menuId=${menuId}`)
    },
    //获取菜单树结构(不包含按钮)
    SelectTreeMenuList(){
        return axios.get(`${base.base}/Menu/SelectAllMenuList`)
    },
    //获取菜单树结构(包含按钮)
    SelectAllMenuButtonList(){
        return axios.get(`${base.base}/Menu/SelectAllMenuButtonList`)
    },

    //获取指定模块下的菜单树结构
    GetTreeMenuListByModuleId(param){
        return axios.get(`${base.base}/Menu/GetTreeMenuListByModuleId`,{
            params:param
        })
    },

    //#endregion

    //#region 部门接口
    
    //获取部门目录树
    GetTreeDepartmentList(){
        return axios.get(`${base.base}/Department/ListTreeDepartment`)
    },
    //获取部门列表
    GetDepartmentList(param){
        return axios.get(`${base.base}/Department/ListSystemDepartment`,{
            params:param
        })
    },
    //获取单个部门信息
    GetDepartmentInfo(param){
        return axios.get(`${base.base}/Department/SingleSystemDepartment`,{
            params:param
        })
    },
    //保存部门
    SaveDepartmentInfo(param){
        return axios.post(`${base.base}/Department/SaveSystemDepartment`,param)
    },
    //删除部门
    DeleteDepartmentInfo(id){
        return axios.post(`${base.base}/Department/DeleteSystemDepartment?departmentId=${id}`)
    },

    //#endregion

    //#region 用户接口

    //获取用户列表
    GetUserList(param){
        return axios.post(`${base.base}/User/ListUserInfo`,param)
    },
    //保存员工
    SaveUserInfo(param){
        return axios.post(`${base.base}/User/SaveUserInfo`,param)
    },

    //获取单个用户信息
    GetUserInfo(param){
        return axios.get(`${base.base}/User/SingleUserInfo`,{
            params:param
        })
    },
    //获取当前用户信息
    GetCurrentUserInfo(){
        return axios.get(`${base.base}/User/SingleCurrentUserInfo`)
    },
    //删除单个用户信息
    DeleteUserInfo(userNumber){
        return axios.post(`${base.base}/User/DeleteUsers?userNumber=${userNumber}`)
    },

    //修改密码
    UpdateUserPwd(param){
        return axios.post(`${base.base}/User/UpdateUserPwd`,param)
    },

    //重置密码  ##################
    ResetUserPwd(param){
        return axios.post(`${base.base}/User/ResetUserPwd`,param)
    },

    //获取员工列表 - 下拉列表使用
    SelectUserList(){
        return axios.get(`${base.base}/User/SelectUserList`)
    },

    
    //获取当前用户获得授权的部门列表(目录树) 
    SelectUserDptList(){
        return axios.get(`${base.base}/User/SelectUserDptList`)
    },

    //获取当前用户授权的仓库列表
    SelectUserWhList(){
        return axios.get(`${base.base}/User/SelectUserWhList`)
    },

    
    //########获取用户权限内所有菜单#############
    ListUserAllMenu(){
        return axios.get(`${base.base}/User/ListUserAllMenu`)
    },

    //########获取用户权限内所有菜单-通过ModuleId获取#########
    ListUserAllMenuByModuleId(param){
        return axios.get(`${base.base}/User/ListUserAllMenuByModuleId`,{
            params:param
        })
    },

    //#########获取用户权限内所有按钮###########
    ListUserAllButton(){
        return axios.get(`${base.base}/User/ListUserAllButton`)
    },


    //按照班制类型获取员工列表
    GetUserListByClockInType(param){
        return axios.get(`${base.base}/User/GetUserListByClockInType`,{
            params:param
        })
    },

    //验证当前用户最后一次修改密码距今天数
    GetLastChangePwdDate(){
        return axios.get(`${base.base}/User/GetLastChangePwdDate`)
    },


    //#endregion

    //#region 角色接口

    //获取角色列表
    PageSystemRoleList(param){
        return axios.post(`${base.base}/Role/PageSystemRoleList`,param)
    },
    //获取单个角色所有信息
    GetSystemRoleAllInfo(param){
        return axios.get(`${base.base}/Role/GetSystemRoleAllInfo`,{
            params:param
        })
    },
    //保存角色信息
    SaveRoleAllInfo(param){
        return axios.post(`${base.base}/Role/SaveRoleAllInfo`,param)
    },
    //删除角色信息
    DeleteRoleInfo(param){
        return axios.post(`${base.base}/Role/DeleteRole`,param)
    },
    //获取当前系统全部角色列表
    SelectRoleList(){
        return axios.get(`${base.base}/Role/SelectRoleList`)
    },

    //角色菜单

    

    //#region 日志接口

    //登录日志列表
    ListLogLogin(param){
        return axios.get(`${base.base}/Log/ListLogLogin`,{
            params:param
        })
    },

    //接口日志列表
    LogInterfaceList(param){
        return axios.post(`${base.base}/Log/LogInterfaceList`,param)
    },
    //导出接口日志列表
    ExportInterfaceList(param){
        return axios.post(`${base.base}/Log/ExportInterfaceList`,param)
    },

    //登录失败日志列表
    ListLoginError(param){
        return axios.post(`${base.base}/Log/ListLoginError`,param)
    },
    //删除失败日志记录
    DeleteLoginError(param){
        return axios.post(`${base.base}/Log/DeleteLoginError`,param)
    },


    //获取所有系统流量列表
    GetAllSystemWebUvPv(param){
        return axios.get(`${base.base}/Log/GetAllSystemWebUvPv`,{
            params:param
        })
    },

    //保存系统流量信息
    SaveLogFlow(){
        return axios.post(`${base.base}/Log/SaveLogFlow`)
    },


    //#endregion
    
    //#region 打印机

    //列表
    ListPrinter(param){
        return axios.post(`${base.base}/Printer/ListPrinter`,param)
    },

    //获取单个信息
    SinglePrinter(param){
        return axios.get(`${base.base}/Printer/SinglePrinter`,{
            params:param
        })
    },

    //保存
    SavePrinter(param){
        return axios.post(`${base.base}/Printer/SavePrinter`,param)
    },

    //删除
    DeletePrinter(param){
        return axios.post(`${base.base}/Printer/DeletePrinter`,param)
    },

    //测试打印
    TestPrinter(param){
        return axios.post(`${base.base}/Printer/TestPrinter`,param)
    },

    //获取打印机状态
    GetPrinterStatus(param){
        return axios.get(`${base.base}/Printer/GetPrinterStatus`,{
            params:param
        })
    },

    //获取用户授权下的打印机列表 - 下拉列表
    SelectPrinterList(){
        return axios.get(`${base.base}/Printer/SelectPrinterList`)
    },


    //#endregion


    //#region 移动菜单管理

    //列表
    PageMobileMenuList(param){
        return axios.post(`${base.base}/MobileMenu/PageMobileMenuList`,param)
    },

    //单个
    GetMobileMenuByMenuId(param){
        return axios.get(`${base.base}/MobileMenu/GetMobileMenuByMenuId`,{
            params:param
        })
    },

    //保存
    SaveMobileMenu(param){
        return axios.post(`${base.base}/MobileMenu/SaveMobileMenu`,param)
    },

    //列表-下拉列表
    SelectAllMobileMenuList(param){
        return axios.get(`${base.base}/MobileMenu/SelectAllMenuList`,{
            params:param
        })
    },

    //删除
    DeleteMobileMenu(param){
        return axios({
            url:`${base.base}/MobileMenu/DeleteMobileMenu`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 系统关联菜单

    //获取单个系统所有菜单列表
    SelectSystemAllMenuList(param){
        return axios.get(`${base.base}/MobileMenu/SelectSystemAllMenuList`,{
            params:param
        })
    },

    //#endregion
 }

 export default system