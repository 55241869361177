/**
 * 通用模块
 */

 import ListDict from '@/views/Common/ListDict'
 import ListArea from '@/views/Common/ListArea'

import ListGroup from '@/views/Common/Group/ListGroup.vue'
//系统设置
import ListSetSys from '@/views/SetUp/SetSys/ListSetSys.vue'
//部门设置
import ListSetDpt from '@/views/SetUp/SetDpt/ListSetDpt.vue'
//个人设置
import ListSetUser from '@/views/SetUp/SetUser/ListSetUser.vue'
//移动端版本
import ListSetMobileVersion from '@/views/SetUp/SetVersion/ListSetMobileVersion.vue'


export default [
    {
        //数据字典
        name:'ListDict',
        path:'listdict',
        component:ListDict
    },
    {
        //省市区
        name:'ListArea',
        path:'listarea',
        component:ListArea
    },
    {
        //组管理
        name:'ListGroup',
        path:'listgroup',
        component:ListGroup
    },
    {
        //系统设置
        name:'ListSetSys',
        path:'listsetsys',
        component:ListSetSys,
    },
    {
        //部门设置
        name:'ListSetDpt',
        path:'listsetdpt',
        component:ListSetDpt
    },
    {
        //个人设置
        name:'ListSetUser',
        path:'listsetuser',
        component:ListSetUser
    },
    
    {
        //移动端版本
        name:'ListSetMobileVersion',
        path:'listsetmobileversion',
        component:ListSetMobileVersion
    }
    
]