<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info.skuInfo"
    label-width="80px"
    label-position="right">
        <el-row>

            <el-col :span="12">
                <el-form-item label="产品">
                    <el-input v-model="info.skuInfo.spuName" :disabled="true"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="规格">
                    <el-input v-model="info.skuInfo.skuName" :disabled="true"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 按钮 -->
    <div class="skuBtnList">
        <el-button class="iconfont icon-navigation btnTk" plain type="primary" @click="BoxImgInfo.isVisible=true">图库</el-button>      
    </div>

    <!-- 图片列表 -->
    <el-table :data="info.dataList" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="60"></el-table-column>
        <el-table-column label="图片">
            <template v-slot="scope">
                <el-image
                style="width: 70px; height: 70px"
                :src="scope.row.skuImg"
                :preview-src-list="[scope.row.skuImg]"
                :hide-on-click-modal="true"
                fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="isDef" label="默认项" width="100">
            <template v-slot="scope">
                <el-select v-model="scope.row.isDef">
                    <el-option label="否" :value="0"></el-option>
                    <el-option label="是" :value="1"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
            <template v-slot="scope">
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.skuNumber,scope.row.id,scope.$index)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData()">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

    <!-- 图库 弹出框 -->
    <el-dialog :title="BoxImgInfo.title" 
    v-model="BoxImgInfo.isVisible"
    width="65%"
    :destroy-on-close="true">
        <SelectGoodsImg ref="SelectImgStock"></SelectGoodsImg>
        <template #footer>
            <el-button type="primary" @click="btnSelectImgStock">选择</el-button>
            <el-button @click="BoxImgInfo.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectGoodsImg from '@/components/common/SelectGoodsImg.vue'

import { ElMessage } from 'element-plus';
export default {
    props:['editId'],
    components:{
        SelectGoodsImg
    },
    data(){
        return{
            info:{
                skuInfo:[],   //规格信息
                dataList:[],   //图片列表
            },
            //图库-弹出框
            BoxImgInfo:{
                isVisible:false,
                title:'产品图库'
            },
        }
    },
    methods:{

        

        //删除文件
        DeleteRow(skuNumber,id,index){
            this.info.dataList.splice(index,1)
            if(id !==0){
                let param = {
                    skuNumber:skuNumber,
                    id:id
                }
                this.$api.goods.DeleteGoodsSkuImage(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },

        //保存数据
        btnSaveData(){
            this.$api.goods.SaveGoodsSkuImageList(this.info.dataList).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    this.btnClose();
                }
            })
        },
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },


        //选择图库
        btnSelectImgStock(){
            let itemList = this.$refs.SelectImgStock.info.dataList
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    if(item.selFlag ==true)  //只要选中的图片
                    {
                        let newInfo = {
                            id:0,
                            systemNumber:'',
                            skuNumber:this.editId,
                            skuImg:item.goodImg,
                            isDef:0
                        }
                        this.info.dataList.push(newInfo);
                    }                    
                });                
            }
            this.BoxImgInfo.isVisible = false;  //关闭图库
        }

    },
    mounted(){
    }
}
</script>

<style scoped>
.btnTk{float: left;margin: 5px;}
</style>