<template>
  <div>
    <el-divider></el-divider>
    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="demo-tabs">

        <el-tab-pane label="供应商资料" name="tabSupplier">
            <TabSupplier></TabSupplier>
        </el-tab-pane>

        <el-tab-pane label="供应商资质" name="tabCert">
            <TabSupplierCert></TabSupplierCert>
        </el-tab-pane>

        <el-tab-pane label="发票信息" name="tabInvoice">
            <TabSupplierInvoice></TabSupplierInvoice>
        </el-tab-pane>

        <!-- <el-tab-pane label="关联产品" name="TabSku">
            <TabSupplierSku></TabSupplierSku>
        </el-tab-pane> -->

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup" v-if="supplierInfo.info">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import TabSupplier from '@/views/Pms/Supplier/TabSupplier.vue'
import TabSupplierCert from '@/views/Pms/Supplier/TabSupplierCert.vue'
import TabSupplierInvoice from '@/views/Pms/Supplier/TabSupplierInvoice.vue'
// import TabSupplierSku from '@/views/Pms/Supplier/TabSupplierSku.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    name:'EditSupplier',
    props:['editId','dataList'],
    components:{
        TabSupplier,
        TabSupplierCert,
        TabSupplierInvoice,
        // TabSupplierSku
    },
    data(){
        return{
            info:{
                activeName:'tabSupplier',
                supplierNumber:this.editId,
            }
        }
    },
    computed:{
        ...mapState('Purchase',['supplierInfo'])
    },
    methods:{
        ...mapMutations('Purchase',['SetSupplierInfo']),

        //加载要编辑的数据
        GetDataInfo(){
            if(this.info.supplierNumber !=0){
                let param = {
                    supplierNumber:this.info.supplierNumber
                }
                this.$api.supplier.SingleSupplierAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetSupplierInfo(res.data.data)
                    }else{
                        console.log(res.data.msg)
                    }
                })
            }
            else{
                this.btnClearData();  //
            }
        },

        //保存-type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.supplier.SaveSupplier(this.supplierInfo).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //类型
                if(type==1){
                    this.btnClose();
                }else{
                    this.btnClearData();
                }
                
            }else{
                ElMessage.error(res.data.msg)
            }
            }).catch(e=>{
                console.log(e)
            })
        },

        //清空实体
        btnClearData(){
            let newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    supplierNumber:this.supplierNumber,
                    supplierName:'',
                    supplierAlias:'',
                    linkMan:'',
                    linkPhone:'',
                    selectArea:[],
                    address:'',
                    departmentId:0,
                    mainBusiness:'',
                    certNumber:'',
                    score:0,
                    isShow:1,
                    isDel:0
                },
                invoiceList:[],  //发票
                certList:[],   //资质
                skuList:[],   //产品
            }
            this.SetSupplierInfo(newInfo)
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.supplierNumber === this.info.supplierNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.supplierNumber = prevInfo.supplierNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.supplierNumber === this.info.supplierNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.supplierNumber = prevInfo.supplierNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo()   //加载单个供应商所有信息
    }
}
</script>

<style>

</style>