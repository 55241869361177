<template>
  <div>
    <!-- 编辑差额单 -->
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName">
        <el-tab-pane label="差额单" name="tabInfo">
            <TabDifference ref="tabInfo"></TabDifference>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabDifferenceRecord></TabDifferenceRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabDifferenceState></TabDifferenceState>
        </el-tab-pane>

    </el-tabs>
    
    <!-- 功能按钮组 -->
    <div class="btnGroup" v-if="differenceInfo.info">
        <el-button type="primary" v-if="differenceInfo.info.state<=2" @click="btnSaveData">保存</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnDifferenceAudit') && differenceInfo.info.id !=0 && differenceInfo.info.state <= 2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="差额单审核"
    v-model="BoxCheckState.isVisible"
    width="20%">
        <el-divider></el-divider>
        <div>差额单是否审核通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(3)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(2)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TabDifference from '@/views/Bill/Difference/TabDifference.vue'
import TabDifferenceRecord from '@/views/Bill/Difference/TabDifferenceRecord.vue'
import TabDifferenceState from '@/views/Bill/Difference/TabDifferenceState.vue'

import moment from 'moment'
import { mapMutations, mapState } from 'vuex'
import { ElLoading, ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    components:{
        TabDifference,
        TabDifferenceRecord,
        TabDifferenceState
    },
    data(){
        return{
            info:{
                activeName:'tabInfo',
                billNumber:this.editId,
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['differenceInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetDifferenceInfo']),

        //加载数据信息
        GetDataInfo(){
            if(this.info.billNumber !=0){
                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.erp.SingleDifferenceAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetDifferenceInfo(res.data.data);
                        
                    }else{
                        this.btnClearData();
                    }
                    //加载子组件数据
                    this.$refs.tabInfo.SelectTradeList();
                })
            }
            else{
                this.btnClearData();
                //加载子组件数据
                this.$refs.tabInfo.SelectTradeList();
            }            
        },

        //保存-差额单信息
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })
            //保存数据
            this.differenceInfo.info.billTime = moment(this.differenceInfo.info.billTime).format("YYYY-MM-DD HH:mm:ss");
            this.$api.erp.SaveDifferenceAllInfo(this.differenceInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.btnClose()
                }else{
                    ElMessage.error(res.data.msg)
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //提交审核状态
        btnUpdateState(state){
            let param = {
                billNumber:this.info.billNumber,
                state:state
            }
            this.$api.erp.UpdateDifferenceState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.differenceInfo.info.state = state;
                    this.BoxCheckState.isVisible = false
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    billNumber:'',
                    tradeType:0,
                    tradeNumber:'',
                    tradeName:'',
                    departmentId:this.userDepartmentId,
                    departmentName:'',
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    originNumber:'',
                    originType:1,
                    spuName:'采购单',
                    content:'',
                    unitPrice:0,
                    realNum:1,
                    totalPrice:0,
                    state:1,
                    billFlag:0,
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    creator:'',
                    remark:'',
                    isDel:0
                },
                recordList:[],
                stateList:[]
            }
            this.SetDifferenceInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>