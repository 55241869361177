/**
 * 财务模块接口
 * 
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const finance = {

    //#region 财务类型

    //列表
    ListFinanceType(param){
        return axios.get(`${base.base}/FinanceType/ListFinanceType`,{
            params:param
        })
    },

    //单个信息
    SingleFinanceType(param){
        return axios.get(`${base.base}/FinanceType/SingleFinanceType`,{
            params:param
        })
    },

    //保存
    SaveFinanceType(param){
        return axios.post(`${base.base}/FinanceType/SaveFinanceType`,param)
    },

    //删除
    DeleteFinanceType(param){
        return axios.post(`${base.base}/FinanceType/DeleteFinanceType`,param)
    },

    //下拉列表
    SelectFinanceTypeList(){
        return axios.get(`${base.base}/FinanceType/SelectFinanceTypeList`)
    },

    //#endregion

    //#region 会计科目

    //获取会计科目列表-目录树
    SelectTreeSubject(){
        return axios.get(`${base.base}/Finance/SelectTreeSubject`)
    },

    //获取会计科目列表
    ListSubject(param){
        return axios.get(`${base.base}/Finance/ListSubject`,{
            params:param
        })
    },

    //获取单个会计科目
    SingleSubject(param){
        return axios.get(`${base.base}/Finance/SingleSubject`,{
            params:param
        })
    },

    //保存会计科目
    SaveSubject(param){
        return axios.post(`${base.base}/Finance/SaveSubject`,param)
    },

    //删除会计科目
    DeleteSubject(param){
        return axios.post(`${base.base}/Finance/DeleteSubject`,param)
    },

    //更新会计科目-显示标识
    UpdateSubjectByIsShow(param){
        return axios.post(`${base.base}/Finance/UpdateSubjectByIsShow`,{
            params:param
        })
    },

    //#endregion

    //#region 账户管理

    //账户列表
    ListAccount(param){
        return axios.get(`${base.base}/Finance/ListAccount`,{
            params:param
        })
    },

    //单个账户信息
    SingleAccount(param){
        return axios.get(`${base.base}/Finance/SingleAccount`,{
            params:param
        })
    },

    //保存账户信息
    SaveAccount(param){
        return axios.post(`${base.base}/Finance/SaveAccount`,param)
    },

    //删除账户信息
    DeleteAccount(param){
        return axios.post(`${base.base}/Finance/DeleteAccount`,param)
    },

    //账户列表 
    SelectAccountList(param){
        return axios.get(`${base.base}/Finance/SelectAccountList`,{
            params:param
        })
    },

    //零售账户列表-下拉列表专用
    SelectRetailAccountList(){
        return axios.get(`${base.base}/Finance/SelectRetailAccountList`)
    },

    //#endregion

    //#region 费用单

    //获取报销单列表
    ListCost(param){
        return axios.post(`${base.base}/Cost/ListCost`,param)
    },

    //获取单个费用报销单
    SingleCostAllInfo(param){
        return axios.get(`${base.base}/Cost/SingleCostAllInfo`,{
            params:param
        })
    },

    //保存费用报销单
    SaveCostAllInfo(param){
        return axios.post(`${base.base}/Cost/SaveCostAllInfo`,param)
    },

    //删除费用报销单
    DeleteCostAllInfo(param){
        return axios.post(`${base.base}/Cost/DeleteCostAllInfo`,param)
    },

    //删除费用报销单明细
    DeleteCostDetail(param){
        return axios({
            url:`${base.base}/Cost/DeleteCostDetail`,
            method:'post',
            params:param
        })
    },

    //更新费用报销单状态
    UpdateCostState(param){
        return axios.post(`${base.base}/Cost/UpdateCostState`,param)
    },

    //费用单生成财务单
    CreateFinance(param){
        return axios.post(`${base.base}/Cost/CreateFinance`,param)
    },

    //费用单导出到Excel
    ExportCost(param){
        return axios.post(`${base.base}/Cost/ExportCost`,param)
    },

    //#endregion

    //#region 财务单

    //获取财务单列表
    ListFinance(param){
        return axios.post(`${base.base}/Finance/ListFinance`,param)
    },

    //获取单个财务单(财务单、原始列表、备注、状态)
    SingleFinanceAllInfo(param){
        return axios.get(`${base.base}/Finance/SingleFinanceAllInfo`,{
            params:param
        })
    },

    //更新财务单状态
    UpdateFinanceState(param){
        return axios.post(`${base.base}/Finance/UpdateFinanceState`,param)
    },

    //导出财务单到Excel
    ExportFinance(param){
        return axios.post(`${base.base}/Finance/ExportFinance`,param)
    },

    //撤销财务单
    CancelFinance(param){
        return axios({
            url:`${base.base}/Finance/CancelFinance`,
            method:'post',
            params:param
        })
    },


    //财务单结款
    PayFinance(param){
        return axios.post(`${base.base}/Finance/PayFinance`,param)
    },

    //财务单退款
    RefundFinance(param){
        return axios({
            url:`${base.base}/Finance/RefundFinance`,
            method:'post',
            params:param
        })
    },


    //保存财务单
    SaveFinanceAllInfo(param){
        return axios.post(`${base.base}/Finance/SaveFinanceAllInfo`,param)
    },


    //保存财务单-差额项
    SaveFinanceBalance(param){
        return axios.post(`${base.base}/Finance/SaveFinanceBalance`,param)
    },

    //删除财务单-差额项
    DeleteBalanceById(param){
        return axios({
            url:`${base.base}/Finance/DeleteBalanceById`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 财务差额项

    //列表-分页
    PageFinanceBalanceList(param){
        return axios.post(`${base.base}/FinanceBalance/PageFinanceBalanceList`,param)
    },

    //#endregion

    //#region 非现金财务单

    //列表
    PageFinanceNoCashList(param){
        return axios.post(`${base.base}/FinanceNoCash/PageFinanceNoCashList`,param)
    },

    //单个详情
    GetFinanceNoCashAllInfo(param){
        return axios.get(`${base.base}/FinanceNoCash/GetFinanceNoCashAllInfo`,{
            params:param
        })
    },

    //更新状态
    UpdateFinanceNoCashState(param){
        return axios.post(`${base.base}/FinanceNoCash/UpdateFinanceNoCashState`,param)
    },

    //#endregion

    //#region 每日结余记录

    ListSurplus(param){
        return axios.post(`${base.base}/Surplus/ListSurplus`,param)
    },

    //手动计算每日结余
    ManualStartTask(param){
        return axios.post(`${base.base}/Surplus/ManualStartTask`,param)
    },

    //#endregion
    
    //#region 发票管理

    //分页列表
    PageInvoiceList(param){
        return axios.post(`${base.base}/FinanceInvoice/PageInvoiceList`,param)
    },

    //单个信息
    GetInvoiceInfo(param){
        return axios.get(`${base.base}/FinanceInvoice/GetInvoiceInfo`,{
            params:param
        })
    },

    //保存
    SaveInvoice(param){
        return axios.post(`${base.base}/FinanceInvoice/SaveInvoice`,param)
    },

    //删除
    DeleteInvoice(param){
        return axios.post(`${base.base}/FinanceInvoice/DeleteInvoice`,param)
    },

    //下拉列表
    GetInvoiceList(){
        return axios.get(`${base.base}/FinanceInvoice/GetInvoiceList`)
    },

    //获取默认发票信息
    GetDefInvoiceInfo(){
        return axios.get(`${base.base}/FinanceInvoice/GetDefInvoiceInfo`)
    },

    //#endregion

    //#region 财务对账单

    //分页列表
    PageCycleList(param){
        return axios.post(`${base.base}/Cycle/PageCycleList`,param)
    },

    //获取单个对账单(包含明细)
    GetCycleAllInfo(param){
        return axios.get(`${base.base}/Cycle/GetCycleAllInfo`,{
            params:param
        })
    },

    //保存
    SaveCycleAllInfo(param){
        return axios.post(`${base.base}/Cycle/SaveCycleAllInfo`,param)
    },

    //删除对账单(包含明细)
    DeleteCycleAllInfo(param){
        return axios.post(`${base.base}/Cycle/DeleteCycleAllInfo`,param)
    },

    //删除我方发票
    DeleteCycleOurInvoice(param){
        return axios({
            url:`${base.base}/Cycle/DeleteCycleOurInvoice`,
            method:'post',
            params:param
        })
    },

    //删除交易方发票
    DeleteCycleTradeInvoice(param){
        return axios({
            url:`${base.base}/Cycle/DeleteCycleTradeInvoice`,
            method:'post',
            params:param
        })
    },

    

    //#endregion

    //#region 库存账

    //明细列表
    PageStockDetailList(param){
        return axios.post(`${base.base}/StockDetail/PageStockDetailList`,param)
    },

    //库存日账
    PageStockDetailDayList(param){
        return axios.post(`${base.base}/StockDetail/PageStockDetailDayList`,param)
    },

    //库存月账
    PageStockDetailMonthList(param){
        return axios.post(`${base.base}/StockDetail/PageStockDetailMonthList`,param)
    },


    //导出明细列表
    ExportStockDetailList(param){
        return axios.post(`${base.base}/StockDetail/ExportStockDetailList`,param)
    },

    //导出日账
    ExportStockDetailDayList(param){
        return axios.post(`${base.base}/StockDetail/ExportStockDetailDayList`,param)
    },

    //导出月账
    ExportStockDetailMonthList(param){
        return axios.post(`${base.base}/StockDetail/ExportStockDetailMonthList`,param)
    },

    //#endregion

    //#region 交易账

    //交易明细账
    PageTradeDetailList(param){
        return axios.post(`${base.base}/TradeDetail/PageTradeDetailList`,param)
    },

    //获取单个信息
    GetTradeDetail(param){
        return axios.get(`${base.base}/TradeDetail/GetTradeDetail`,{
            params:param
        })
    },

    //删除
    DeleteTradeDetail(param){
        return axios.post(`${base.base}/TradeDetail/DeleteTradeDetail`,param)
    },

    //交易日账
    PageTradeDetailDayList(param){
        return axios.post(`${base.base}/TradeDetail/PageTradeDetailDayList`,param)
    },

    //交易月账
    PageTradeDetailMonthList(param){
        return axios.post(`${base.base}/TradeDetail/PageTradeDetailMonthList`,param)
    },

    //导出交易明细账
    ExportTradeDetail(param){
        return axios.post(`${base.base}/TradeDetail/ExportTradeDetail`,param)
    },

    //#endregion
 }
 
 export default finance