<template>
  <div>

    <el-form :inline="true"
        v-model="processInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="processInfo.info">

                <el-col :span="8">
                    <el-form-item label="审批类型">
                        <el-select v-model.number="processInfo.info.contractType">
                            <el-option :value="1" label="企业"></el-option>
                            <el-option :value="2" label="个人"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="使用说明">
                      <el-input v-model="processInfo.info.title"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="接收人">
                      <el-input v-model="processInfo.info.sendCompany"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="抄送">
                      <el-input v-model="processInfo.info.sendPhone"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="创建时间">
                        <el-date-picker v-model="processInfo.info.createDate" type="date" placeholder="创建时间" value-format="YYYY-MM-DD" />
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="状态">
                      <el-tag v-if="processInfo.info.state ==1">新创建</el-tag>
                      <el-tag v-if="processInfo.info.state ==2">已发送</el-tag>
                      <el-tag v-if="processInfo.info.state ==3">签署中</el-tag>
                      <el-tag v-if="processInfo.info.state ==4">已完成</el-tag>
                      <el-tag v-if="processInfo.info.state ==5">已逾期</el-tag>
                      <el-tag v-if="processInfo.info.state ==6">已取消</el-tag>                        
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Oa',['processInfo']),
    },
}
</script>

<style>

</style>