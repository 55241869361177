<template>
  <!-- 单位换算 -->
  <div>
        <!-- 新增 -->
        <div class="skuBtnList">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnAddSkuUnit">添加</el-button>
        </div>
      
        <!-- 列表 -->
        <el-table :data="goodsInfo.skuUnitList" border stripe style="width: 100%">
            <el-table-column label="单位" width="130">
                <template #default="scope">
                    1
                    <el-select v-model="scope.row.unitId" style="width:80px">
                        <el-option v-for="item in allUnitList" :key="item.unitId" :label="item.unitName" :value="item.unitId"></el-option>
                    </el-select>
                     =
                </template>
            </el-table-column>

            <el-table-column label="换算值" width="120">
                <template v-slot="scope">
                    <el-input-number v-model.number="scope.row.unitValue" :precision="2" :controls="false" style="width:70px"></el-input-number>
                    {{scope.row.minUnitName}}
                </template>
            </el-table-column>

            <el-table-column label="零售价">
                <template v-slot="scope">
                    <el-input-number v-model.number="scope.row.retailPrice" :precision="2" :controls="false" style="width:80px"></el-input-number>
                </template>
            </el-table-column>

            <el-table-column label="批发价">
                <template v-slot="scope">
                    <el-input-number v-model.number="scope.row.wholesalePrice" :precision="2" :controls="false" style="width:80px"></el-input-number>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template v-slot="scope">
                    <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="DeleteRow(scope.row.unitId,scope.$index)">
                        <template #reference>
                            <el-button size="small" type="danger">删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>

        </el-table>


  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex';
export default {
    name:'BoxSkuUnit',
    computed:{
        ...mapState('Erp',['goodsInfo'])
    },
    data(){
        return{         
            //全部单位列表
            allUnitList:[],  
            
        }
    },
    methods:{

        //新增单位
        btnAddSkuUnit(){
            if(this.goodsInfo.info.minUnitId==0){
                ElMessage.error('请先选择最小单位');
                return false;
            }

            let newRow = {
                id:0,
                systemNumber:'',
                minUnitName:this.goodsInfo.info.minUnitName,
                skuNumber:this.goodsInfo.info.skuNumber,
                unitId:this.allUnitList[0].unitId,  //默认加载第一个单位
                unitName:'',
                retailPrice:0,   //零售价
                wholesalePrice:0,  //批发价
                unitValue:1
            }
            this.goodsInfo.skuUnitList.push(newRow);
        },

        //删除换算单位列表
        DeleteRow(unitId,index){
            this.goodsInfo.skuUnitList.splice(index,1);  //删除界面数据
            if(unitId !=0){
                let param = {
                    skuNumber:this.goodsInfo.info.skuNumber,
                    unitId:unitId
                }
                this.$api.goods.DeleteSkuUnit(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },

        //获取全部单位列表
        SelectAllUnitList(){
            this.$api.goods.GetUnitList().then(res=>{
                if(res.data.status === 200){
                    this.allUnitList = res.data.data;
                }
            })
        },


        


    },
    mounted(){    
        this.SelectAllUnitList();    
    },
}
</script>

<style>

</style>