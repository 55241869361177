<template>
  <div>
    <!-- 创建退零单-弹出框 -->
    <el-divider></el-divider>

    <TabReturnRetail ref="tabRetail"></TabReturnRetail>
    <el-divider>退零单明细</el-divider>
    <TabReturnRetailDetail></TabReturnRetailDetail>

    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import TabReturnRetail from '@/views/Rms/ReturnRetail/TabReturnRetail.vue'
import TabReturnRetailDetail from '@/views/Rms/ReturnRetail/TabReturnRetailDetail.vue'
import { mapMutations, mapState } from 'vuex';
import { ElMessage } from 'element-plus';
export default {
    props:['oldInfo'],
    data(){
        return{
        }
    },
    components:{
        TabReturnRetail,
        TabReturnRetailDetail,
    },
    computed:{
        ...mapState('Erp',['returnRetailInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetReturnRetailInfo']),

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //加载数据
        GetDataInfo(){      
            let newInfo = {
                info:{
                    billNumber:'',
                    systemNumber:this.oldInfo.info.systemNumber,
                    shiftNumber:this.oldInfo.info.shiftNumber,  //所属班次
                    shiftName:this.oldInfo.info.shiftName,  //班次名称
                    departmentId:this.oldInfo.info.departmentId,
                    departmentName:this.oldInfo.info.departmentName,
                    tradeType:this.oldInfo.info.tradeType,
                    tradeNumber:this.oldInfo.info.tradeNumber,
                    tradeName:this.oldInfo.info.tradeName,
                    accountNumber:this.oldInfo.info.accountNumber,  //账户编号
                    accountName:this.oldInfo.info.accountName,  //账户名称
                    totalPrice:this.oldInfo.info.totalPrice,
                    realPrice:this.oldInfo.info.realPrice,
                    payModel:this.oldInfo.info.payModel,
                    originNumber:this.oldInfo.info.retailNumber,  //原始单号
                    payMode:this.oldInfo.info.payMode,  //支付操作
                    state:1,
                    isDel:0,
                },
                detailList:[],  //退零单明细
            }     
            //组装明细
            this.oldInfo.detailList.forEach(item => {
                let detail ={
                    id:0,
                    systemNumber:item.systemNumber,
                    billNumber:'',  //退零单号
                    warehouseNumber:item.warehouseNumber,
                    warehouseName:item.warehouseName,
                    cid:item.cid,
                    cidName:item.cidName,
                    brandId:item.brandId,
                    brandName:item.brandName,
                    spuNumber:item.spuNumber,
                    spuName:item.spuName,
                    skuNumber:item.skuNumber,
                    skuName:item.skuName,
                    unitId:item.unitId,
                    unitName:item.unitName,
                    costUnitPrice:item.costUnitPrice,
                    unitPrice:item.unitPrice,
                    realNum:item.realNum,
                    totalPrice:item.totalPrice,
                    skuUnitList:item.skuUnitList,  //单位列表 
                    remark:'',  //备注
                    isEdit:1,
                    isDel:0,
                }   
                newInfo.detailList.push(detail);
            });
            this.SetReturnRetailInfo(newInfo);
        },

        //保存退零单
        btnSaveData(){
            this.$api.erp.SaveReturnRetailAllInfo(this.returnRetailInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    this.returnRetailInfo.info.billNumber = res.data.data;  //退零单号 
                    //关闭-弹出框
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>