<template>
  <div>
    <!-- 个人设置列表页 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="员工姓名">
                <el-select v-model="info.search.userNumber" clearable filterable class="search-200">
                    <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                </el-select>
            </el-form-item>

            <!-- <el-form-item label="单据类型">
                <el-select v-model="info.search.billType">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="采购单"></el-option>
                    <el-option :value="2" label="销售单"></el-option>
                    <el-option :value="14" label="退供单"></el-option>
                    <el-option :value="15" label="退货单"></el-option>
                </el-select>
            </el-form-item> -->

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>           
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                        
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" /> 
        <el-table-column prop="id" label="编号" />
        <el-table-column prop="userName" label="用户名称" />
        <!-- <el-table-column label="单据类型">
            <template v-slot="scope">
                <el-tag v-if="scope.row.billType == 1">采购单</el-tag>
                <el-tag v-if="scope.row.billType == 2">销售单</el-tag>
            </template>
        </el-table-column> -->
        <el-table-column prop="limitDay" label="制单限制天数" />
        <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.id)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.userNumber)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange">
            </el-pagination>
        </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="450"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditSetUser :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditSetUser>
    </el-dialog>

  </div>
</template>

<script>
import EditSetUser from '@/views/SetUp/SetUser/EditSetUser.vue'
import AllUserList from '@/utils/mixins/AllUserList.js'
import { ElMessage } from 'element-plus'
export default {
    mixins:[AllUserList],
    components:{
        EditSetUser,
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    userNumber:'',
                    billType:0,
                }                
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'制单设置'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据
        GetDataList(){
            let param = {
                userNumber:this.info.search.userNumber,
                billType:this.info.search.billType,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.common.PageSetUserList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.userNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑
        btnEditInfo(id){
            this.info.id = id
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(userNumber){
            let param = [userNumber]
            this.$api.common.DeleteSetUser(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.common.DeleteSetUser(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.userNumber='';
            this.info.search.billType = 0;
            this.info.page = 1,
            this.GetDataList()
        }, 

    },
    mounted(){
        this.GetDataList();
    }

}
</script>

<style>

</style>