/**
 * 获取员工列表 - 下拉列表
 * 
 */

const AllUserList = {
    data(){
        return{
            selectUserList:[]
        }
    },
    methods:{
        //加载员工下拉列表
        SelectUserList(){
            this.$api.system.SelectUserList().then(res=>{
                if(res.data.status === 200){
                    this.selectUserList = res.data.data  //用户列表
                }else{
                    console.log(res.data.msg);
                }
            }).catch(e=>{
                console.log(e.message);
            })
        }
    },
    mounted(){
        this.SelectUserList();   //加载员工列表
    }
}

export default AllUserList;