<template>
  <div>
      
      <el-form :inline="true"
        v-model="purchaseInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="purchaseInfo.info">

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="采购单号">
                        <el-input v-model="purchaseInfo.info.billNumber" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="所属部门">
                      <template #label>
                          <span style="color:red;">*</span>
                          <span>所属部门</span>
                      </template>
                        <el-tree-select v-model="purchaseInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" @change="onLoadSetDpt"/>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24" v-if="this.setDptInfo.shiftFlag ==1">
                    <el-form-item label="所属班次">
                        <el-select v-model="purchaseInfo.info.shiftNumber" class="search-200" clearable>
                            <el-option v-for="shiftInfo in shiftRecordList" :key="shiftInfo.shiftNumber" :label="shiftInfo.shiftName" :value="shiftInfo.shiftNumber"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="入库仓库">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>入库仓库</span>
                        </template>
                        <el-select v-model="purchaseInfo.info.warehouseNumber" clearable class="search-200">
                          <el-option v-for="warehouse in userWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="交易者">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>交易者</span>
                        </template>
                        <el-select v-model="purchaseInfo.info.tradeNumber" filterable class="search-200">
                            <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="记账时间">
                        <el-date-picker v-model="purchaseInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD HH:mm:ss" @visible-change="ChangeBillTime" :disabled-date="BillDisabledDate" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="期望到货时间">
                        <el-date-picker v-model="purchaseInfo.info.arriveTime" type="date" placeholder="期望到货时间" value-format="YYYY-MM-DD" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="物流标识">
                        <el-select v-model.number="purchaseInfo.info.isTransport" disabled class="search-200">
                            <el-option :value="0" label="未使用"></el-option>
                            <el-option :value="1" label="已使用"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="总价">
                      <el-input v-model="purchaseInfo.info.totalPrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="状态">
                      <el-tag v-if="purchaseInfo.info.state ==1">新建单</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==2">待审核</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==3" type="danger">已驳回</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==4" type="success">已审核</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==5" type="warning">已发货</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==6">已收货</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==7" type="success">已入库</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==9" type="info">已取消</el-tag>
                      <el-tag v-if="purchaseInfo.info.state ==10" type="info">已完成</el-tag>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserDptList from '@/utils/mixins/UserDptList.js'
import UserWhList from '@/utils/mixins/UserWhList.js'  //用户关联仓库
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    name:'TabPurchase',
    mixins:[UserDptList,UserWhList,PartTradeList],
    data(){
      return{
        departmentId:0,  //选择后的-部门编号
        invoiceList:[],  //发票类型列表
        shiftRecordList:[],  //班次列表
        setDptInfo:{},  //部门设置信息
      }
    },
    computed:{
        ...mapState('Purchase',['purchaseInfo']),        
    },
    methods:{
      
      //加载发票类型列表
      SelectInvoiceTypeList(){
        let param = {
          groupName:'InvoiceType'
        }
        this.$api.common.SelectDict(param).then(res=>{
          if(res.data.status === 200){
            this.invoiceList = res.data.data
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      
      //记账时间发生改变时
      ChangeBillTime(){
        this.purchaseInfo.info.arriveTime = moment(this.purchaseInfo.info.billTime).format("YYYY-MM-DD")
      },

      //加载部门设置
      onLoadSetDpt(){
        let param = {
            departmentId:this.purchaseInfo.info.departmentId
        }
        this.$api.common.GetCacheSetDptInfo(param).then(res=>{
            if(res.data.status === 200){
                this.setDptInfo = res.data.data;
                if(this.setDptInfo.shiftFlag ==1){
                    this.purchaseInfo.info.shiftNumber = this.shiftRecordList[0].shiftNumber;
                }else{                    
                    this.purchaseInfo.info.shiftNumber = ''; //清空班次信息
                }
            }else{
                this.setDptInfo ={}
                this.purchaseInfo.info.shiftNumber = ''; //清空班次信息
            }
        })
      },

      //获取班次列表
      SelectShiftRecordList(){
          this.$api.erp.GetShiftRecordList().then(res=>{
              if(res.data.status === 200){
                  this.shiftRecordList = res.data.data;
                  //新增时，默认加载第一个 
                  if(this.shiftRecordList !=null && this.purchaseInfo.info.billNumber==''){
                      this.purchaseInfo.info.shiftNumber = this.shiftRecordList[0].shiftNumber;
                  }
              }else{
                  ElMessage.error(res.data.msg);
              }
          })
      },

    },
    mounted(){
    },

}
</script>

<style scoped>
.Remark {color: #cbc1c1;padding-left: 40px;}
</style>