<template>
  <div>

    <el-form :inline="true"
        v-model="customerInfo.info"
        label-width="80px"
        label-position="right">
            <el-row v-if="customerInfo.info">

                <el-col :span="12">
                    <el-form-item label="客户简称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>客户名称</span>
                        </template>
                        <el-input v-model="customerInfo.info.customerName" :maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="客户全称">
                        <el-input v-model="customerInfo.info.customerAlias" :maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="联系人">
                        <el-input v-model="customerInfo.info.linkMan" :maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="联系电话">
                        <el-input v-model="customerInfo.info.linkPhone" :maxlength="20" class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :span="12">
                    <el-form-item label="等级">
                        <el-select v-model="customerInfo.info.grade" class="search-200">
                            <el-option label="A" value="A"></el-option>
                            <el-option label="B" value="B"></el-option>
                            <el-option label="C" value="C"></el-option>
                            <el-option label="D" value="D"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="省市区">
                        <el-cascader :options="areaList" clearable v-model="customerInfo.info.selectArea" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="详细地址">
                        <el-input v-model="customerInfo.info.address" minlength="6" maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="主营业务">
                        <el-input v-model="customerInfo.info.mainBusiness" maxlength="150" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="显示标识">
                        <el-select v-model="customerInfo.info.isShow" class="search-200">
                            <el-option :value="1" label="显示"></el-option>
                            <el-option :value="0" label="隐藏"></el-option>
                        </el-select>
                        <!-- 提示 -->
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="在制单过程中交易者列表是否显示该客户"
                            placement="top"
                        >
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

  </div>
</template>

<script>
import areaList from '@/utils/mixins/AreaList.js'
import { mapState } from 'vuex'
export default {
    name:'TabCustomer',
    mixins:[areaList],
    computed:{
        ...mapState('Erp',['customerInfo'])
    },
}
</script>

<style>

</style>