<template>
  <div>

    <el-form :inline="true"
    v-model="billInInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="billInInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="入库单号">
                    <el-input v-model="billInInfo.info.inNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="billInInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="交易者">
                    <el-select v-model="billInInfo.info.tradeNumber" filterable v-if="billInInfo.info.originType==20" class="search-200">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                    <el-select v-model="billInInfo.info.tradeNumber" filterable disabled v-else class="search-200">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>
                        
            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="原始单据类型">
                  <el-select v-model="billInInfo.info.originType" disabled class="search-200">
                    <el-option label="无" :value="0"></el-option>
                    <el-option label="采购单" :value="1"></el-option>
                    <el-option label="销售单" :value="2"></el-option>
                    <el-option label="零售单" :value="3"></el-option>
                    <el-option label="加工单" :value="6"></el-option>
                    <el-option label="移仓单" :value="13"></el-option>
                    <el-option label="退供单" :value="14"></el-option>
                    <el-option label="退货单" :value="15"></el-option>
                    <el-option label="入库单" :value="20"></el-option>
                    <el-option label="出库单" :value="21"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="原始单据编号">
                  <el-input v-model="billInInfo.info.originNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="入库时间">
                  <el-date-picker v-model="billInInfo.info.inTime" type="datetime" placeholder="入库时间" value-format="YYYY-MM-DD HH:mm:ss"  style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="经手人">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>经手人</span>
                    </template>
                    <el-select v-model="billInInfo.info.receiver" disabled class="search-200">
                        <el-option v-for="user in selectUserList" :key="user.userNumber" :label="user.userName" :value="user.userNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="billInInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="billInInfo.info.state ==2" type="danger">入库中</el-tag>
                  <el-tag v-if="billInInfo.info.state ==3" type="success">已入库</el-tag>
                  <el-tag v-if="billInInfo.info.state ==10">已取消</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllUserList from '@/utils/mixins/AllUserList.js'
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,AllUserList,PartTradeList],
    data(){
        return{
            departmentId:0,  //选择后的-部门编号
        }
    },
    computed:{
        ...mapState('Erp',['billInInfo'])
    },
    methods:{
        
    }
}
</script>

<style>

</style>