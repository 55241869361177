<template>
  <div>

    <el-form :inline="true"
    v-model="businessInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="businessInfo.info">

            <el-col :span="8">
                <el-form-item label="业务单号">
                    <el-input v-model="businessInfo.info.billNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="原始单号">
                    <el-input v-model="businessInfo.info.originNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="单据类型">
                    <el-tag v-if="businessInfo.info.billType ==1" type="danger">采购单</el-tag>
                    <el-tag v-if="businessInfo.info.billType ==2" type="success">销售单</el-tag>
                    <el-tag v-if="businessInfo.info.billType ==3" type="info">零售单</el-tag>
                    <el-tag v-if="businessInfo.info.billType ==4" type="danger">差额单</el-tag>
                    <el-tag v-if="businessInfo.info.billType ==14" type="danger">退供单</el-tag>
                    <el-tag v-if="businessInfo.info.billType ==15" type="danger">退货单</el-tag>
                    <el-tag v-if="businessInfo.info.billType ==17" type="info">网络订单</el-tag>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                    <el-tree-select v-model="businessInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="交易者类型">
                    <el-select v-model="businessInfo.info.tradeType" @change="ChangeTradeType" class="search-200">
                        <el-option :value="1" label="供应商"></el-option>
                        <el-option :value="2" label="客户"></el-option>
                        <el-option :value="3" label="员工"></el-option>
                        <el-option :value="4" label="散客"></el-option>
                        <!-- <el-option :value="5" label="会员"></el-option> -->
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="交易者">
                    <el-select v-model="businessInfo.info.tradeNumber" filterable  @change="ChangeTradeName" class="search-200">
                        <el-option v-for="trade in tradeList" :key="trade.tradeNumber" :label="trade.tradeName" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>


            <el-col :span="8">
                <el-form-item label="产品类目">
                    <el-input v-model="businessInfo.info.cidName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="品牌">
                    <el-input v-model="businessInfo.info.brandName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="产品名称">
                    <el-input v-model="businessInfo.info.spuName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="规格">
                    <el-input v-model="businessInfo.info.skuName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="单位">
                    <el-input v-model="businessInfo.info.unitName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="单价">
                    <el-input v-model="businessInfo.info.unitPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="数量">
                    <el-input v-model="businessInfo.info.itemNum" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="总价">
                    <el-input v-model="businessInfo.info.totalPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="财务单标识">
                    <el-tag v-if="businessInfo.info.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="businessInfo.info.financeFlag == 1" type="info">已生成</el-tag>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="财务单号">
                    <el-input v-model="businessInfo.info.financeNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="备注">
                    <el-input v-model="businessInfo.info.remark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="支付状态">
                    <el-tag v-if="businessInfo.info.payState == 0" type="danger">未支付</el-tag>
                    <el-tag v-if="businessInfo.info.payState == 1" type="info">已支付</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
    data(){
        return{
            tradeList:[],   //交易者列表
        }
    },
    mixins:[UserDptList],
    computed:{
        ...mapState('Erp',['businessInfo'])
    },
    methods:{
        //交易者类型
        ChangeTradeType(){
            this.SelectTradeList();  //获取交易者
        },

        //获取交易者列表(父组件也在调用)
        SelectTradeList(){
            if(this.businessInfo.info.tradeType != 0){
                let param = {
                    tradeType:this.businessInfo.info.tradeType
                }
                this.$api.erp.SelectTradeList(param).then(res=>{
                    if(res.data.status === 200){
                        this.tradeList = res.data.data;
                        return false;
                    }
                })
                this.tradeList = [];
            }
            else{
                //全部
                this.tradeList = [];
            }        
        },

        
        //交易者发生改变时:更新交易者(中文)字段
        ChangeTradeName(option){
            if(option !=""){
                let tradeInfo = this.tradeList.find(item=>{
                    return item.tradeNumber === option
                })
                this.businessInfo.info.tradeName = tradeInfo.tradeName;
            }else{
                this.businessInfo.info.tradeName = "";
            }
        },

    }
}
</script>

<style>

</style>