<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row v-if="info">

            <el-col :span="24">
                <el-form-item label="差额说明">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>差额说明</span>
                    </template>
                    <el-input v-model="info.content" :maxlength="100" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="金额">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>金额</span>
                    </template>
                    <el-input-number v-model="info.totalPrice" :precision="2" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>
            
            <el-col :span="24">
                <el-form-item label="备注">
                    <el-input v-model="info.remark" :maxlength="100" class="search-200"></el-input>
                </el-form-item>
            </el-col>


            <el-col :span="24">
                <el-form-item label="凭证">
                    <!-- 上传图片 -->
                      <el-image
                          class="ImgTheme"
                          v-if="info.fileUrl"
                          :src="info.fileUrl"
                          :preview-src-list="[info.fileUrl]"
                          :hide-on-click-modal="true"
                          fit="fill">
                      </el-image>

                      <el-upload
                      :action="GetFileUrl('Finance')"
                      :headers="GetHeader"
                      :show-file-list="false"
                      :before-upload="BeforeUpload"
                      :on-success="UploadSuccess">
                          <el-tag class="UploadImg">上传凭证</el-tag>
                      </el-upload>
                </el-form-item>
            </el-col>

            

        </el-row>
    </el-form>
  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus'
import moment from 'moment'
export default {
    data(){
        return{
            info:{
                id:0,
                systemNumber:'',
                billNumber:'',
                totalPrice:0,
                content:'',
                fileType:0,  //文件类型0:无 1:图片 2:文件
                fileUrl:'',
                remark:'',
                createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                isDel:0,
                isEdit:1,
            }
        }
    },
    computed:{
      //添加Token - 重要
      GetHeader(){
          return {
            "Authorization":"Bearer "+ Authorize.getToken()
          }
        },
    },
    methods:{
        //加载数据
        GetDataInfo(){
            this.info = {
                id:0,
                systemNumber:'',
                billNumber:'',
                totalPrice:0,
                content:'',
                fileType:0,  //文件类型0:无 1:图片 2:文件
                fileUrl:'',
                remark:'',
                createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                isDel:0,
                isEdit:1,
            }
        },


      //上传图片之前操作
      BeforeUpload(file){
        const fileType = file.type;
        const fileSize = file.size;
        
        if(fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !='application/pdf'){
            ElMessage.error('上传文件格式:jpeg|png|pdf')
            return false;
        }
        if(fileType == 'application/pdf'){
            this.info.fileType = 2;
        }else{
            this.info.fileType = 1;
        }

        //文件大小
        if(fileSize/1024/1024 > 3){
            ElMessage.error('上传文件大小不能超过3Mb')
            return false;
        }

      },
      //上传完成
      UploadSuccess(res){
        if(res.status === 200){
          this.info.fileUrl = res.data
        }
      },
      //上传失败
      UploadError(e){
        ElMessage.error(e)
      }

    },
    mounted(){
    },

}
</script>

<style scoped>
 .ImgTheme{height: 80px;width: 80px;border: 1px solid #ccc;padding: 5px;}
  .UploadImg{margin: 5px;}
</style>