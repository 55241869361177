<template>
  <div>
    <!-- 编辑审批单 -->
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName">
        <el-tab-pane label="审批单" name="tabInfo">
            <TabProcess></TabProcess>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabRecord></TabRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabState></TabState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>


  </div>
</template>

<script>

import TabProcess from '@/views/Oa/Process/Info/TabProcess.vue'
import TabRecord from '@/views/Oa/Process/Info/TabRecord.vue'
import TabState from '@/views/Oa/Process/Info/TabState.vue'
import { ElMessage } from 'element-plus'
export default {
    name:'EditProcess',
    props:['editId','dataList'],
    components:{
        TabProcess,
        TabRecord,
        TabState,
    },
    data(){
        return{
            info:{
                activeName:'tabInfo',
                billNumber:this.editId,
            }
        }
    },
    methods:{

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.contractNumber === this.info.contractNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.contractNumber = prevInfo.contractNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.contractNumber === this.info.contractNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.contractNumber = prevInfo.contractNumber
                this.GetDataInfo()
            }
        },

    }

}
</script>

<style>

</style>