/**
 * ERP模块相关数据
 * 
 */


var actions = {

}

var mutations = {

    //设置产品信息
    SetSpuInfo(state,value){
        state.spuInfo = value
    },

    //设置规格数据
    SetGoodsInfo(state,value){
        state.goodsInfo = value
    },

    //设置物流单数据
    SetTransportInfo(state,value){
        state.transportInfo = value
    },

    //设置差额单数据
    SetDifferenceInfo(state,value){
        state.differenceInfo = value
    },

    //设置客户信息
    SetCustomerInfo(state,value){
        state.customerInfo = value
    },

    //设置财务单信息
    SetFinanceInfo(state,value){
        state.financeInfo = value
    },

    //设置销售单信息
    SetSaleInfo(state,value){
        state.saleInfo = value
    },

    //设置零售单
    SetRetailInfo(state,value){
        state.retailInfo = value
    },

    //设置加工单
    SetWorkInfo(state,value){
        state.workInfo = value
    },

    //设置盘点单
    SetCheckInfo(state,value){
        state.checkInfo = value
    },

    //设置费用单
    SetCostInfo(state,value){
        state.costInfo = value
    },

    //设置报损单
    SetLossInfo(state,value){
        state.lossInfo = value
    },

    //设置入库单
    SetBillInInfo(state,value){
        state.billInInfo = value
    },

    //设置出库单
    SetBillOutInfo(state,value){
        state.billOutInfo = value
    },

    //设置退货单
    SetReturnSaleInfo(state,value){
        state.returnSaleInfo = value
    },

    //设置退供单
    SetReturnPurchaseInfo(state,value){
        state.returnPurchaseInfo = value
    },

    //设置业务单
    SetBusinessInfo(state,value){
        state.businessInfo = value
    },

    //设置加工组
    SetWorkGroupInfo(state,value){
        state.workGroupInfo = value
    },

    //设置移仓单
    SetMoveInfo(state,value){
        state.moveInfo = value
    },

    //设置零售交单记录
    SetShiftRecordInfo(state,value){
        state.shiftRecordInfo = value
    },

    //设置零售班组
    SetRetailGroupInfo(state,value){
        state.retailGroupInfo = value
    },

    //设置退零单
    SetReturnRetailInfo(state,value){
        state.returnRetailInfo = value
    },

    //设置业务对账单
    SetCycleBusinessInfo(state,value){
        state.cycleBusinessInfo = value
    },
    
}

var state = {
    spuInfo:{},         //产品信息
    goodsInfo:{},       //规格信息
    transportInfo:{},   //物流单
    differenceInfo:{},  //差额单
    customerInfo:{},    //客户信息
    financeInfo:{},     //财务单
    saleInfo:{},        //销售单
    retailInfo:{},      //零售单
    workInfo:{},        //加工单
    checkInfo:{},       //盘点单
    costInfo:{},        //费用单
    lossInfo:{},        //报损单
    billInInfo:{},      //入库单
    billOutInfo:{},     //出库单
    returnSaleInfo:{},  //退货单
    returnPurchaseInfo:{}, //退供单
    businessInfo:{},      //业务单
    workGroupInfo:{},    //加工组
    moveInfo:{},        //移仓单
    shiftRecordInfo:{},       //零售交单记录
    retailGroupInfo:{},   //零售班组
    returnRetailInfo:{},   //退零单
    cycleBusinessInfo:{},      //业务对账单
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}