<template>
  <div>
    <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">

        <el-col :span="12">
            <el-form-item label="产品类目">
                <template #label>
                    <span style="color:red;">*</span>
                    <span>产品类目</span>
                </template>
                <el-tree-select v-model="info.cid" :data="selectCategoryList" :default-expand-all="true" class="search-200"/>
            </el-form-item>
        </el-col>

        <el-col :span="24">
            <el-form-item label="产品名称">
                <template #label>
                    <span style="color:red;">*</span>
                    <span>产品名称</span>
                </template>
                <el-input v-model="info.spuName" class="search-200"></el-input>
            </el-form-item>
        </el-col>

    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
      <el-button type="primary" @click="btnSaveSpuInfo">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import CategoryList from '@/utils/mixins/CategoryList.js'  //负责加载类目下拉框
import { ElMessage } from 'element-plus'
export default {
    mixins:[CategoryList],
    props:['editCid'],
    data(){
        return{
            info:{
                id:0,
                cid:this.editCid,  //选中的产品分类
                systemNumber:'',
                spuNumber:'',
                spuName:'',
            }
        }
    },
    methods:{
        //保存产品
        btnSaveSpuInfo(){
            this.$api.goods.SaveSpuInfo(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },

}
</script>

<style>

</style>