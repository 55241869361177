<template>
  <div>
    <el-form :inline="true"
        v-model="financeInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="financeInfo.info">
                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="财务单号">
                        <el-input v-model="financeInfo.info.billNumber" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="所属部门">
                        <el-tree-select v-model="financeInfo.info.departmentId" :data="departmentList" :default-expand-all="true" disabled class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="会计科目">
                        <el-input v-model="financeInfo.info.subFullName" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="财务类型">
                        <el-select v-model="financeInfo.info.billType" disabled class="search-200">
                            <el-option v-for="typeInfo in info.financeTypeList" :key="typeInfo.typeId" :label="typeInfo.typeName" :value="typeInfo.typeId"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="交易者类型">
                        <el-select v-model="financeInfo.info.tradeType" disabled class="search-200">
                            <el-option label="供应商" :value="1" />
                            <el-option label="客户" :value="2" />
                            <el-option label="员工" :value="3" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="交易者">
                        <el-input v-model="financeInfo.info.tradeName" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="账户">
                        <el-input v-model="financeInfo.info.accountName" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="应付金额">
                        <el-input v-model="financeInfo.info.payablePrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="实付金额">
                        <el-input v-model="financeInfo.info.totalPrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="记账时间">
                        <el-input v-model="financeInfo.info.billTime" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="用款时间">
                        <el-input v-model="financeInfo.info.payTime" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="备注">
                        <el-input v-model="financeInfo.info.remark" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="审核状态">
                        <el-tag v-if="financeInfo.info.state ==1">新建单</el-tag>
                        <el-tag v-if="financeInfo.info.state ==2" type="danger">已驳回</el-tag>
                        <el-tag v-if="financeInfo.info.state ==3" type="warning">已审核并上报</el-tag>
                        <el-tag v-if="financeInfo.info.state ==4" type="info">已完成</el-tag>
                        <el-tag v-if="financeInfo.info.state ==5" type="info">已结款</el-tag>
                        <el-tag v-if="financeInfo.info.state ==6" type="info">已结算</el-tag>
                        <el-tag v-if="financeInfo.info.state ==10">已关单</el-tag>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="支付状态">
                        <el-tag v-if="financeInfo.info.payState ==0" type="danger">未支付</el-tag>
                        <el-tag v-if="financeInfo.info.payState ==1" type="info">已支付</el-tag>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
    name:'TabSingleFinance',
    mixins:[UserDptList],
    data(){
        return{
            info:{
                financeTypeList:[],  //财务类型-下拉列表
            }
        }
    },
    computed:{
        ...mapState('Erp',['financeInfo'])   //财务单信息
    },
    methods:{
        //财务类型
        SelectFinanceTypeList(){
            this.$api.finance.SelectFinanceTypeList().then(res=>{
                if(res.data.status === 200){
                    this.info.financeTypeList = res.data.data;
                }
            })
        },
    },
    mounted(){
        this.SelectFinanceTypeList();
    },
}
</script>

<style>

</style>