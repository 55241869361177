/**
 * 统计管理模块
 * 
 */


//货品量统计
import ListStatGoods from '@/views/Ams/GoodsVolumeAnalyse.vue'
//营业额统计
import TurnoverAnalyse from '@/views/Ams/TurnoverAnalyse.vue'
//统计-账户统计
import AccountAnalyse from '@/views/Ams/AccountAnalyse.vue'
//单品价格走势图
import GoodsPriceAnalyse from '@/views/Ams/GoodsPriceAnalyse.vue'
//销售员业绩统计
import SalerAnalyse from '@/views/Ams/Saler/SalerAnalyse.vue'



//大数据页面

//单日数据统计图
import BigDataDayStat from '@/views/Ams/BigData/BigDataDayStat.vue'
//单月数据统计图
import BigDataMonthStat from '@/views/Ams/BigData/BigDataMonthStat.vue'
//全年数据统计图
import BigDataYearStat from '@/views/Ams/BigData/BigDataYearStat.vue'
//全年财务统计
import BigDataYearFinance from '@/views/Ams/BigData/BigDataYearFinance.vue'


export default[
    {
        //货品量统计
        name:'ListStatGoods',
        path:'liststatgoods',
        component:ListStatGoods
    },
    {
        //营业额统计
        name:'TurnoverAnalyse',
        path:'turnoveranalyse',
        component:TurnoverAnalyse
    },
    {
        //账户统计
        name:'AccountAnalyse',
        path:'accountanalyse',
        component:AccountAnalyse
    },
    {
        //单品价格走势图
        name:'GoodsPriceAnalyse',
        path:'goodspriceanalyse',
        component:GoodsPriceAnalyse
    },
    {
        //销售员业绩统计
        name:'SalerAnalyse',
        path:'saleranalyse',
        component:SalerAnalyse
    },

    {
        //单日数据统计图
        name:'BigDataDayStat',
        path:'bigdatadaystat',
        component:BigDataDayStat
    },
    {
        //单月数据统计图
        name:'BigDataMonthStat',
        path:'bigdatamonthstat',
        component:BigDataMonthStat
    },
    {
        //全年数据统计图
        name:'BigDataYearStat',
        path:'bigdatayearstat',
        component:BigDataYearStat
    },
    {
        //全年财务统计
        name:'BigDataYearFinance',
        path:'bigdatayearfinance',
        component:BigDataYearFinance
    },

]