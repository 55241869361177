<template>
  <div>
    <!-- 从图库选择图片 -->
    <el-divider></el-divider>

    <el-row>
        <el-col :span="3" v-for="item in info.dataList" :key="item.id">
            <el-image
                class="ItemImg"
                :src="item.goodImg"
                :preview-src-list="[item.goodImg]"
                :hide-on-click-modal="true"
                fit="cover">
            </el-image><br/>
            <el-checkbox class="ItemSel" v-model="item.selFlag"></el-checkbox>
        </el-col>
    </el-row>

    <div class="btnGroup">
        <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteFileList">
            <template #reference>
                <el-button class="iconfont icon-ashbin btnTk" type="danger" plain>删除</el-button>
            </template>
        </el-popconfirm>
        <el-upload
            :action="GetFileUrl2('Goods')"
            class="btnTk"
            :headers="GetHeader"
            :show-file-list="false"
            multiple
            :limit="5"
            :before-upload="BeforeUpload"
            :on-success="UploadSuccess"
            :on-error="UploadError">
            <el-button class="iconfont icon-add" type="primary" plain >上传新图</el-button>
        </el-upload>
    </div>    

  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                dataList:[],   //Table数据
            },
        }
    },
    computed:{
      //添加Token - 重要
      GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            this.info.dataList = [];  //清空
            let param = {
                module:'Goods'
            }
            this.$api.common.GetFileList(param).then(res=>{
                if(res.data.status === 200){
                    //组装新实体列表
                    let index = 0;
                    res.data.data.forEach(item => {
                        index++;
                        let info = {
                            id:index,
                            goodImg:item,
                            selFlag:false
                        }
                        this.info.dataList.push(info);
                    });
                }
            })
            console.log(this.info.dataList)
        },

        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            
            if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
                ElMessage.error('上传图片格式:jpeg|png')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 3){
                ElMessage.error('上传文件大小不能超过3Mb')
                return false;
            }

        },
        //上传完成
        UploadSuccess(res){
            if(res.status === 200){
                this.GetDataList();  //重新加载
            }
        },
        //上传失败
        UploadError(e){
            ElMessage.error(e)
        },


        //删除图片
        DeleteFileList(){
            let selectItem =[] //选中图片
            this.info.dataList.forEach(item=>{
                if(item.selFlag){
                    selectItem.push(item.goodImg);
                }
            })
            if(selectItem.length<=0){
                ElMessage.error('请选择要删除的图片');
            }
            //
            let param = {
                module:'Goods',
                fileList:selectItem
            }
            this.$api.common.DeleteFileList(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.GetDataList();
                }
            })
        },
        
    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style scoped>
.ItemImg{border: 1px solid #ccc;height: 95px;width: 95px;padding: 2px;}
.ItemSel{padding-left: 45px;}

.btnGroup{margin-bottom: 5px;}
.btnTk{float: right;margin: 5px;}
</style>