<template>
  <div>
    <!-- 生成非现金财务单 - 弹出框 -->
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="allInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="allInfo.info">

                <el-col :span="12">
                    <el-form-item label="部门">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>部门</span>
                        </template>
                        <el-tree-select v-model="allInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200">
                        </el-tree-select>
                    </el-form-item>
                </el-col>      

                <el-col :span="12">
                    <el-form-item label="财务类型">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>财务类型</span>
                        </template>
                        <el-select v-model="allInfo.info.financeType" class="search-200">
                            <el-option label="选择类型" :value="0"/>
                            <el-option v-for="typeInfo in financeTypeList" :key="typeInfo.typeId" :label="typeInfo.typeName" :value="typeInfo.typeId"></el-option>
                            
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="会计科目">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>会计科目</span>
                        </template>
                        <el-tree-select v-model="allInfo.info.subId" :data="selectSubjectList" :default-expanded-keys="[0]" class="search-200">
                        </el-tree-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="记账时间">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>记账时间</span>
                        </template>
                        <el-date-picker v-model="allInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD hh:mm:ss" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="总金额">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>总金额</span>
                        </template>
                        <el-input-number v-model="allInfo.info.totalPrice" :precision="2" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="备注">
                        <el-input v-model="allInfo.info.remark" :maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

    <!-- 备注列表 -->
    <el-divider>备注记录</el-divider>
    <div class="skuBtnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="AddRecord">新增</el-button>
    </div>
    
    <el-table :data="allInfo.recordList" border style="width: 100%">

        <el-table-column prop="skuName" label="备注类型">
            <template v-slot="scope">
                <span v-if="scope.row.type == 1">文字</span>
                <span v-if="scope.row.type == 2">图片</span>
                <span v-if="scope.row.type == 3">文件</span>
                <span v-if="scope.row.type == 4">视频</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="备注内容">
            <template v-slot="scope">
                <!-- 文字 -->
                <span v-if="scope.row.type==1" >{{scope.row.content}}</span>
                <!-- 图片 -->
                <el-image v-if="scope.row.type==2"
                    style="width: 100px; height: 100px"
                    :src="scope.row.content"
                    :preview-src-list="[scope.row.content]"
                    fit="cover"
                    />
                <!-- 文件 -->
                <el-link v-if="scope.row.type==3" :href="scope.row.content" target="_blank">{{scope.row.title}}</el-link>
            </template>
        </el-table-column>

        <el-table-column prop="createName" label="创建者">
            <template v-slot="scope">
                <span>{{scope.row.createName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="createName" label="创建时间">
            <template v-slot="scope">
              <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="160" fixed="right">
        <template v-slot="scope">
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
        </el-table-column>
    </el-table>

    <!-- 备注信息 - 弹出框 -->
    <el-dialog :title="BoxRecord.title" 
        v-model="BoxRecord.isVisible"
        width="40%"
        :destroy-on-close="true">
            <NewRecord ref="RecordInfo"></NewRecord>
        <template #footer>
            <el-button type="primary" @click="SaveRecordInfo">保存</el-button>
            <el-button @click="BoxRecord.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import SubjectList from '@/utils/mixins/SubjectList.js'  //会计科目
import NewRecord from '@/components/common/NewRecord.vue'  //新增备注-弹框
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,SubjectList],
    props:['financeInfo'],
    components:{
        NewRecord
    },
    data(){
        return{
            allInfo:{
                info:{
                   departmentId:0,
                    financeType:0,  //财务类型
                    subId:0,
                    totalPrice:0,
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    remark:'', 
                },
                numberList:[],   //编号数组
                recordList:[],   //备注列表
            },
            financeTypeList:[],   //财务类型列表
            //备注-弹出框
            BoxRecord:{
                isVisible:false,
                title:'备注信息'
            },
        }
    },
    methods:{
        //财务类型
        SelectFinanceTypeList(){
            this.$api.finance.SelectFinanceTypeList().then(res=>{
                if(res.data.status === 200){
                    this.financeTypeList = res.data.data;
                }
            })
        },

        //***************备注相关********************************** */
        //新增备注
        AddRecord(){
            this.BoxRecord.isVisible = true
        },
        //保存备注信息
        SaveRecordInfo(){
            let newRecordInfo = this.$refs.RecordInfo.info;
            let recordInfo = {
                id:0,
                systemNumber:'',
                billNumber:'',
                billType:32, //非现金财务单
                title:newRecordInfo.title,
                content:newRecordInfo.content,
                type:newRecordInfo.type,
                createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                creator:'',
                createName:newRecordInfo.createName,
                isdel:0
            }
            this.allInfo.recordList.push(recordInfo)  //新增备注信息
            this.BoxRecord.isVisible = false  //关闭备注弹出框
        },
        
        //删除行
        DeleteRow(billNumber,id,index){
            this.allInfo.recordList.splice(index,1);
            if(id!=0){
                let param = {
                    billNumber:billNumber,
                    id:id
                }
                this.$api.erp.DeleteRecord(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
            
        },


    },
    mounted(){
        this.SelectFinanceTypeList();  //加载财务单类型列表
        this.allInfo.numberList = this.financeInfo.numberList;
        this.allInfo.info.totalPrice = this.financeInfo.totalPrice;
        //部门
        if(this.financeInfo.departmentId !=0){
            this.allInfo.info.departmentId = this.financeInfo.departmentId;
        }
    }
}
</script>

<style>

</style>